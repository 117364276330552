import React from "react";
import SubPageContainer from "components/templates/SubPageContainer";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import OptionCard from "components/atoms/OptionCard";
import Carteirinha from "draws/home/Carteirinha";

import { linkCarteirinhaGoogle, linkCarteirinhaApple } from "links/links";

const href = (link) => {
  return (window.location.href = link);
};

const Fields = () => {
  const handleClickGoogle = () => {
    href(linkCarteirinhaGoogle);
  };

  const handleClickApple = () => {
    href(linkCarteirinhaApple);
  };

  return (
    <>
      <Box
        sx={{
          mt: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid container spacing={2} width={{ xs: 300, sm: 604 }}>
          <Grid item xs={12} sm={6}>
            <OptionCard
              title="Carteirinha UFF"
              description="Google Play"
              image={<Carteirinha />}
              action={handleClickGoogle}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <OptionCard
              title="Carteirinha UFF"
              description="Apple Store"
              image={<Carteirinha />}
              action={handleClickApple}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const Home = () => {
  return (
    <SubPageContainer>
      <Fields />
    </SubPageContainer>
  );
};

export default Home;
