import React from "react";
import { useNavigate } from "react-router-dom";

import PageContainer from "components/templates/PageContainer";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import OptionCard from "components/atoms/OptionCard";
import Wifi from "draws/home/Wifi";
import Glpi from "draws/home/Glpi";
import Sites from "draws/home/Sites";
import Siscoord from "draws/home/Siscoord";
import Carteirinha from "draws/home/Carteirinha";
import MoreOptions from "draws/home/MoreOptions";

const Fields = () => {
  const navigate = useNavigate();

  const handleClickWifi = () => {
    navigate("/wifi");
  };

  const handleClickGlpi = () => {
    navigate("/glpi");
  };

  const handleClickSites = () => {
    navigate("/sites-e-redes-sociais");
  };

  const handleClickCarteirinha = () => {
    navigate("/carteirinha");
  };

  const handleClickSiscoord = () => {
    navigate("/siscoord");
  };

  const handleClickMoreOptions = () => {
    navigate("/mais");
  };

  return (
    <Box
      sx={{
        mt: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Grid container spacing={2} width={{ xs: 300, sm: 604, md: 906 }}>
        <Grid item xs={12} sm={6} md={4}>
          <OptionCard
            title="Wi-Fi"
            description="Manuais de configuração"
            image={<Wifi />}
            action={handleClickWifi}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <OptionCard
            title="GLPI"
            description="Solicitação de serviços"
            image={<Glpi />}
            action={handleClickGlpi}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <OptionCard
            title="Sites e Redes Sociais"
            description="Institucionais"
            image={<Sites />}
            action={handleClickSites}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <OptionCard
            title="Carteirinha UFF"
            description="Aplicativo da Carteirinha Digital"
            image={<Carteirinha />}
            action={handleClickCarteirinha}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <OptionCard
            title="SISCOORD"
            description="Solicitação acadêmica à coordenação de graduação"
            image={<Siscoord />}
            action={handleClickSiscoord}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <OptionCard
            title="Mais Opções"
            description="Horário das aulas e do BusUFF, idUFF e outros"
            image={<MoreOptions />}
            action={handleClickMoreOptions}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const Home = () => {
  return (
    <PageContainer>
      <Fields />
    </PageContainer>
  );
};

export default Home;
