import React from "react";

import SubPageContainer from "components/templates/SubPageContainer";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import OptionCard from "components/atoms/OptionCard";
import Sissala from "draws/moreoptions/Sissala";
import Iduff from "draws/moreoptions/Iduff";
import Sei from "draws/moreoptions/Sei";
import Faq from "draws/moreoptions/Faq";
import Busuff from "draws/moreoptions/Busuff";
import Bavr from "draws/moreoptions/Bavr";

import {
  linkSissala,
  linkBusUff,
  linkIdUff,
  linkSei,
  linkGlpiFaq,
  linkBavr,
} from "links/links";

const href = (link) => {
  return (window.location.href = link);
};

const Fields = () => {
  const handleClickSissala = () => {
    href(linkSissala);
  };

  const handleClickBusuff = () => {
    href(linkBusUff);
  };

  const handleClickIduff = () => {
    href(linkIdUff);
  };

  const handleClickSei = () => {
    href(linkSei);
  };

  const handleClickFaq = () => {
    href(linkGlpiFaq);
  };

  const handleClickBavr = () => {
    href(linkBavr);
  };

  return (
    <>
      <Box
        sx={{
          mt: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid container spacing={2} width={{ xs: 300, sm: 604, md: 906 }}>
          <Grid item xs={12} sm={6} md={4}>
            <OptionCard
              title="Horário das Aulas"
              description="Saiba mais"
              image={<Sissala />}
              action={handleClickSissala}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <OptionCard
              title="BAVR"
              description="Biblioteca do Aterrado"
              image={<Bavr />}
              action={handleClickBavr}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <OptionCard
              title="FAQ"
              description="Perguntas Frequentes"
              image={<Faq />}
              action={handleClickFaq}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <OptionCard
              title="idUFF"
              description="Sistema Acadêmico de Graduação"
              image={<Iduff />}
              action={handleClickIduff}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <OptionCard
              title="SEI"
              description="Sistema Eletrônico de Informações"
              image={<Sei />}
              action={handleClickSei}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <OptionCard
              title="BusUFF"
              description="Horário do Ônibus da UFF Volta Redonda"
              image={<Busuff />}
              action={handleClickBusuff}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const Home = () => {
  return (
    <SubPageContainer>
      <Fields />
    </SubPageContainer>
  );
};

export default Home;
