import * as React from "react";
const SvgLinux = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10em"
    height="10em"
    data-name="Layer 1"
    viewBox="0 0 1019.484 436.681"
    {...props}
  >
    <path
      fill="#ffb9b9"
      d="M223.77 243.614a9.75 9.75 0 1 0-19.407 1.282l-28.015 36.686a13.583 13.583 0 0 0 1.836 14.914l2.198 2.564 10.083-2.017 11.428-10.083-1.345-11.428 14.117-22.183-.018-.016a9.743 9.743 0 0 0 9.123-9.719Z"
    />
    <path
      fill="#ffb8b8"
      d="m30.041 422.968-4.573-16.984L88.8 380.265l6.749 25.066-65.508 17.637z"
    />
    <path
      fill="#2f2e41"
      d="M14.744 431.732 0 376.97l.693-.187a22.075 22.075 0 0 1 27.054 15.575v.002l9.005 33.447Z"
    />
    <path
      fill="#ffb8b8"
      d="m117.278 420.254-14.224-10.344 33.131-59.789 20.994 15.267-39.901 54.866z"
    />
    <path
      fill="#2f2e41"
      d="m110.879 436.681-45.866-33.355.422-.58a22.075 22.075 0 0 1 30.834-4.87h.002l28.013 20.373ZM238.192 336.704l-35.795-34.772a18.076 18.076 0 0 0-23.668-1.323l-67.586 75.084 6.05 9.41 64.533-43.693 43.694 57.138 41.678-20.838Z"
    />
    <path
      fill="#ffb9b9"
      d="m222.731 344.099-26.216-2.017-18.986-4.603a9.753 9.753 0 1 0-1.85 12.656l-.003.014 11.428 4.705 49.072 16.806 6.05-4.706Z"
    />
    <path
      fill="#2f2e41"
      d="m285.92 416.699-14.789 9.411h-17.478l-81.338-30.25-118.983 21.511-3.361-25.545 108.9-47.727 95.455 31.594 31.594 41.006z"
    />
    <circle cx={254.326} cy={227.132} r={26.217} fill="#ffb9b9" />
    <path
      fill="#ccc"
      d="M322.16 331.804a150.631 150.631 0 0 1-7.388 46.591l-1.963 6.037-9.411 43.022-10.083 2.689-7.395-9.411-14.789-10.756-10.755-17.477-9.223-15.367-2.877-4.8-10.084-50.417-38.316-21.51-12.772-2.017 2.689-7.395 11.427-15.46 11.428-1.345 3.361-2.69 2.69 1.345 14.822 5.71 48.057-20.304 7.031 1.15 24.2 20.167a150.5 150.5 0 0 1 9.35 52.238Z"
    />
    <path
      d="m314.825 325.949-25.544 16.805-38.128 34.377-2.877-4.799-8.067-40.333h2.017l20.839-38.316 51.76 32.266z"
      opacity={0.1}
      style={{
        isolation: "isolate",
      }}
    />
    <path
      fill="#ccc"
      d="m263.065 289.649-20.839 38.316-12.1 13.445-10.755 2.689 28.905 31.594 41.005-36.972 25.544-16.806-51.76-32.266z"
    />
    <path
      fill="#2f2e41"
      d="m233.935 207.64-2.84 1.092a3.965 3.965 0 0 1-1.061-5.507l.046-.067-5.249-.246a39.637 39.637 0 0 1 59.174-4.769c.239-.823 2.844.778 3.907 2.402.358-1.34 2.8 5.135 3.665 9.712.4-1.524 1.937.936.59 3.297.854-.125 1.24 2.06.579 3.276.934-.439.776 2.169-.237 3.911 1.333-.118-.113 27.331-20.113 25.331-1.393-6.397-1-6-2.64-14.226-.763-.81-1.599-1.548-2.433-2.284l-4.513-3.984c-5.247-4.632-10.02-10.348-17.01-12.08-4.804-1.189-7.842-1.458-5.224-6.87-2.365.986-4.574 2.454-6.96 3.37.031-.756.365-1.602.319-2.359Z"
    />
    <circle cx={267.434} cy={230.157} r={4.706} fill="#ffb9b9" />
    <path
      fill="#e6e6e6"
      d="M137.514 351.376h21.61v21.61h-21.61z"
      style={{
        isolation: "isolate",
      }}
      transform="rotate(-86.19 -20.61 294.586)"
    />
    <path
      fill="#ccc"
      d="m34.086 130.472 26.182 1.744-1.743 26.182-26.183-1.743Zm24.968 2.806-23.906-1.592-1.592 23.906 23.906 1.592Z"
    />
    <path
      fill="#e6e6e6"
      d="M241.009 288.772h21.61v21.61h-21.61z"
      style={{
        isolation: "isolate",
      }}
      transform="rotate(-12.127 -883.757 608.6)"
    />
    <path
      fill="#ccc"
      d="m155.02 44.85 5.513 25.655-25.655 5.513-5.513-25.655Zm4.158 24.78-5.033-23.425-23.425 5.034 5.034 23.424Z"
    />
    <path
      fill="#e6e6e6"
      d="M226.603 392.674h21.61v21.61h-21.61z"
      style={{
        isolation: "isolate",
      }}
      transform="rotate(-43.127 -100.818 401.844)"
    />
    <path
      fill="#ccc"
      d="m163.557 154.337-19.152 17.939-17.939-19.152 19.152-17.938Zm-19.1 16.326 17.487-16.378-16.379-17.487-17.486 16.38Z"
    />
    <path
      fill="#3f3d56"
      d="M963.277 0H323.894a17.598 17.598 0 0 0-17.598 17.598v356.253a17.599 17.599 0 0 0 17.598 17.598h639.383a17.599 17.599 0 0 0 17.599-17.598V17.598A17.599 17.599 0 0 0 963.277 0Z"
    />
    <path fill="#fff" d="M329.89 31.101h627.391v353.913H329.89z" />
    <circle cx={643.049} cy={15.014} r={6.435} fill="#fff" />
    <path
      fill="#f2f2f2"
      d="M777.858 385.015H329.89V31.102l447.968 353.913z"
      style={{
        isolation: "isolate",
      }}
    />
    <circle cx={428.058} cy={298.224} r={60.307} fill="currentColor" />
    <path
      fill="#3f3d56"
      d="M441.483 343.55a75.016 75.016 0 1 1 75.016-75.015 75.016 75.016 0 0 1-75.016 75.016Zm0-149.05a74.035 74.035 0 1 0 74.035 74.035 74.035 74.035 0 0 0-74.035-74.035ZM753.437 214.97h58.605v12.246h-58.605zM697.991 148.627h169.497v5.248H697.991zM697.991 169.246h169.497v5.248H697.991zM697.991 189.866h169.497v5.248H697.991z"
    />
    <path fill="currentColor" d="M888.401 52.282h24.492v24.492h-24.492z" />
    <path
      fill="#ccc"
      d="M924.264 88.145h-29.74v-29.74h29.74Zm-28.447-1.293h27.154V59.698h-27.154Z"
    />
    <path
      fill="#3f3d56"
      d="M998.491 378.58h-61.23v-4.412a.875.875 0 0 0-.874-.875h-20.993a.875.875 0 0 0-.874.875v4.412H901.4v-4.412a.875.875 0 0 0-.876-.875h-20.992a.875.875 0 0 0-.875.875v4.412h-13.12v-4.412a.875.875 0 0 0-.875-.875h-20.993a.875.875 0 0 0-.875.875v4.412h-13.12v-4.412a.875.875 0 0 0-.875-.875h-20.993a.875.875 0 0 0-.875.875v4.412h-13.12v-4.412a.875.875 0 0 0-.875-.875h-20.993a.875.875 0 0 0-.875.875v4.412h-13.12v-4.412a.875.875 0 0 0-.875-.875H736.08a.875.875 0 0 0-.875.875v4.412h-13.12v-4.412a.875.875 0 0 0-.875-.875H556.766a.875.875 0 0 0-.875.875v4.412h-13.12v-4.412a.875.875 0 0 0-.875-.875h-20.993a.875.875 0 0 0-.875.875v4.412h-13.12v-4.412a.875.875 0 0 0-.875-.875H485.04a.875.875 0 0 0-.875.875v4.412h-13.12v-4.412a.875.875 0 0 0-.875-.875h-20.993a.875.875 0 0 0-.875.875v4.412h-13.12v-4.412a.875.875 0 0 0-.875-.875h-20.993a.875.875 0 0 0-.875.875v4.412h-13.12v-4.412a.875.875 0 0 0-.875-.875h-20.993a.875.875 0 0 0-.875.875v4.412h-13.12v-4.412a.875.875 0 0 0-.875-.875h-20.993a.875.875 0 0 0-.874.875v4.412h-40.237a20.993 20.993 0 0 0-20.993 20.993v9.492a20.993 20.993 0 0 0 20.993 20.993h698.014a20.993 20.993 0 0 0 20.993-20.993v-9.492a20.993 20.993 0 0 0-20.993-20.993Z"
    />
  </svg>
);
export default SvgLinux;
