import * as React from "react";
const SvgVisitantes = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10em"
    height="10em"
    data-name="Layer 1"
    viewBox="0 0 850 605"
    {...props}
  >
    <path
      fill="#f2f2f2"
      d="M845.318 562.216a1.003 1.003 0 0 1-1.286.589L49.27 267.07a1 1 0 0 1 .697-1.875L844.73 560.93a1.003 1.003 0 0 1 .589 1.286Z"
    />
    <path
      fill="#3f3d56"
      d="M421.955 0h-189.38a56.097 56.097 0 0 0-56.033 56.033v17.118a2.6 2.6 0 0 0-3.291 2.54v13.163a2.6 2.6 0 0 0 3.29 2.54v19.931a2.6 2.6 0 0 0-3.29 2.54v32.91a2.6 2.6 0 0 0 3.29 2.539v12.033a2.6 2.6 0 0 0-3.29 2.54v32.909a2.6 2.6 0 0 0 3.29 2.54v347.523a56.097 56.097 0 0 0 56.034 56.033h189.38a56.097 56.097 0 0 0 56.033-56.033V56.033A56.097 56.097 0 0 0 421.955 0Zm44.186 546.859a44.236 44.236 0 0 1-44.186 44.186h-189.38a44.236 44.236 0 0 1-44.186-44.186V56.033a44.236 44.236 0 0 1 44.186-44.186h189.38a44.236 44.236 0 0 1 44.186 44.186Z"
    />
    <path
      fill="#e6e6e6"
      d="M421.955 597.627h-189.38a50.826 50.826 0 0 1-50.768-50.768V56.033a50.826 50.826 0 0 1 50.768-50.768h189.38a50.826 50.826 0 0 1 50.767 50.768V546.86a50.826 50.826 0 0 1-50.767 50.768Z"
    />
    <path
      fill="#fff"
      d="M426.634 589.729H227.895a34.28 34.28 0 0 1-34.24-34.241V47.404a34.28 34.28 0 0 1 34.24-34.24h25.918a5.666 5.666 0 0 1 5.66 5.659 16.737 16.737 0 0 0 16.718 16.719h99.515a16.737 16.737 0 0 0 16.719-16.719 5.666 5.666 0 0 1 5.659-5.66h28.55a34.28 34.28 0 0 1 34.241 34.241v508.084a34.28 34.28 0 0 1-34.24 34.24Z"
    />
    <path
      fill="#fff"
      d="M335.821 24.682h-23.694a2.633 2.633 0 0 1 0-5.266h23.694a2.633 2.633 0 0 1 0 5.266Z"
    />
    <circle cx={352.276} cy={22.707} r={1.316} fill="#fff" />
    <path
      fill="#e6e6e6"
      d="M186.99 116h280.287v3H186.99zM186.99 176h280.287v3H186.99zM186.99 300h280.287v3H186.99zM186.99 424h280.287v3H186.99zM186.99 521h280.287v3H186.99z"
    />
    <path
      fill="#e6e6e6"
      d="M387.541 117.5h3v184h-3zM247.227 301.446h3V522.5h-3z"
    />
    <path
      fill="#e6e6e6"
      d="M248.727 362.083h212.149v3H248.727zM387.864 522.5h3v67.229h-3zM193.154 239.229h195.387v3H193.154zM231.191 12.426l2.767-1.158 44.215 105.653-2.768 1.158zM367.132 35.541h3V117.5h-3z"
    />
    <path
      fill="currentColor"
      d="M447.49 163c0 26.88-41.56 89.516-53.704 107.247a3.992 3.992 0 0 1-6.591 0C375.05 252.516 333.49 189.88 333.49 163a57 57 0 0 1 114 0Z"
    />
    <circle cx={390.49} cy={159} r={29} fill="#fff" />
    <path
      fill="#e6e6e6"
      d="M278.765 521.795 388.59 302.362l2.682 1.343-109.826 219.432z"
    />
    <path
      fill="#ffb8b8"
      d="M546.759 350.63a10.056 10.056 0 0 0 14.317-5.725l35.73-.618-10.49-15.323-32.321 2.936a10.11 10.11 0 0 0-7.236 18.73Z"
    />
    <path
      fill="currentColor"
      d="M568.208 350.184a4.101 4.101 0 0 1-4.098-4.281l.722-16.384a4.082 4.082 0 0 1 3.63-3.893l41.39-4.747-1.387 27.302-40.054 1.997q-.102.006-.203.006Z"
    />
    <path
      fill="#ffb8b8"
      d="m618.618 574.755-11.654 3.806-20.227-43.14 17.2-5.617 14.681 44.951z"
    />
    <path
      fill="#2f2e41"
      d="m625.28 585.08-37.577 12.274-.155-.476a15.386 15.386 0 0 1 9.848-19.403h.001l22.951-7.496Z"
    />
    <path
      fill="#ffb8b8"
      d="m652.944 592.796-12.26-.001-5.832-47.288 18.094.001-.002 47.288z"
    />
    <path
      fill="#2f2e41"
      d="m656.07 604.68-39.53-.001v-.5a15.386 15.386 0 0 1 15.386-15.387h.001l24.144.001ZM634.323 581.287a4.103 4.103 0 0 1-4.068-3.613L614.448 445.53l-23.137 37.142 27.148 69.377a4.125 4.125 0 0 1-2.016 5.178l-23.03 11.27a4.1 4.1 0 0 1-5.694-2.395l-25.522-77.108a32.578 32.578 0 0 1-.725-17.97l16.503-67.72.193-.106 37.199-20.376.213.078 43.808 15.99-4.585 86.817 3.331 90.108a4.077 4.077 0 0 1-3.844 4.244l-19.71 1.22a4.535 4.535 0 0 1-.257.009Z"
    />
    <path
      fill="currentColor"
      d="M610.192 429.036c-6.545 0-12.06-1.865-15.51-6.604l-.11-.152 2.232-23.426-19.855 11.021a2.3 2.3 0 0 1-3.27-2.82l24.763-65.76 16.35-53.567a21.536 21.536 0 0 1 17.358-17.196c14.564-2.676 23.24 2.239 33.256 13.145a23.585 23.585 0 0 1 5.826 19.896l-9.476 54.16 2.784 48.561a4.095 4.095 0 0 1-1.777 4.47c-7.745 4.929-31.493 18.27-52.571 18.272Z"
    />
    <circle cx={643.328} cy={237.86} r={24.561} fill="#ffb8b8" />
    <path
      fill="#ffb8b8"
      d="M649.725 412.938a10.056 10.056 0 0 0 4.072-14.872l21.065-28.867-18.55-.88-17.158 27.547a10.11 10.11 0 0 0 10.571 17.072Z"
    />
    <path
      fill="currentColor"
      d="M661.133 396.648a4.105 4.105 0 0 1-2.925-.696l-13.945-9.724a4.083 4.083 0 0 1-1.228-5.37l24.826-44.172-24.97-30.056a14.783 14.783 0 1 1 24.234-16.734l27.57 48.661a2.31 2.31 0 0 1-.002 2.27l-30.575 53.788a4.109 4.109 0 0 1-2.66 1.973 3.696 3.696 0 0 1-.325.06Z"
    />
    <path
      fill="#2f2e41"
      d="M633.512 234.724c-2.43-1.814-5.796-1.53-8.676-2.482a10.3 10.3 0 0 1-3.645-17.452c3.11-2.787 7.537-3.42 11.683-3.93 5.68-.699 11.414-1.398 17.11-.843s11.428 2.48 15.59 6.406c4.59 4.329 6.851 10.773 6.866 17.083a38.084 38.084 0 0 1-5.062 18.033 14.134 14.134 0 0 1-4.292 5.294c-1.916 1.265-4.616 1.596-6.461.23-1.595-1.18-2.178-3.248-2.899-5.097s-1.95-3.817-3.913-4.102c-2.528-.368-4.458 2.203-6.87 3.045-3.375 1.176-7.123-1.426-8.5-4.724s-1.013-7.03-.627-10.582"
    />
    <path
      fill="#ccc"
      d="M850 604a1.003 1.003 0 0 1-1 1H1a1 1 0 0 1 0-2h848a1.003 1.003 0 0 1 1 1Z"
    />
    <circle cx={390.49} cy={301} r={13} fill="currentColor" />
    <path
      fill="#f2f2f2"
      d="M573.51 158.861c-5.43 7.236-29.268 15.702-36.119 18.022a1.343 1.343 0 0 1-1.774-1.331c.312-7.226 1.776-32.48 7.205-39.717a19.183 19.183 0 1 1 30.688 23.026Z"
    />
    <circle cx={558.974} cy={146.271} r={9.76} fill="#fff" />
    <circle cx={530.293} cy={184.496} r={4.375} fill="#f2f2f2" />
    <path
      fill="#f2f2f2"
      d="M97.677 357.34c7.99 12.206 7.734 53 7.489 64.662a2.166 2.166 0 0 1-2.993 1.959c-10.785-4.442-48.273-20.532-56.262-32.738a30.934 30.934 0 1 1 51.766-33.883Z"
    />
    <circle cx={70.605} cy={372.465} r={15.739} fill="#fff" />
    <circle cx={112.81} cy={436.946} r={7.055} fill="#f2f2f2" />
  </svg>
);
export default SvgVisitantes;
