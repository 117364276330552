import React from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";

import PageContainer from "components/templates/PageContainer";
import OptionCard from "components/atoms/OptionCard";
import PageNotFound from "draws/404";

const Error = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  };
  return (
    <Box
      sx={{
        mt: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <OptionCard
        title="Erro"
        description="Página não encontrada"
        image={<PageNotFound />}
        action={handleClick}
      />
    </Box>
  );
};

const error404 = () => {
  return (
    <PageContainer maxWidth="xs">
      <Error />
    </PageContainer>
  );
};

export default error404;
