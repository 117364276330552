import React from "react";
import { Routes, Route } from "react-router-dom";

import ButtonAppBar from "components/organisms/ButtonAppBar";
import Home from "components/pages/Home";
import Wifi from "components/pages/Wifi";
import Glpi from "components/pages/Glpi";
import SitesRedes from "components/pages/SitesRedes";
import Sites from "components/pages/Sites";
import RedesSociais from "components/pages/RedesSociais";
import Carteirinha from "components/pages/Carteirinha";
import Siscoord from "components/pages/Siscoord";
import MoreOptions from "components/pages/MoreOptions";

import Error404 from "routes/error404";

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<ButtonAppBar />}>
      <Route index element={<Home />} />
      <Route path="/wifi" element={<Wifi />} />
      <Route path="/glpi" element={<Glpi />} />
      <Route path="/sites-e-redes-sociais" element={<SitesRedes />} />
      <Route path="/sites" element={<Sites />} />
      <Route path="/redes-sociais" element={<RedesSociais />} />
      <Route path="/carteirinha" element={<Carteirinha />} />
      <Route path="/siscoord" element={<Siscoord />} />
      <Route path="/mais" element={<MoreOptions />} />
      <Route path="*" element={<Error404 />} />
    </Route>
  </Routes>
);

export default AppRoutes;
