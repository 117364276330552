import * as React from "react";
const SvgSites = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10em"
    height="10em"
    data-name="Layer 1"
    viewBox="0 0 876.272 661.473"
    {...props}
  >
    <path
      fill="#2f2e41"
      d="M504.97 32.194c7.516 15.995-8.41 20.526-26.655 29.099S446.417 79.236 438.9 63.24s-13.615-47.742 12.195-59.87c26.7-12.547 46.358 12.829 53.874 28.824Z"
    />
    <circle cx={467.746} cy={39.089} r={24.561} fill="#ffb8b8" />
    <path
      fill="#2f2e41"
      d="M589.426 148.012c-.85-1.11-1.7-2.2-2.56-3.26q-1.665-2.07-3.35-4.01c-12.73-14.69-26.27-23.94-41.18-24.93h-.02l-.22-.02-29.71 28.96-10.1 9.84.22.54 5.45 13.57h95.49a234.834 234.834 0 0 0-14.02-20.69ZM441.038 48.977l8.792-4.131-1.225-12.683 5.88 10.496 4.201-1.975-.714-7.398 3.43 6.122 31.224-14.672a26 26 0 0 0-34.589-12.474l-4.525 2.126a26 26 0 0 0-12.474 34.59Z"
    />
    <path
      fill="#f2f2f2"
      d="M644.526 494.8H131.688a16.519 16.519 0 0 1-16.5-16.5V170.146a12.102 12.102 0 0 1 12.087-12.088H648.73a12.311 12.311 0 0 1 12.297 12.297V478.3a16.519 16.519 0 0 1-16.5 16.5Z"
    />
    <path
      fill="#fff"
      d="M147.688 478.3h480.838a16 16 0 0 0 16-16V200.752a16 16 0 0 0-16-16H147.688a16 16 0 0 0-16 16V462.3a16 16 0 0 0 16 16Z"
    />
    <path
      fill="currentColor"
      d="M660.796 169.203h-545.84v-8.4a16.574 16.574 0 0 1 16.56-16.55h512.72a16.574 16.574 0 0 1 16.56 16.55Z"
    />
    <circle cx={145.051} cy={156.752} r={4.283} fill="#fff" />
    <circle cx={161.31} cy={156.752} r={4.283} fill="#fff" />
    <circle cx={177.569} cy={156.752} r={4.283} fill="#fff" />
    <path
      fill="#ccc"
      d="M403.11 421.58h-43a9.51 9.51 0 0 1-9.5-9.5v-43a9.51 9.51 0 0 1 9.5-9.5h43a9.51 9.51 0 0 1 9.5 9.5v43a9.51 9.51 0 0 1-9.5 9.5Zm-43-60a7.508 7.508 0 0 0-7.5 7.5v43a7.508 7.508 0 0 0 7.5 7.5h43a7.508 7.508 0 0 0 7.5-7.5v-43a7.508 7.508 0 0 0-7.5-7.5Z"
    />
    <path
      fill="#ccc"
      d="M394.61 409.58h-26a3.003 3.003 0 0 1-3-3v-32a3.003 3.003 0 0 1 3-3h18.84a3.007 3.007 0 0 1 1.879.66l7.16 5.755a2.984 2.984 0 0 1 1.121 2.338v26.246a3.003 3.003 0 0 1-3 3Zm-26-36a1.001 1.001 0 0 0-1 1v32a1.001 1.001 0 0 0 1 1h26a1.001 1.001 0 0 0 1-1v-26.247a.995.995 0 0 0-.373-.78l-7.161-5.754a1.002 1.002 0 0 0-.626-.22Z"
    />
    <path
      fill="#ccc"
      d="M389.61 385.58h-16a1 1 0 0 1 0-2h16a1 1 0 0 1 0 2ZM389.61 391.58h-16a1 1 0 0 1 0-2h16a1 1 0 0 1 0 2ZM389.61 397.58h-16a1 1 0 0 1 0-2h16a1 1 0 0 1 0 2ZM505.11 421.58h-43a9.51 9.51 0 0 1-9.5-9.5v-43a9.51 9.51 0 0 1 9.5-9.5h43a9.51 9.51 0 0 1 9.5 9.5v43a9.51 9.51 0 0 1-9.5 9.5Zm-43-60a7.508 7.508 0 0 0-7.5 7.5v43a7.508 7.508 0 0 0 7.5 7.5h43a7.508 7.508 0 0 0 7.5-7.5v-43a7.508 7.508 0 0 0-7.5-7.5Z"
    />
    <path
      fill="#ccc"
      d="M497.61 401.58h-28a3.003 3.003 0 0 1-3-3v-16a3.003 3.003 0 0 1 3-3h28a3.003 3.003 0 0 1 3 3v16a3.003 3.003 0 0 1-3 3Zm-28-20a1.001 1.001 0 0 0-1 1v16a1.001 1.001 0 0 0 1 1h28a1.001 1.001 0 0 0 1-1v-16a1.001 1.001 0 0 0-1-1Z"
    />
    <path
      fill="#ccc"
      d="M483.61 393.217a3.003 3.003 0 0 1-1.59-.452l-14.94-9.338a1 1 0 0 1 1.06-1.696l14.94 9.337a1.006 1.006 0 0 0 1.06 0l14.94-9.337a1 1 0 0 1 1.06 1.696l-14.939 9.338a3.004 3.004 0 0 1-1.59.452ZM301.11 421.58h-43a9.51 9.51 0 0 1-9.5-9.5v-43a9.51 9.51 0 0 1 9.5-9.5h43a9.51 9.51 0 0 1 9.5 9.5v43a9.51 9.51 0 0 1-9.5 9.5Zm-43-60a7.508 7.508 0 0 0-7.5 7.5v43a7.508 7.508 0 0 0 7.5 7.5h43a7.508 7.508 0 0 0 7.5-7.5v-43a7.508 7.508 0 0 0-7.5-7.5Z"
    />
    <path
      fill="#ccc"
      d="M293.61 401.58h-28a3.003 3.003 0 0 1-3-3v-16a3.003 3.003 0 0 1 3-3h28a3.003 3.003 0 0 1 3 3v16a3.003 3.003 0 0 1-3 3Zm-28-20a1.001 1.001 0 0 0-1 1v16a1.001 1.001 0 0 0 1 1h28a1.001 1.001 0 0 0 1-1v-16a1.001 1.001 0 0 0-1-1Z"
    />
    <path
      fill="#ccc"
      d="M289.172 401.58h-20.124a2 2 0 0 1-1.732-3l6.062-10.5a2 2 0 0 1 3.464 0l3.915 6.78 2.268-3.928a2 2 0 0 1 3.465 0l4.415 7.647a2 2 0 0 1-1.733 3Zm0-2-4.415-7.648-3.134 5.428a1 1 0 0 1-.866.5 1 1 0 0 1-.866-.5l-4.78-8.28-6.063 10.5Z"
    />
    <circle cx={290.61} cy={385.579} r={2} fill="#ccc" />
    <path
      fill="#ffb8b8"
      d="m768.825 648.15-12.26-.001-5.832-47.288 18.094.001-.002 47.288z"
    />
    <path
      fill="#2f2e41"
      d="m771.951 660.034-39.53-.002v-.5a15.386 15.386 0 0 1 15.386-15.386h.001l24.144.001Z"
    />
    <path
      fill="#ffb8b8"
      d="m818.825 648.15-12.26-.001-5.832-47.288 18.094.001-.002 47.288z"
    />
    <path
      fill="#2f2e41"
      d="m821.951 660.034-39.53-.002v-.5a15.386 15.386 0 0 1 15.386-15.386h.001l24.144.001ZM815.15 627.65l-11.355-3.454a4.493 4.493 0 0 1-3.166-3.833l-10.585-100.326a.48.48 0 0 0-.479-.447.47.47 0 0 0-.51.409l-17.731 97.2a4.5 4.5 0 0 1-5.335 3.599l-14.512-2.99a4.516 4.516 0 0 1-3.581-4.711l10.785-159.313 73.138-7.891-10.868 177.726a4.505 4.505 0 0 1-4.484 4.228 4.526 4.526 0 0 1-1.317-.197Z"
    />
    <circle cx={815.08} cy={290.487} r={24.561} fill="#ffb8b8" />
    <path
      fill="currentColor"
      d="m758.568 454.72 15.859-99.851a37.857 37.857 0 0 1 48.005-30.4 37.743 37.743 0 0 1 26.601 43.262l-16.591 89.172Z"
    />
    <path
      fill="#ffb8b8"
      d="M821.86 472.586a10.056 10.056 0 0 0 1.748-15.32l16.396-31.752-18.465 1.97-12.74 29.85a10.11 10.11 0 0 0 13.062 15.252Z"
    />
    <path
      fill="currentColor"
      d="M825.372 451.665a4.506 4.506 0 0 1-2.427-1.15l-6.273-5.797a4.515 4.515 0 0 1-1.092-5.055l12.538-29.718-1.779-58.72a14.497 14.497 0 1 1 28.727 3.92l3.041 36.911a46.373 46.373 0 0 1-8.938 31.397l-19.552 26.432a4.505 4.505 0 0 1-3.195 1.804 4.455 4.455 0 0 1-1.05-.024Z"
    />
    <path
      fill="#ffb8b8"
      d="M673.64 277.105a10.056 10.056 0 0 0 13.427 7.582l22.846 27.478 5.385-17.772-22.52-23.369a10.11 10.11 0 0 0-19.137 6.081Z"
    />
    <path
      fill="currentColor"
      d="M691.537 288.495a4.506 4.506 0 0 1 2.006-1.786l7.783-3.516a4.515 4.515 0 0 1 5.08.965l22.478 23.132 54.767 21.256a14.497 14.497 0 1 1-14.81 24.926l-35.176-11.591a46.374 46.374 0 0 1-25.427-20.472l-16.718-28.31a4.505 4.505 0 0 1-.415-3.646 4.454 4.454 0 0 1 .432-.958Z"
    />
    <path
      d="m835.002 375.652-1 32-18.422 32.011 15.382-35.673 4.04-28.338z"
      opacity={0.1}
    />
    <path
      fill="#2f2e41"
      d="M812.266 295.103a6.423 6.423 0 0 1-7.03-4.703 9.764 9.764 0 0 0-6.951-7.273c-4.505-1.03-10.012 1.091-13.313-2.322a6.637 6.637 0 0 1-1.576-5.843c.608-3.317 2.981-5.823 5.563-7.827a34.062 34.062 0 0 1 18.866-7.051 67.677 67.677 0 0 1 10.621.528 47.01 47.01 0 0 1 10.864 1.986 25.576 25.576 0 0 1 16.002 18.68 29.528 29.528 0 0 1-6.057 24.13 31.702 31.702 0 0 1-12.161 9.26 4.744 4.744 0 0 1-3.606.182c-2.958-1.176-2.474-4.84-1.126-7.283 1.443-2.614 3.663-5.146 2.503-8.328a6.302 6.302 0 0 0-3.127-3.366c-2.94-1.5-6.228-1.145-9.389-.78Z"
    />
    <path
      fill="#fff"
      d="M665.433 308.973h-304a16.519 16.519 0 0 1-16.5-16.5v-65a16.519 16.519 0 0 1 16.5-16.5h304a16.519 16.519 0 0 1 16.5 16.5v65a16.519 16.519 0 0 1-16.5 16.5Z"
    />
    <path
      fill="#3f3d56"
      d="M665.433 309.473h-304a17.02 17.02 0 0 1-17-17v-65a17.02 17.02 0 0 1 17-17h304a17.02 17.02 0 0 1 17 17v65a17.02 17.02 0 0 1-17 17Zm-304-97a15.017 15.017 0 0 0-15 15v65a15.017 15.017 0 0 0 15 15h304a15.017 15.017 0 0 0 15-15v-65a15.017 15.017 0 0 0-15-15Z"
    />
    <path
      fill="#f2f2f2"
      d="M628.433 292.973h-230a16.519 16.519 0 0 1-16.5-16.5v-34a16.519 16.519 0 0 1 16.5-16.5h230a16.519 16.519 0 0 1 16.5 16.5v34a16.519 16.519 0 0 1-16.5 16.5Z"
    />
    <circle cx={447.928} cy={254.915} r={19.732} fill="#ff6584" />
    <path
      fill="#3f3d56"
      d="m614.14 290.981-27.852-23.36-27.372-22.956a4 4 0 0 0-5.153.01l-27.283 23.062-6.664 5.633-10.048-8.427-30.538-25.61a4 4 0 0 0-5.152.009L443.64 265.07l-30.917 26.134a1 1 0 0 0 .648 1.764l63.386-.123 20.064-.038-.196.166 59.808-.115 57.066-.11a1 1 0 0 0 .641-1.767Z"
    />
    <path
      fill="#ccc"
      d="M875.272 661.473h-192a1 1 0 0 1 0-2h192a1 1 0 0 1 0 2Z"
    />
    <path
      fill="#ffb8b8"
      d="M604.056 131.912a11.22 11.22 0 0 0-7.25-5.1 10.301 10.301 0 0 0-1.66-.21l-76.19-68.12a9.015 9.015 0 0 0-5.63-2.69 7.67 7.67 0 0 0-1.27-.04 9.055 9.055 0 0 0-5.96 15.48l29.17 25.43 47.9 41.75a11.916 11.916 0 0 0 .35 2.33 11.266 11.266 0 0 0 9.67 8.37 11.092 11.092 0 0 0 6.05-1 11.407 11.407 0 0 0 3.88-2.97c.11-.13.21-.25.31-.39a11.293 11.293 0 0 0 .63-12.84ZM473.736 197.562l-.71-28.86-.6-23.95-.41-16.76-.81-32.67a9.046 9.046 0 0 0-15.71-5.25 6.923 6.923 0 0 0-.75.96 8.936 8.936 0 0 0-1.56 6.05l1.78 47.67.89 23.95 1.14 30.55a12.047 12.047 0 0 0-.89 1.41 11.248 11.248 0 0 0-.71 8.84 11.311 11.311 0 0 0 15.56 6.63 11.3 11.3 0 0 0 2.78-18.57Z"
    />
    <path
      fill="#ccc"
      d="m542.336 115.812-7.07-19.15-11.44-31.02a16.006 16.006 0 0 0-9.95-9.68c-.18-.06-.37-.12-.55-.17a15.984 15.984 0 0 0-13.24 1.82l-38.53 24.44a16.093 16.093 0 0 0-5.27 21.75 276.323 276.323 0 0 0 15.73 24.19c4.49 6.11 12.85 6.76 14.3 16.76h28.41l28.76-25.82Z"
    />
    <circle cx={101.165} cy={326.682} r={23.172} fill="#a0616a" />
    <path
      fill="#a0616a"
      d="M158.051 310.732q.343.273.664.578a10.38 10.38 0 0 1-.417 15.484 9.722 9.722 0 0 1-.814.613l1.569 52.945a8.061 8.061 0 0 1-5.545 7.867l-.096.02-42.989 4.579a8.899 8.899 0 1 1-1.722-17.713l30.644-2.7 5.3-45.85a9.876 9.876 0 0 1-.971-.99 10.38 10.38 0 0 1 14.377-14.833ZM139.328 649.174h11.567l5.502-44.613h-17.071l.002 44.613z"
    />
    <path
      fill="#2f2e41"
      d="m136.378 645.398 22.778-.001h.001a14.516 14.516 0 0 1 14.516 14.516v.471l-37.294.002Z"
    />
    <path
      fill="#a0616a"
      d="m24.366 633.182 9.938 5.917 27.553-35.517-14.668-8.733-22.823 38.333z"
    />
    <path
      fill="#2f2e41"
      d="m23.763 628.428 19.572 11.653a14.516 14.516 0 0 1 5.046 19.9l-.241.405-32.045-19.08ZM153.624 633.417h-12.952a4.238 4.238 0 0 1-4.221-3.797l-13.057-122.738a1.415 1.415 0 0 0-2.698-.433l-23.555 52.16-41.704 68.002a4.266 4.266 0 0 1-5.328 1.666l-16.66-7.33a4.247 4.247 0 0 1-1.936-6.065l38.338-64.192 11.757-54.085C77.91 473.15 93.763 438.28 93.924 437.93l.079-.17 43.667-17.068.219.268c17.221 56.514 27.715 117.332 19.967 208.568a4.274 4.274 0 0 1-4.232 3.89Z"
    />
    <path
      fill="#3f3d56"
      d="m92.243 443.474-.203-.428c-.097-.204-9.807-20.684-17.292-42.571a32.285 32.285 0 0 1 2.238-25.874A32.637 32.637 0 0 1 97.8 358.597a32.677 32.677 0 0 1 37.928 19.57c5.937 14.847 4.045 30.694 2.264 43.157l-.035.25-.227.108Z"
    />
    <path
      fill="#2f2e41"
      d="M129.26 313.593H92.466v-16.038c8.076-3.209 15.978-5.937 20.755 0a16.038 16.038 0 0 1 16.039 16.038Z"
    />
    <path
      fill="#2f2e41"
      d="M90.429 294.724c-21.996 0-28.153 27.572-28.153 43.126 0 8.674 3.923 11.777 10.087 12.827l2.178-11.611 5.099 12.11c1.731.01 3.55-.024 5.436-.06l1.73-3.56 3.854 3.497c15.441.023 27.921 2.273 27.921-13.203 0-15.554-5.4-43.126-28.152-43.126Z"
    />
    <path fill="#ccc" d="M193 661.473H1a1 1 0 1 1 0-2h192a1 1 0 0 1 0 2Z" />
    <path
      fill="#fff"
      d="M196.61 332.473h-43a9.51 9.51 0 0 1-9.5-9.5v-43a9.51 9.51 0 0 1 9.5-9.5h43a9.51 9.51 0 0 1 9.5 9.5v43a9.51 9.51 0 0 1-9.5 9.5Z"
    />
    <path
      fill="#3f3d56"
      d="M196.61 332.473h-43a9.51 9.51 0 0 1-9.5-9.5v-43a9.51 9.51 0 0 1 9.5-9.5h43a9.51 9.51 0 0 1 9.5 9.5v43a9.51 9.51 0 0 1-9.5 9.5Zm-43-60a7.508 7.508 0 0 0-7.5 7.5v43a7.508 7.508 0 0 0 7.5 7.5h43a7.508 7.508 0 0 0 7.5-7.5v-43a7.508 7.508 0 0 0-7.5-7.5Z"
    />
    <path
      fill="currentColor"
      d="M189.11 314.973h-28a3.003 3.003 0 0 1-3-3v-21a3.003 3.003 0 0 1 3-3h7.883a2.981 2.981 0 0 1 2.555 1.427l2.198 3.573h15.364a3.003 3.003 0 0 1 3 3v16a3.003 3.003 0 0 1-3 3Z"
    />
    <path
      fill="#a0616a"
      d="M188.2 325.582q.21.384.387.79a10.38 10.38 0 0 1-6.393 14.109 9.72 9.72 0 0 1-.988.25l-19.1 49.404a8.061 8.061 0 0 1-8.162 5.099l-.096-.018-41.397-12.462a8.899 8.899 0 1 1 5.286-16.993l29.29 9.403 22.677-40.201a9.876 9.876 0 0 1-.511-1.29 10.38 10.38 0 0 1 19.006-8.091Z"
    />
  </svg>
);
export default SvgSites;
