import * as React from "react";
const SvgGlpi = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10em"
    height="10em"
    data-name="Layer 1"
    viewBox="0 0 844 624.592"
    {...props}
  >
    <circle cx={83} cy={83} r={83} fill="#f0f0f0" />
    <circle cx={761} cy={269} r={83} fill="#f0f0f0" />
    <path
      fill="#e6e6e6"
      d="m501.716 617.871-14.592-6.144-10.01-73.152H343.808l-10.85 72.853-13.056 6.527a3.1 3.1 0 0 0 1.387 5.873h179.223a3.1 3.1 0 0 0 1.203-5.957Z"
    />
    <path
      fill="#ccc"
      d="M738.607 548.308H83.778a12.973 12.973 0 0 1-12.944-12.973v-92.34h680.718v92.34a12.974 12.974 0 0 1-12.945 12.973Z"
    />
    <path
      fill="#3f3d56"
      d="M752.236 486.637h-682V91.536a15.657 15.657 0 0 1 15.64-15.64h650.72a15.657 15.657 0 0 1 15.64 15.64Z"
    />
    <path
      fill="#fff"
      d="M711.503 457.96H110.97a12.07 12.07 0 0 1-12.057-12.056V116.63a12.07 12.07 0 0 1 12.057-12.058h600.534a12.07 12.07 0 0 1 12.057 12.058v329.274a12.07 12.07 0 0 1-12.057 12.056Z"
    />
    <path
      fill="#ccc"
      d="M579.656 624.592H242.208a1.567 1.567 0 0 1-1.54-1.134 1.53 1.53 0 0 1 1.478-1.919h337.385a1.615 1.615 0 0 1 1.616 1.194 1.528 1.528 0 0 1-1.49 1.859Z"
    />
    <path
      fill="#f0f0f0"
      d="M643.433 399.344H516.907a1.208 1.208 0 0 1-1.303-1.079V296.206a1.208 1.208 0 0 1 1.303-1.078h126.526a1.208 1.208 0 0 1 1.303 1.078v102.06a1.208 1.208 0 0 1-1.303 1.078Z"
    />
    <path
      fill="#fff"
      d="M629.68 306.42h-99.02a1.305 1.305 0 0 0-1.303 1.303v76.727a1.305 1.305 0 0 0 1.303 1.302h99.02a1.305 1.305 0 0 0 1.303-1.302v-76.727a1.305 1.305 0 0 0-1.303-1.303Z"
    />
    <circle cx={577.724} cy={345.821} r={12.59} fill="#ffb8b8" />
    <path
      fill="currentColor"
      d="M596.144 385.752H568.23l.912-20.522.217-.228 3.133-3.31h9.968l3.695 3.579.298.289 7.867 7.54 1.824 12.652z"
    />
    <path
      fill="#2f2e41"
      d="M573.03 385.752h-21.118c-.052-4.632-.081-7.406-.081-7.406l1.221-.744.006-.003h.003l13.458-8.17 2.826-4.59.014.163.831 9.673s1.372 4.436 2.84 11.077ZM608.524 385.752h-22.37c.34-13.51-1.484-20.913-.546-20.913l.547.432 6.593 5.206 8.689 3.187h.005l5.99 2.198 1.052.385v.495c.003 1.187.008 4.433.04 9.01ZM565.813 349.76s1.07-1.878.821-8.154 9.308-2.726 17.293-3.325 6.295 10.478 6.295 10.478.576-.659 1.81-6.004-1.568-10.673-1.568-10.673c-.825-5.617-5.27-3.817-5.27-3.817a2.331 2.331 0 0 1 1.73 2.615c-1.812-3.177-6.257-1.376-6.257-1.376-5.928-3.72-11.937 1.179-11.937 1.179-7.161 1.625-2.961 7.32-2.961 7.32-7.203 3.64.044 11.757.044 11.757Z"
    />
    <path
      fill="#2f2e41"
      d="M554.732 385.752h-8.678a26.163 26.163 0 0 0 .006-3.21c-.518-2.065 6.772-4.856 6.992-4.94l.006-.003h.003l1.39.747ZM610.09 385.752h-9.114l-1.138-11.233 1.277-.933s.116.026.321.078h.006a25.003 25.003 0 0 1 5.99 2.198 3.711 3.711 0 0 1 1.052.88 1.085 1.085 0 0 1 .249.77c-.047.42.486 3.512 1.358 8.24Z"
    />
    <path
      fill="#f0f0f0"
      d="M643.021 269.125H516.495a1.208 1.208 0 0 1-1.303-1.078V165.952a1.208 1.208 0 0 1 1.303-1.079H643.02a1.208 1.208 0 0 1 1.303 1.079v102.095a1.208 1.208 0 0 1-1.303 1.078Z"
    />
    <path
      fill="#fff"
      d="M629.268 176.165h-99.02a1.305 1.305 0 0 0-1.303 1.303v76.726a1.305 1.305 0 0 0 1.303 1.303h99.02a1.305 1.305 0 0 0 1.303-1.303v-76.726a1.305 1.305 0 0 0-1.303-1.303Z"
    />
    <path
      fill="currentColor"
      d="M604.131 255.497c.559-2.012.993-3.746 1.216-4.974a3.547 3.547 0 0 0 .02-1.146c-.834-5.933-15.895-10.948-17.826-11.567l-.466-5.166-18.959-2.04-2.4 6.606-6.804 2.551a3.241 3.241 0 0 0-.698.36 3.369 3.369 0 0 0-1.387 3.592l2.898 11.784h44.406Z"
    />
    <path
      fill="currentColor"
      d="M564.83 255.497h-14.711c.527-3.283.999-6.187 1.305-8.034 1.124-6.723 6.023-7.296 6.79-7.343.06-.006.098-.006.101-.006h3.095ZM604.131 255.497h3.567a11.608 11.608 0 0 0-2.33-6.12 7.312 7.312 0 0 0-2.551-2.123l-.27-.115-3.135 8.358Z"
    />
    <circle
      cx={576.855}
      cy={213.683}
      r={13.651}
      fill="#a0616a"
      data-name="Ellipse 109"
    />
    <path
      fill="#2f2e41"
      d="M567.692 207.033c1.413 1.672 3.804 2.144 6.01 2.336a56.61 56.61 0 0 0 15.373-1.255c.315 3.028-.539 6.294 1.244 8.785a40.788 40.788 0 0 0 1.45-9.246 8.856 8.856 0 0 0-.513-3.96 3.642 3.642 0 0 0-3.102-2.287 7.776 7.776 0 0 1 3.469-1.482l-4.335-2.179 1.113-1.142-7.847-.48 2.273-1.444a49.284 49.284 0 0 0-10.328-.325 8.86 8.86 0 0 0-4.567 1.27c-1.295.926-2.044 2.767-1.24 4.128a5.994 5.994 0 0 0-4.568 3.624 9.676 9.676 0 0 0-.346 4.651 32.968 32.968 0 0 0 1.728 7.314"
      data-name="Path 696"
    />
    <path
      fill="#e4e4e4"
      d="M177.736 396.49V165.627a2.76 2.76 0 0 1 2.99-2.439h290.273a2.76 2.76 0 0 1 2.989 2.439v230.861a2.76 2.76 0 0 1-2.99 2.439H180.726a2.76 2.76 0 0 1-2.989-2.439Z"
    />
    <path
      fill="#fff"
      d="M209.287 192.084v176.024a2.994 2.994 0 0 0 2.99 2.99h227.17a2.994 2.994 0 0 0 2.99-2.99V192.084a2.994 2.994 0 0 0-2.99-2.989h-227.17a2.994 2.994 0 0 0-2.99 2.99Z"
    />
    <path
      fill="currentColor"
      d="M303.51 371.097h45.72l-2.85-42.763-16.493 3.806-16.041-6.171-.425-.16-.027-.013a.215.215 0 0 0-.013.04c-.253 1.016-5.288 21.176-9.87 45.261Z"
    />
    <path
      fill="#2f2e41"
      d="m275.957 335.315 4.271 35.782h38.533l-4.915-45.128-.047-.439-.378.279-.04.027-9.506 6.935-27.918 2.544zM342.468 371.097h34.507l.498-28.17-25.394-8.423-5.699-6.17s-1.8 18.565-3.912 42.763Z"
    />
    <path
      fill="#2f2e41"
      d="M259.345 371.097h28.595l-1.833-25.633-10.15-10.15s-8.03 16.846-16.612 35.783ZM368.838 371.097h15.085c-.066-8.21-.106-13.882-.106-15.483a14.952 14.952 0 0 0-6.344-12.687l-7.619 2.537ZM371.191 314.877h-79.933v-33.69c0-11.546 8.134-25.427 18.133-30.944 14.033-7.743 29.375-7.879 42.09-.372a40.184 40.184 0 0 1 19.71 34.443Z"
    />
    <circle
      cx={510.808}
      cy={422.879}
      r={28.871}
      fill="#ffb8b8"
      transform="rotate(-61.337 305.702 504.109)"
    />
    <path
      fill="#2f2e41"
      d="M360.25 286.665h-41.189l-.097-.468a65.962 65.962 0 0 0-1.845-7.266 82.637 82.637 0 0 0-4.52 7.432l-.167.302h-8.008a3.535 3.535 0 0 1-2.61-1.154c-.217-.24-.727-.801 1.382-26.547a3.556 3.556 0 0 1 2.016-2.877c18.072-8.437 36.387-8.214 54.438.667h.322l.113.447c3.561 27.403 3.05 27.995 2.83 28.247a3.525 3.525 0 0 1-2.665 1.217Z"
    />
  </svg>
);
export default SvgGlpi;
