import React from "react";
import { useNavigate } from "react-router-dom";

import SubPageContainer from "components/templates/SubPageContainer";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import OptionCard from "components/atoms/OptionCard";
import Sites from "draws/home/Sites";
import SocialNetwork from "draws/social/SocialNetwork";

const Fields = () => {
  const navigate = useNavigate();

  const handleClickSites = () => {
    navigate("/sites");
  };

  const handleClickRedesSociais = () => {
    navigate("/redes-sociais");
  };

  return (
    <Box
      sx={{
        mt: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Grid container spacing={2} width={{ xs: 300, sm: 604 }}>
        <Grid item xs={12} sm={6}>
          <OptionCard
            title="Sites"
            description="Institucionais"
            image={<Sites />}
            action={handleClickSites}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <OptionCard
            title="Redes Sociais"
            description="Institucionais"
            image={<SocialNetwork />}
            action={handleClickRedesSociais}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const SitesRedes = () => {
  return (
    <SubPageContainer>
      <Fields />
    </SubPageContainer>
  );
};

export default SitesRedes;
