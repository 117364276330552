import * as React from "react";
const SvgWindows = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10em"
    height="10em"
    data-name="Layer 1"
    viewBox="0 0 848.713 634"
    {...props}
  >
    <path fill="#fff" d="M330.69 176H0V0h330.69Z" />
    <path fill="#e4e4e4" d="M1.184 1.184h329.112v41.83H1.184z" />
    <path
      fill="#cacaca"
      d="M330.69 176H0V0h330.69ZM2.369 173.632h325.955V2.368H2.368Z"
    />
    <path
      fill="#e4e4e4"
      d="M149.19 77.905c-1.563.005-2.828 2.035-2.825 4.535.004 2.492 1.267 4.512 2.826 4.517h133.471c1.564-.005 2.829-2.035 2.825-4.535-.003-2.493-1.266-4.512-2.825-4.517Z"
      data-name="Path 155"
    />
    <path
      fill="#e4e4e4"
      d="M149.19 104.132c-1.563.005-2.828 2.035-2.825 4.535.004 2.493 1.267 4.512 2.826 4.517h133.471c1.564-.005 2.829-2.035 2.825-4.535-.003-2.493-1.266-4.512-2.825-4.517Z"
      data-name="Path 156"
    />
    <path
      fill="#e4e4e4"
      d="M149.19 130.042c-1.563.005-2.828 2.035-2.825 4.535.004 2.493 1.267 4.512 2.826 4.517h57.433c1.563-.005 2.828-2.035 2.825-4.535-.003-2.492-1.266-4.512-2.825-4.517Z"
      data-name="Path 157"
    />
    <circle cx={81.422} cy={108.156} r={36.219} fill="#e4e4e4" />
    <path fill="#fff" d="M724.52 176H393.83V0h330.69Z" />
    <path fill="#e4e4e4" d="M395.013 1.184h329.112v41.83H395.013z" />
    <path
      fill="#cacaca"
      d="M724.52 176H393.83V0h330.69Zm-328.323-2.368h325.955V2.368H396.197Z"
    />
    <path
      fill="#e4e4e4"
      d="M543.02 77.905c-1.563.005-2.828 2.035-2.825 4.535.003 2.492 1.266 4.512 2.825 4.517h133.472c1.563-.005 2.828-2.035 2.825-4.535-.003-2.493-1.266-4.512-2.825-4.517Z"
      data-name="Path 155"
    />
    <path
      fill="#e4e4e4"
      d="M543.02 104.132c-1.563.005-2.828 2.035-2.825 4.535.003 2.493 1.266 4.512 2.825 4.517h133.472c1.563-.005 2.828-2.035 2.825-4.535-.003-2.493-1.266-4.512-2.825-4.517Z"
      data-name="Path 156"
    />
    <path
      fill="#e4e4e4"
      d="M543.02 130.042c-1.563.005-2.828 2.035-2.825 4.535.003 2.493 1.266 4.512 2.825 4.517h57.433c1.564-.005 2.829-2.035 2.826-4.535-.003-2.492-1.267-4.512-2.826-4.517Z"
      data-name="Path 157"
    />
    <circle cx={475.251} cy={108.156} r={36.219} fill="#e4e4e4" />
    <path fill="#fff" d="M571.76 348.5h-419v-223h419Z" />
    <path fill="currentColor" d="M154.26 127h417v53h-417z" />
    <path
      fill="#3f3d56"
      d="M571.76 348.5h-419v-223h419Zm-416-3h413v-217h-413Z"
    />
    <path
      fill="#e4e4e4"
      d="M341.792 224.209c-1.981.006-3.584 2.579-3.58 5.746.004 3.158 1.604 5.717 3.58 5.723h169.114c1.98-.006 3.584-2.578 3.58-5.746-.004-3.158-1.605-5.717-3.58-5.723Z"
      data-name="Path 155"
    />
    <path
      fill="#e4e4e4"
      d="M341.792 257.44c-1.981.006-3.584 2.579-3.58 5.746.004 3.159 1.604 5.717 3.58 5.724h169.114c1.98-.007 3.584-2.58 3.58-5.747-.004-3.158-1.605-5.717-3.58-5.723Z"
      data-name="Path 156"
    />
    <path
      fill="#e4e4e4"
      d="M341.792 290.27c-1.981.006-3.584 2.578-3.58 5.745.004 3.159 1.604 5.718 3.58 5.724h72.77c1.98-.007 3.584-2.58 3.58-5.746-.004-3.159-1.605-5.717-3.58-5.724Z"
      data-name="Path 157"
    />
    <circle cx={255.925} cy={262.539} r={45.891} fill="#e4e4e4" />
    <path
      fill="#ffb6b6"
      d="m751.352 607.074-11.199 4.989-24.573-40.821 16.529-7.363 19.243 43.195z"
    />
    <path
      fill="#2f2e41"
      d="m759.044 616.657-36.11 16.087-.203-.457a15.387 15.387 0 0 1 7.793-20.316l22.055-9.826Z"
    />
    <path
      fill="#ffb6b6"
      d="M694.914 378.662a10.743 10.743 0 0 0-1.14-16.433l-11.865-97.123-18.501 4.556 14.823 95.516a10.8 10.8 0 0 0 16.683 13.484Z"
    />
    <path
      fill="currentColor"
      d="M688.585 280.752a5.313 5.313 0 0 1-3.026 1.339l-24.205 2.44a5.316 5.316 0 0 1-5.844-5.537l1.13-24.228a13.584 13.584 0 0 1 3.07-10.028 13.88 13.88 0 0 1 24.555 7.396l5.916 23.364a5.32 5.32 0 0 1-1.596 5.254Z"
    />
    <path
      fill="currentColor"
      d="m673.507 342.586-67.3-13.378-.45-9.397.01-.06 7.255-48.197c-.975-2.033-9.605-21.04 2.933-31.696a65.998 65.998 0 0 0 8.153-8.297 38.73 38.73 0 0 1 32.939-14.458 26.926 26.926 0 0 1 19.62 11.034 32.853 32.853 0 0 1 6.306 23.304Z"
    />
    <path
      fill="#ffb6b6"
      d="m605.587 620.86-12.259-.001-5.833-47.288 18.095.001-.003 47.288z"
    />
    <path
      fill="#2f2e41"
      d="m608.714 632.744-39.53-.002v-.5a15.387 15.387 0 0 1 15.386-15.386l24.145.001ZM586.76 602.5l-2.664-196.914A151.803 151.803 0 0 1 606.26 322s59.5-.5 69 20l71 247-20 9-85-185-30.5 190.5Z"
    />
    <path
      fill="#ffb6b6"
      d="M577.04 272.537A11.822 11.822 0 0 1 578.19 274l55.698.06 6.797-11.7 18.999 7.95-10.02 21.883a8.985 8.985 0 0 1-9.474 5.149l-62.695-9.205a11.79 11.79 0 1 1-.456-15.6Z"
    />
    <path
      fill="currentColor"
      d="m628.76 275.28 8.114-41.655a17.888 17.888 0 0 1 19.165-14.395 17.888 17.888 0 0 1 15.311 23.626l-12.68 36.924Z"
    />
    <circle cx={657.04} cy={182.874} r={24.561} fill="#ffb6b6" />
    <path
      fill="#2f2e41"
      d="M627.26 169h39v-17c-8.56-3.4-16.936-6.293-22 0a17 17 0 0 0-17 17Z"
    />
    <path
      fill="#2f2e41"
      d="M668.42 149c23.314 0 29.84 29.224 29.84 45.711 0 9.195-4.158 12.483-10.692 13.596L685.26 196l-5.405 12.837c-1.835.009-3.763-.027-5.762-.064L672.26 205l-4.086 3.706c-16.367.024-29.595 2.41-29.595-13.995 0-16.487 5.723-45.711 29.84-45.711Z"
    />
    <path
      fill="#cacaca"
      d="M847.713 634h-381a1 1 0 0 1 0-2h381a1 1 0 1 1 0 2Z"
    />
  </svg>
);
export default SvgWindows;
