import ThemeProvider, { ThemeNames } from "styles/ThemeProvider";
import GlobalStyles from "styles/GlobalStyles";

import { BrowserRouter as Router } from "react-router-dom";
import Routes from "routes";

function App() {
  return (
    <ThemeProvider theme={ThemeNames.mainTheme}>
      <GlobalStyles />
      <Router>
        <Routes />
      </Router>
    </ThemeProvider>
  );
}

export default App;
