import * as React from "react";
const SvgApple = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10em"
    height="10em"
    data-name="Layer 1"
    viewBox="0 0 728.252 598.943"
    {...props}
  >
    <path
      fill="#f0f0f0"
      d="M592.675 60.197c4.653-18.979.229-41.112-14.804-53.596a90.031 90.031 0 0 1-22.02 57.757c-5.393 6.171-12.034 12.473-12.296 20.664-.163 5.096 2.325 9.992 5.754 13.766 3.428 3.775 7.753 6.6 12.027 9.382l.628 1.107c13.757-13.877 26.058-30.102 30.711-49.08Z"
    />
    <path
      fill="#fff"
      d="M577.569 6.878a76.953 76.953 0 0 1 2.45 47.283 33.138 33.138 0 0 1-4.048 9.561 19.006 19.006 0 0 1-7.557 6.554c-2.824 1.428-5.88 2.562-8.307 4.653a11.638 11.638 0 0 0-3.915 8.675c-.118 4.086 1.24 7.972 2.7 11.725 1.622 4.167 3.372 8.47 2.997 13.032-.045.553.815.59.86.039.652-7.938-4.739-14.643-5.594-22.342-.399-3.593.282-7.327 2.939-9.947 2.322-2.29 5.472-3.463 8.344-4.884a20.281 20.281 0 0 0 7.628-6.133 30.636 30.636 0 0 0 4.4-9.37 74.658 74.658 0 0 0 2.986-23.143 78.317 78.317 0 0 0-5.12-26.103c-.198-.517-.96-.113-.763.4Z"
    />
    <path
      fill="#fff"
      d="M581.659 49.208a11.545 11.545 0 0 0 12.52-8.17c.155-.533-.664-.8-.82-.267a10.69 10.69 0 0 1-11.661 7.577c-.55-.073-.585.788-.04.86ZM569.942 69.74a22.253 22.253 0 0 1-4.72-15.515c.043-.553-.818-.591-.861-.039a23.146 23.146 0 0 0 4.945 16.135c.345.435.98-.148.636-.58ZM582.21 24.13a6.535 6.535 0 0 1-5.676-2.5c-.34-.44-.973.145-.636.58a7.323 7.323 0 0 0 6.272 2.78.445.445 0 0 0 .45-.41.433.433 0 0 0-.41-.45Z"
    />
    <path
      fill="#f0f0f0"
      d="M512.861 18.887c.204.28.409.56.61.848a86.065 86.065 0 0 1 7.223 11.947c.166.32.33.647.486.97a90.732 90.732 0 0 1 8.806 32.224 88.112 88.112 0 0 1 .062 13.388c-.44 6.156-1.408 12.796.164 18.568a15.114 15.114 0 0 0 .583 1.778l29.769 11.347c.08-.008.159-.01.24-.018l1.168.508c.032-.229.06-.467.093-.696.02-.132.033-.267.052-.4.01-.09.021-.179.036-.258.004-.03.007-.06.008-.083.015-.08.02-.156.033-.229q.247-1.994.467-3.999c.002-.007.002-.007-.002-.016a129.556 129.556 0 0 0 .007-30.63c-.04-.303-.077-.613-.131-.921a84.08 84.08 0 0 0-2.977-13.394 74.33 74.33 0 0 0-2.608-7.054 61.81 61.81 0 0 0-10.327-16.336c-8.528-9.707-20.066-16.578-32.79-17.484a45.585 45.585 0 0 0-.972-.06Z"
    />
    <path
      fill="#fff"
      d="M512.784 19.29a76.953 76.953 0 0 1 30.424 36.278 33.138 33.138 0 0 1 2.524 10.071 19.006 19.006 0 0 1-2.088 9.783c-1.395 2.84-3.151 5.586-3.832 8.716a11.638 11.638 0 0 0 2.097 9.284c2.367 3.334 5.79 5.618 9.216 7.736 3.803 2.35 7.791 4.733 10.239 8.601.296.469 1.006-.019.71-.487-4.258-6.73-12.6-8.838-17.918-14.47-2.481-2.629-4.186-6.021-3.642-9.712.475-3.227 2.284-6.059 3.722-8.923a20.281 20.281 0 0 0 2.398-9.49 30.636 30.636 0 0 0-2.128-10.13 74.658 74.658 0 0 0-11.55-20.276 78.317 78.317 0 0 0-19.804-17.76c-.469-.293-.833.488-.368.78Z"
    />
    <path
      fill="#fff"
      d="M541.535 50.626a11.545 11.545 0 0 0 5.077-14.062c-.196-.518-1.01-.238-.815.281a10.69 10.69 0 0 1-4.749 13.07c-.482.274.008.983.487.711ZM544.541 74.074a22.253 22.253 0 0 1-13.11-9.545c-.298-.468-1.008.02-.71.487a23.146 23.146 0 0 0 13.662 9.905c.538.139.693-.708.158-.847ZM526.875 30.27a6.535 6.535 0 0 1-6.036 1.422c-.535-.146-.69.702-.158.847a7.323 7.323 0 0 0 6.681-1.558.445.445 0 0 0 .112-.598.433.433 0 0 0-.599-.112Z"
    />
    <path
      fill="#e4e4e4"
      d="M473.5 447.587c-99.804 0-181-81.197-181-181s81.196-181 181-181a182.729 182.729 0 0 1 31.477 2.727 1 1 0 1 1-.345 1.97 180.782 180.782 0 0 0-31.132-2.697c-98.7 0-179 80.298-179 179s80.3 179 179 179 179-80.3 179-179a178.548 178.548 0 0 0-46.695-120.57 1 1 0 0 1 1.478-1.347A180.544 180.544 0 0 1 654.5 266.587c0 99.803-81.196 181-181 181Z"
    />
    <circle cx={553.307} cy={111.852} r={35.811} fill="currentColor" />
    <path
      fill="#fff"
      d="M548.422 130.49a3.982 3.982 0 0 1-3.186-1.594l-9.77-13.026a3.983 3.983 0 1 1 6.373-4.78l6.392 8.522 16.416-24.625a3.984 3.984 0 0 1 6.63 4.42l-19.54 29.31a3.985 3.985 0 0 1-3.204 1.772 6.29 6.29 0 0 1-.11.001Z"
    />
    <path
      fill="#3f3d56"
      d="M468.843 238.498 413.62 84.867l1.4-.504-10.156-28.256-1.4.504-16.957-47.17A14.255 14.255 0 0 0 368.27.848l-99.832 35.885a14.256 14.256 0 0 0-8.594 18.237l11.822 32.89-2.213.852L277 108.309l1.771-.682 1.567 4.359-1.885.726L286 132.309l1.444-.556 54.736 152.274a14.23 14.23 0 0 0 18.238 8.594l99.832-35.885a14.273 14.273 0 0 0 8.593-18.238Z"
    />
    <path
      fill="#fff"
      d="M355.167 287.557a6.845 6.845 0 0 1-6.424-4.524L265.538 51.559a6.831 6.831 0 0 1 4.118-8.739l21.241-7.635-.032.743a7.874 7.874 0 0 0 10.53 7.742l47.14-16.945a7.974 7.974 0 0 0 4.488-5.636 7.808 7.808 0 0 0-1.86-6.835l-.498-.553 21.941-7.887a6.84 6.84 0 0 1 8.74 4.118l79.384 220.846a18.147 18.147 0 0 1-10.927 23.188l-92.321 33.185a6.842 6.842 0 0 1-2.315.406Z"
    />
    <circle cx={347.033} cy={96.377} r={27} fill="currentColor" />
    <path
      fill="#e6e6e6"
      d="m413.94 135.642-93.503 33.61a2.362 2.362 0 1 1-1.598-4.445l93.502-33.61a2.362 2.362 0 0 1 1.598 4.445ZM386.172 156.952l-30.756 11.055a2.362 2.362 0 1 1-1.597-4.445l30.755-11.055a2.362 2.362 0 0 1 1.598 4.445ZM393.487 177.303l-30.755 11.055a2.362 2.362 0 1 1-1.598-4.445l30.755-11.056a2.362 2.362 0 0 1 1.598 4.446ZM397.234 187.726l-30.756 11.055a2.362 2.362 0 1 1-1.597-4.445l30.755-11.055a2.362 2.362 0 1 1 1.598 4.445ZM407.027 160.784l-65.253 23.456a2.362 2.362 0 1 1-1.598-4.445l65.253-23.456a2.362 2.362 0 1 1 1.598 4.445Z"
    />
    <path
      fill="currentColor"
      d="m393.884 213.03-11.168 4.014a4.016 4.016 0 0 1-2.717-7.56l11.168-4.013a4.016 4.016 0 0 1 2.717 7.558Z"
    />
    <circle cx={305.882} cy={34.38} r={1.553} fill="#fff" />
    <path
      fill="#f0f0f0"
      d="M127.283 121.724c17.406-20.489 45.647-33.4 71.684-26.706a123.863 123.863 0 0 0-46.635 71.113c-2.53 10.987-4.201 23.47-13.122 30.366-5.55 4.291-13.014 5.464-19.986 4.685-6.972-.779-13.608-3.323-20.155-5.844l-1.74.193c4.378-26.525 12.549-53.319 29.954-73.807Z"
    />
    <path
      fill="#fff"
      d="M198.901 95.578a105.87 105.87 0 0 0-54.686 35.388 45.591 45.591 0 0 0-7.395 12.222 26.149 26.149 0 0 0-1.213 13.708c.684 4.3 1.883 8.62 1.51 13.012a16.01 16.01 0 0 1-6.514 11.359c-4.461 3.426-9.887 5.044-15.249 6.442-5.952 1.552-12.16 3.07-16.944 7.166-.58.497-1.315-.432-.737-.927 8.324-7.127 20.145-6.523 29.417-11.777 4.327-2.451 7.941-6.222 8.72-11.295.68-4.436-.552-8.892-1.283-13.24a27.903 27.903 0 0 1 .687-13.448 42.148 42.148 0 0 1 6.899-12.46 102.712 102.712 0 0 1 23.391-21.987 107.747 107.747 0 0 1 33.229-15.336c.735-.197.898.978.168 1.173Z"
    />
    <path
      fill="#fff"
      d="M148.415 125.146a15.884 15.884 0 0 1-.985-20.545c.467-.602 1.425.096.957.699a14.708 14.708 0 0 0 .955 19.109c.524.554-.407 1.288-.927.737ZM134.972 154.761a30.615 30.615 0 0 0 21.102-7.246c.581-.494 1.318.434.737.928a31.844 31.844 0 0 1-21.974 7.495c-.763-.034-.624-1.211.135-1.177ZM175.928 104.313a8.991 8.991 0 0 0 7.362 4.312c.764.024.624 1.201-.134 1.177A10.075 10.075 0 0 1 175 105.05a.612.612 0 0 1 .095-.832.595.595 0 0 1 .832.095Z"
    />
    <path
      fill="#f0f0f0"
      d="M237.683 177.396a85.067 85.067 0 0 0-1.437.002 118.406 118.406 0 0 0-19.142 1.58c-.49.074-.987.155-1.474.242a124.827 124.827 0 0 0-43.022 16.163 121.222 121.222 0 0 0-14.974 10.724c-6.508 5.454-13.13 11.886-20.833 14.787a20.794 20.794 0 0 1-2.451.784L97.7 197.639c-.057-.096-.118-.184-.175-.281l-1.507-.893c.229-.22.471-.445.7-.665.132-.129.272-.252.405-.38.09-.085.181-.168.259-.25.03-.027.06-.055.085-.075.077-.081.157-.148.229-.22q2.024-1.884 4.082-3.745c.005-.009.005-.009.019-.012 10.477-9.428 21.834-18.064 34.14-24.702.37-.2.746-.408 1.133-.596a115.674 115.674 0 0 1 17.332-7.48 102.264 102.264 0 0 1 9.967-2.78 85.037 85.037 0 0 1 26.536-1.658c17.697 1.682 34.66 9.004 45.927 22.457.288.345.568.684.85 1.037Z"
    />
    <path
      fill="#fff"
      d="M237.295 177.807a105.87 105.87 0 0 0-64.97-4.669 45.59 45.59 0 0 0-13.263 5.306 26.149 26.149 0 0 0-9.222 10.214c-2.042 3.846-3.686 8.017-6.628 11.3a16.01 16.01 0 0 1-12.04 5.147c-5.624.049-10.931-1.926-16.053-4.038-5.688-2.344-11.558-4.87-17.844-4.479-.762.047-.79-1.137-.03-1.184 10.936-.68 20.011 6.92 30.578 8.308 4.93.647 10.086-.187 13.762-3.769 3.215-3.132 4.913-7.431 6.948-11.344a27.902 27.902 0 0 1 8.645-10.323 42.148 42.148 0 0 1 13.01-5.796 102.713 102.713 0 0 1 31.914-3.472 107.747 107.747 0 0 1 35.765 7.761c.705.285.128 1.322-.572 1.038Z"
    />
    <path
      fill="#fff"
      d="M179.183 171.019a15.884 15.884 0 0 1 11.583-16.997c.735-.2 1.08.935.343 1.135a14.708 14.708 0 0 0-10.742 15.832c.084.758-1.1.783-1.184.03ZM150.619 186.572a30.615 30.615 0 0 0 21.211 6.92c.762-.045.79 1.14.03 1.184a31.844 31.844 0 0 1-22.057-7.246c-.589-.487.23-1.343.816-.858ZM213.693 170.95a8.991 8.991 0 0 0 3.283 7.875c.594.48-.226 1.335-.817.859a10.075 10.075 0 0 1-3.65-8.704.612.612 0 0 1 .577-.607.595.595 0 0 1 .607.577Z"
    />
    <path
      fill="#f0f0f0"
      d="M649.65 515.514c18.978 4.653 41.112.229 53.595-14.804a90.031 90.031 0 0 1-57.757-22.02c-6.17-5.394-12.472-12.035-20.664-12.296-5.096-.163-9.992 2.325-13.766 5.753-3.775 3.429-6.6 7.754-9.382 12.028l-1.107.627c13.877 13.758 30.102 26.059 49.08 30.712Z"
    />
    <path
      fill="#fff"
      d="M702.968 500.408a76.953 76.953 0 0 1-47.283 2.45 33.138 33.138 0 0 1-9.56-4.048 19.006 19.006 0 0 1-6.555-7.557c-1.428-2.825-2.562-5.88-4.653-8.308a11.638 11.638 0 0 0-8.675-3.915c-4.086-.117-7.971 1.241-11.724 2.701-4.168 1.621-8.47 3.371-13.033 2.997-.553-.046-.59.815-.039.86 7.938.652 14.643-4.74 22.342-5.594 3.593-.4 7.328.282 9.947 2.938 2.29 2.323 3.463 5.472 4.884 8.345a20.281 20.281 0 0 0 6.133 7.628 30.636 30.636 0 0 0 9.37 4.4 74.657 74.657 0 0 0 23.143 2.986 78.317 78.317 0 0 0 26.103-5.12c.517-.198.113-.96-.4-.763Z"
    />
    <path
      fill="#fff"
      d="M660.638 504.498a11.545 11.545 0 0 0 8.17 12.52c.533.155.8-.664.267-.82a10.69 10.69 0 0 1-7.577-11.661c.073-.55-.788-.586-.86-.04ZM640.106 492.78a22.253 22.253 0 0 1 15.515-4.72c.553.043.591-.817.039-.86a23.146 23.146 0 0 0-16.135 4.945c-.435.345.149.98.581.636ZM685.717 505.048a6.535 6.535 0 0 1 2.5-5.675c.438-.34-.146-.974-.582-.636a7.323 7.323 0 0 0-2.779 6.272.445.445 0 0 0 .41.45.433.433 0 0 0 .45-.411Z"
    />
    <path
      fill="#f0f0f0"
      d="M690.959 435.7c-.28.204-.56.408-.847.61a86.065 86.065 0 0 1-11.948 7.223c-.32.166-.647.33-.97.486a90.732 90.732 0 0 1-32.224 8.805 88.112 88.112 0 0 1-13.388.063c-6.156-.441-12.796-1.409-18.568.164a15.114 15.114 0 0 0-1.778.582l-11.347 29.77c.008.08.01.159.018.24l-.508 1.167c.229.033.467.062.696.094.132.02.267.032.4.052.09.01.179.021.259.036.03.004.059.007.082.008.08.015.156.02.229.033q1.994.247 3.999.466c.007.003.007.003.016-.001a129.556 129.556 0 0 0 30.63.007c.303-.04.613-.077.921-.131a84.08 84.08 0 0 0 13.394-2.977 74.33 74.33 0 0 0 7.054-2.609 61.81 61.81 0 0 0 16.336-10.326c9.707-8.528 16.578-20.066 17.484-32.79.023-.325.044-.644.06-.972Z"
    />
    <path
      fill="#fff"
      d="M690.556 435.623a76.953 76.953 0 0 1-36.278 30.423 33.138 33.138 0 0 1-10.07 2.525 19.006 19.006 0 0 1-9.783-2.088c-2.842-1.395-5.586-3.152-8.717-3.832a11.638 11.638 0 0 0-9.284 2.097c-3.334 2.367-5.618 5.79-7.735 9.216-2.352 3.803-4.733 7.79-8.602 10.239-.469.296.019 1.006.487.71 6.73-4.259 8.838-12.6 14.47-17.918 2.629-2.482 6.021-4.186 9.712-3.642 3.227.475 6.059 2.284 8.924 3.721a20.281 20.281 0 0 0 9.489 2.398 30.636 30.636 0 0 0 10.131-2.128 74.658 74.658 0 0 0 20.275-11.549 78.317 78.317 0 0 0 17.76-19.805c.293-.468-.488-.833-.78-.367Z"
    />
    <path
      fill="#fff"
      d="M659.22 464.374a11.545 11.545 0 0 0 14.062 5.076c.518-.195.238-1.01-.28-.814a10.69 10.69 0 0 1-13.072-4.75c-.273-.481-.981.008-.71.488ZM635.772 467.38a22.253 22.253 0 0 1 9.545-13.11c.468-.298-.02-1.008-.486-.71a23.146 23.146 0 0 0-9.906 13.662c-.139.538.709.693.847.158ZM679.575 449.714a6.535 6.535 0 0 1-1.42-6.036c.145-.536-.702-.69-.847-.158a7.323 7.323 0 0 0 1.557 6.68.445.445 0 0 0 .598.113.433.433 0 0 0 .112-.599Z"
    />
    <path
      fill="#3f3d56"
      d="m725.697 333.101-28.94 48.7 1.1.58-9.8 18.58-2.04-1.08-6.44 10.83 1.6.92-10.5 18.18-1.82-1.05-94.43 158.92a18.274 18.274 0 0 1-25.02 6.37l-175.55-104.31a18.272 18.272 0 0 1-6.37-25.02l151.27-254.58a18.274 18.274 0 0 1 25.02-6.37l175.55 104.31a18.28 18.28 0 0 1 6.37 25.02Z"
    />
    <path
      fill="#fff"
      d="M555.663 584.224 379.495 479.548a5.634 5.634 0 0 1-1.964-7.713l152.274-256.273a5.634 5.634 0 0 1 7.713-1.964l176.168 104.677a5.634 5.634 0 0 1 1.963 7.712L563.376 582.261a5.634 5.634 0 0 1-7.713 1.963Z"
    />
    <circle cx={628.028} cy={260.333} r={1.553} fill="#fff" />
    <path
      fill="#e6e6e6"
      d="M618.417 445.465 471.36 358.086a4.066 4.066 0 1 1 4.154-6.99l147.057 87.378a4.066 4.066 0 1 1-4.154 6.99ZM559.698 431.925l-48.37-28.741a4.066 4.066 0 1 1 4.153-6.991l48.371 28.741a4.066 4.066 0 0 1-4.154 6.991ZM540.68 463.932l-48.37-28.741a4.066 4.066 0 0 1 4.153-6.991l48.371 28.741a4.066 4.066 0 1 1-4.154 6.991ZM530.94 480.326l-48.372-28.741a4.066 4.066 0 1 1 4.154-6.991l48.371 28.74a4.066 4.066 0 1 1-4.154 6.992ZM577.45 463.823l-102.626-60.98a4.066 4.066 0 0 1 4.154-6.99l102.627 60.98a4.066 4.066 0 1 1-4.154 6.99Z"
    />
    <path
      fill="currentColor"
      d="m557.89 541.755-17.563-10.436a6.914 6.914 0 0 1 7.063-11.888l17.564 10.436a6.914 6.914 0 0 1-7.064 11.888Z"
    />
    <circle cx={590.659} cy={336.314} r={36} fill="currentColor" />
    <path
      fill="#cacaca"
      d="M.424 482.606h487.18V200.557a7.98 7.98 0 0 0-7.97-7.97H8.395a7.98 7.98 0 0 0-7.971 7.97Z"
    />
    <path fill="#fff" d="M15.476 203.611h457.077v259.491H15.476z" />
    <path
      fill="#3f3d56"
      d="M0 474.973v34.805a10.987 10.987 0 0 0 10.987 10.988h179.815v68.689h-2.389a1.427 1.427 0 0 0-1.427 1.427v4.777a1.427 1.427 0 0 0 1.427 1.428H300.04a1.427 1.427 0 0 0 1.427-1.428v-4.777a1.427 1.427 0 0 0-1.427-1.427h-2.389v-68.69h179.815a10.987 10.987 0 0 0 10.987-10.987v-34.805Z"
    />
    <path
      fill="#e6e6e6"
      d="M406.413 290.394H189.96a5.145 5.145 0 1 1 0-10.29h216.454a5.145 5.145 0 1 1 0 10.29ZM333.785 313.62h-71.198a5.145 5.145 0 1 1 0-10.29h71.198a5.145 5.145 0 1 1 0 10.29ZM333.785 360.731h-71.198a5.145 5.145 0 0 1 0-10.29h71.198a5.145 5.145 0 0 1 0 10.29ZM333.785 384.861h-71.198a5.145 5.145 0 1 1 0-10.29h71.198a5.145 5.145 0 1 1 0 10.29ZM373.715 336.845H222.657a5.145 5.145 0 1 1 0-10.29h151.058a5.145 5.145 0 1 1 0 10.29Z"
    />
    <path
      fill="currentColor"
      d="M402.81 434.266h-25.852a8.749 8.749 0 0 1 0-17.498h25.852a8.749 8.749 0 0 1 0 17.498Z"
    />
    <circle cx={121.895} cy={325.766} r={45} fill="currentColor" />
    <path
      fill="#cacaca"
      d="M648.485 598.943H1.19a1.19 1.19 0 0 1 0-2.382h647.294a1.19 1.19 0 0 1 0 2.382Z"
    />
    <circle cx={243.802} cy={196.403} r={1.696} fill="#fff" />
  </svg>
);
export default SvgApple;
