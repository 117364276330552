import * as React from "react";
const SvgGlpiConta = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10em"
    height="10em"
    data-name="Layer 1"
    viewBox="0 0 824.808 438.317"
    {...props}
  >
    <path
      fill="#e6e6e6"
      d="M819.47 437.184H360.203a5.345 5.345 0 0 1-5.338-5.338V5.338A5.345 5.345 0 0 1 360.203 0H819.47a5.345 5.345 0 0 1 5.338 5.338v426.507a5.344 5.344 0 0 1-5.338 5.338ZM360.203 2.131a3.207 3.207 0 0 0-3.2 3.2v426.514a3.207 3.207 0 0 0 3.2 3.2H819.47a3.206 3.206 0 0 0 3.2-3.2V5.338a3.207 3.207 0 0 0-3.2-3.2Z"
      data-name="Path 141"
    />
    <path
      fill="#e6e6e6"
      d="M577.215 110.83a3.624 3.624 0 0 0 0 7.247H776.07a3.624 3.624 0 0 0 0-7.248Z"
      data-name="Path 142"
    />
    <path
      fill="#e6e6e6"
      d="M577.215 132.576a3.624 3.624 0 0 0 0 7.248h101.52a3.624 3.624 0 0 0 0-7.248Z"
      data-name="Path 143"
    />
    <path
      fill="#e6e6e6"
      d="M403.408 237.83a3.624 3.624 0 0 0 0 7.247h372.856a3.624 3.624 0 0 0 0-7.248Z"
      data-name="Path 142"
    />
    <path
      fill="#e6e6e6"
      d="M403.408 259.576a3.624 3.624 0 0 0 0 7.248h275.52a3.624 3.624 0 0 0 0-7.248Z"
      data-name="Path 143"
    />
    <path
      fill="#e6e6e6"
      d="M403.408 280.83a3.624 3.624 0 0 0 0 7.247h372.856a3.624 3.624 0 0 0 0-7.248Z"
      data-name="Path 142"
    />
    <path
      fill="#e6e6e6"
      d="M403.408 302.575a3.624 3.624 0 0 0 0 7.248h275.52a3.624 3.624 0 0 0 0-7.248Z"
      data-name="Path 143"
    />
    <path
      fill="currentColor"
      d="M526.959 187.184H238.69a5.344 5.344 0 0 1-5.338-5.338V55.339A5.344 5.344 0 0 1 238.69 50h288.268a5.344 5.344 0 0 1 5.338 5.338v126.507a5.344 5.344 0 0 1-5.338 5.338ZM773.959 387.184H664.69a5.344 5.344 0 0 1-5.338-5.338v-17.507A5.344 5.344 0 0 1 664.69 359h109.268a5.344 5.344 0 0 1 5.338 5.338v17.507a5.344 5.344 0 0 1-5.338 5.338Z"
      data-name="Path 154"
    />
    <path
      fill="#ffb8b8"
      d="M126.436 214.11c-4.71-3.516-6.091-10.085-3.087-14.673a9.165 9.165 0 0 1 1.009-1.26l.3-93.46 13.893 2.593.61 91.92c3.165 3.672 3.804 9.022 1.24 12.937-3.003 4.588-9.256 5.458-13.965 1.943ZM198.683 423.301l14.322-.001 6.814-55.242-21.138.001.002 55.242z"
    />
    <path
      fill="#2f2e41"
      d="m195.03 418.625 28.205-.001h.001a17.976 17.976 0 0 1 17.975 17.974v.584l-46.18.002Z"
    />
    <path
      fill="#ffb8b8"
      d="m65.506 423.301 14.323-.001 6.813-55.242-21.138.001.002 55.242z"
    />
    <path
      fill="#2f2e41"
      d="m61.853 418.625 28.206-.001a17.976 17.976 0 0 1 17.975 17.974v.584l-46.18.002ZM197.847 407.871l-1.2-7.202a5.71 5.71 0 0 1-2.969-2.383c-1.289-2.036-1.494-4.903-.61-8.52l-9.664-81.608-50.45-52.344-32.366 78.065-12.812 61.774v8.926l-25.581 3.284 1.164-8.412a4.9 4.9 0 0 1-2.323-2.07c-1.47-2.446-1.45-6.238.06-11.268l.065-.218-.083-.751c-.059-.527.326-50.986 15.326-75.986l15.023-75.902c-.39-1.069-2.317-7.293 1.216-17.81 3.486-10.378 13.235-27.097 39.886-47.81l.092-.071.113-.024c.295-.061 29.69-5.905 44.891 13.974l.103.135v.169c0 1.229-.026 29.83-2.288 36.176l36.073 66.328c.703.744 7.009 8.027 4.769 27.175l5.827 65.268c.667 1.273 6.7 13.26.051 18.006l1.192 8.241Z"
    />
    <path
      fill="currentColor"
      d="M137.5 133.6c-6.772 0-15.773-2.394-21.283-13.429l-.108-.217 13.574-28.774a15.776 15.776 0 0 1 28.828 12.805l-11.736 28.133-.204.076a29.346 29.346 0 0 1-9.07 1.406Z"
    />
    <path
      fill="currentColor"
      d="m175.102 190.648-42.793-20.207 5.882-71.762 10.724-13.106 2.077-.445a54.68 54.68 0 0 1 28.73 1.591l.204.068 17.615 37.577c.11.187 12.813 22.615-4.747 34.439Z"
    />
    <path
      fill="#ffb8b8"
      d="M233.93 167.778a10.524 10.524 0 0 0-1.474.756l-45.093-20.626-1.156-11.99-18.324-.62-.015 21.426a7.999 7.999 0 0 0 5.755 7.683l54.148 15.825a10.496 10.496 0 1 0 6.16-12.454Z"
    />
    <path
      fill="currentColor"
      d="M178.775 141.034c-5.138 0-10.937-1.777-16.503-7.343l-.172-.17 3.761-31.593a15.776 15.776 0 0 1 31.4 3.014l-2.22 30.402-.17.136c-.109.087-7.085 5.554-16.096 5.554Z"
    />
    <circle
      cx={354.945}
      cy={277.653}
      r={28.693}
      fill="#ffb8b8"
      transform="rotate(-61.337 66.512 320.405)"
    />
    <path
      fill="#2f2e41"
      d="M196.55 31.609c-1.423-8.281-8.07-15.146-15.99-17.954-7.92-2.81-16.85-1.896-24.604 1.339a40.849 40.849 0 0 0-3.783 1.819c-2.835-5.403-9.784-8.537-16.14-8.198-7.679.408-14.613 4.471-21.282 8.3s-13.825 7.64-21.513 7.46c-13.893-.321-24.23-13.202-37.589-17.03a30.06 30.06 0 0 0-33.083 45.882c5.7-10.216 19.086-13.666 30.701-12.275 11.615 1.39 22.44 6.482 33.82 9.194 11.379 2.712 24.557 2.595 33.371-5.096 5-4.362 7.936-10.597 12.288-15.605 2.945-3.388 7.136-6.248 11.419-6.921a34.655 34.655 0 0 0-9.379 13.91c-3.434 9.758-1.598 21.473 5.526 28.972 7.638 8.04 19.624 10.183 30.713 10.053l.232-.236c-3.395-5.082-6.075-12.128-2.337-16.963 3.69-4.773 10.968-4.07 16.618-6.182 7.871-2.941 12.436-12.188 11.013-20.469Z"
    />
    <path fill="#ccc" d="M274 438.316H1a1 1 0 0 1 0-2h273a1 1 0 1 1 0 2Z" />
    <path
      fill="#fff"
      d="M369.89 94.37c-1.252.004-2.265 1.63-2.262 3.631.002 1.996 1.014 3.613 2.262 3.617h106.87c1.251-.004 2.264-1.63 2.262-3.631-.003-1.996-1.014-3.613-2.263-3.617Z"
      data-name="Path 155"
    />
    <path
      fill="#fff"
      d="M369.89 115.37c-1.252.004-2.265 1.63-2.262 3.631.002 1.996 1.014 3.613 2.262 3.617h106.87c1.251-.004 2.264-1.63 2.262-3.631-.003-1.996-1.014-3.613-2.263-3.617Z"
      data-name="Path 156"
    />
    <path
      fill="#fff"
      d="M369.89 136.116c-1.252.004-2.265 1.63-2.262 3.631.002 1.996 1.014 3.613 2.262 3.617h45.986c1.252-.004 2.265-1.63 2.262-3.631-.002-1.996-1.014-3.613-2.262-3.617Z"
      data-name="Path 157"
    />
    <circle cx={315.628} cy={118.592} r={29} fill="#fff" />
  </svg>
);
export default SvgGlpiConta;
