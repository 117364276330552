import React from "react";
import SubPageContainer from "components/templates/SubPageContainer";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import OptionCard from "components/atoms/OptionCard";
import Facebook from "draws/social/Facebook";
import Instagram from "draws/social/Instagram";

import {
  linkFacebookIcex,
  linkFacebookIchs,
  linkInstagramIcex,
  linkInstagramIchs,
} from "links/links";

const href = (link) => {
  return (window.location.href = link);
};

const Fields = () => {
  const handleClickInstagramIcex = () => {
    href(linkInstagramIcex);
  };

  const handleClickInstagramIchs = () => {
    href(linkInstagramIchs);
  };

  const handleClickFacebookIcex = () => {
    href(linkFacebookIcex);
  };

  const handleClickFacebookIchs = () => {
    href(linkFacebookIchs);
  };

  return (
    <>
      <Box
        sx={{
          mt: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid container spacing={2} width={{ xs: 300, sm: 604, md: 906 }}>
          <Grid item xs={12} sm={6} md={4}>
            <OptionCard
              title="Facebook ICEx"
              description="Facebook do instituto"
              image={<Facebook />}
              action={handleClickFacebookIcex}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <OptionCard
              title="Facebook ICHS"
              description="Facebook do instituto"
              image={<Facebook />}
              action={handleClickFacebookIchs}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <OptionCard
              title="Instagram ICEx"
              description="Instagram do instituto"
              image={<Instagram />}
              action={handleClickInstagramIcex}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <OptionCard
              title="Instagram ICHS"
              description="Instagram do instituto"
              image={<Instagram />}
              action={handleClickInstagramIchs}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const Home = () => {
  return (
    <SubPageContainer>
      <Fields />
    </SubPageContainer>
  );
};

export default Home;
