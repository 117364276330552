import React, { useState, useMemo, createContext } from "react";

import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from "@mui/material/styles";

import { green } from "@mui/material/colors";

export const ColorModeContext = createContext({ toggleColorMode: () => {} });

const ThemeProvider = ({ theme, children }) => {
  const [mode, setMode] = useState("light");
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const mainTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode]
  );

  const greenTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          primary: {
            main: green[500],
          },
          secondary: {
            main: green[300],
            dark: green[400],
          },
        },
      }),
    [mode]
  );

  const allThemes = {
    mainTheme,
    greenTheme,
  };

  return (
    <ColorModeContext.Provider value={colorMode}>
      <MuiThemeProvider theme={allThemes[theme]}>{children}</MuiThemeProvider>
    </ColorModeContext.Provider>
  );
};

ThemeProvider.defaultProps = {
  theme: "mainTheme",
};

export const ThemeNames = {
  mainTheme: "mainTheme",
  greenTheme: "greenTheme",
};

export default ThemeProvider;
