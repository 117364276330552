import * as React from "react";
const SvgInstagram = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10em"
    height="10em"
    data-name="Layer 1"
    viewBox="0 0 808 419.93"
    {...props}
  >
    <path
      fill="#3f3d56"
      d="m435.154 410.996-10.659-82.605-.008-.128a9.01 9.01 0 0 1 9-9h104a9.01 9.01 0 0 1 9 9l-7.755 60.127-2.912 22.606a9.01 9.01 0 0 1-9 8.934h-82.666a9.01 9.01 0 0 1-9-8.934Zm102.333-89.733h-104a7.008 7.008 0 0 0-7 6.938l7.739 59.935 2.92 22.665.008.129a7.008 7.008 0 0 0 7 7h82.666a7.008 7.008 0 0 0 7-7l10.667-82.729a7.008 7.008 0 0 0-7-6.938Z"
    />
    <ellipse cx={485.487} cy={334.93} fill="#f2f2f2" rx={56} ry={9.333} />
    <path
      fill="#ccc"
      d="M480.639 330.73a156.834 156.834 0 0 0 11.61-59.779 163.21 163.21 0 0 0-11.44-59.879 152.825 152.825 0 0 0-16.208-30.327c-.912-1.323 1.246-2.57 2.151-1.258a158.735 158.735 0 0 1 24.312 56.901 165.83 165.83 0 0 1 1.457 61.615 154.195 154.195 0 0 1-9.48 33.389c-.601 1.462-3.013.822-2.402-.662Z"
    />
    <path
      fill="#ccc"
      d="M490.98 239.727c3.398-3.68 4.324-8.806 4.298-13.66-.032-5.767-1.45-11.412-1.72-17.162-.244-5.144.536-10.348 3.542-14.648 2.587-3.702 6.667-6.551 11.35-6.289 9.988.56 14.638 12.047 16.08 20.435a47.34 47.34 0 0 1-30.015 52.658c-1.5.571-2.148-1.836-.663-2.403a44.894 44.894 0 0 0 28.73-46.037 36.419 36.419 0 0 0-3.704-13.826c-1.916-3.623-4.897-7.221-9.082-8.149-4.123-.914-7.99 1.373-10.374 4.628-3.005 4.103-3.631 9.063-3.353 14.025.594 10.606 4.887 23.29-3.327 32.19-1.09 1.18-2.848-.584-1.761-1.762ZM489.039 285.346c-8.447-5.511-9.29-16.292-10.355-25.37-.603-5.141-1.477-10.605-4.631-14.868-2.595-3.506-6.992-6.518-11.547-5.703-4.016.72-6.73 4.035-8.208 7.64a29.912 29.912 0 0 0-1.75 13.092 45.777 45.777 0 0 0 11.248 27.355 48.843 48.843 0 0 0 11.43 9.908 36.23 36.23 0 0 0 14.45 4.924c1.572.186 1.59 2.68 0 2.491a39.347 39.347 0 0 1-13.881-4.232 46.325 46.325 0 0 1-11.362-8.724 49.58 49.58 0 0 1-14.012-27.983 38.168 38.168 0 0 1 .503-14.515c1.064-4.148 3.19-8.308 6.86-10.712a12.183 12.183 0 0 1 13.058.179c4.474 2.723 7.293 7.251 8.722 12.198 1.754 6.071 1.63 12.462 2.925 18.617 1.086 5.168 3.21 10.552 7.807 13.552 1.338.873.09 3.031-1.257 2.152Z"
    />
    <path
      fill="#3f3d56"
      d="M409.504 141.301a42 42 0 1 1 42 42 42.048 42.048 0 0 1-42-42Zm2 0a40 40 0 1 0 40-40 40.045 40.045 0 0 0-40 40Z"
    />
    <path
      fill="#3f3d56"
      d="M696.487 419.93h-62a7.008 7.008 0 0 1-7-6.933l-8-62.067a7.008 7.008 0 0 1 7-7h78a7.008 7.008 0 0 1 7 7l-.008.128-7.992 61.94a7.008 7.008 0 0 1-7 6.932Zm-75-69.062 8 62.062a5.006 5.006 0 0 0 5 5h62a5.006 5.006 0 0 0 5-5l.008-.129 7.991-61.933a5.006 5.006 0 0 0-5-4.938h-78a5.006 5.006 0 0 0-4.999 4.938Z"
    />
    <ellipse cx={665.487} cy={355.93} fill="#f2f2f2" rx={42} ry={7} />
    <path
      fill="#ccc"
      d="M666.626 348.76a123.717 123.717 0 0 1-9.157-47.09 128.6 128.6 0 0 1 9.007-47.173 120.519 120.519 0 0 1 12.774-23.884c1.097-1.594-1.502-3.095-2.59-1.514a125.983 125.983 0 0 0-19.25 45.015 131.778 131.778 0 0 0-1.208 48.817 122.193 122.193 0 0 0 7.531 26.626c.724 1.761 3.627.99 2.893-.798Z"
    />
    <path
      fill="#ccc"
      d="M658.32 276.607c-6.126-6.716-1.964-17.141-1.853-25.043a17.94 17.94 0 0 0-3.244-11.254c-2.157-2.863-5.44-4.99-9.142-4.757-8.097.51-11.939 9.613-13.131 16.48a37.924 37.924 0 0 0 24.075 42.296c1.804.692 2.585-2.207.797-2.892a34.972 34.972 0 0 1-22.335-35.798 28.034 28.034 0 0 1 2.934-10.889c1.484-2.753 3.794-5.544 7.04-6.122 3.22-.573 6.098 1.467 7.782 4.06 2.145 3.304 2.414 7.16 2.124 10.999-.633 8.374-3.558 18.035 2.831 25.04 1.304 1.43 3.421-.696 2.122-2.12ZM659.75 313.472c6.42-4.239 7.509-12.171 8.293-19.21.458-4.111.948-8.54 3.258-12.087 1.835-2.819 5.091-5.364 8.632-5.012 7.074.703 8.177 10.342 7.89 15.794a35.576 35.576 0 0 1-8.71 21.36c-5.3 6.082-11.97 10.631-20.126 11.613-1.893.228-1.916 3.23 0 3 16.286-1.96 29.246-16.571 31.49-32.5.978-6.947.54-16.302-5.907-20.679a10.036 10.036 0 0 0-10.418-.246 16.222 16.222 0 0 0-7.394 9.368c-1.62 4.876-1.513 10.075-2.482 15.078-.8 4.131-2.375 8.51-6.04 10.93-1.604 1.06-.103 3.659 1.514 2.591Z"
    />
    <path
      fill="#3f3d56"
      d="M686.47 230.635a42 42 0 1 1 42-42 42.048 42.048 0 0 1-42 42Zm0-82a40 40 0 1 0 40 40 40.045 40.045 0 0 0-40-40Z"
    />
    <path
      fill="#3f3d56"
      d="M808 418.93a1.003 1.003 0 0 1-1 1H1a1 1 0 0 1 0-2h806a1.003 1.003 0 0 1 1 1Z"
    />
    <path
      fill="#3f3d56"
      d="M191.487 419.93h-62a7.008 7.008 0 0 1-7-6.933l-8-62.067a7.008 7.008 0 0 1 7-7h78a7.008 7.008 0 0 1 7 7l-.008.128-7.992 61.94a7.008 7.008 0 0 1-7 6.932Zm-75-69.062 8 62.062a5.006 5.006 0 0 0 5 5h62a5.006 5.006 0 0 0 5-5l.008-.129 7.991-61.933a5.006 5.006 0 0 0-5-4.938h-78a5.006 5.006 0 0 0-4.999 4.938Z"
    />
    <ellipse cx={160.487} cy={355.93} fill="#f2f2f2" rx={42} ry={7} />
    <path
      fill="#ccc"
      d="M159.24 352.557a125.984 125.984 0 0 0 9.264-48.087 130.9 130.9 0 0 0-9.187-47.955 123.808 123.808 0 0 0-13.003-24.416c-1.088-1.58-3.689-.081-2.59 1.514a123.716 123.716 0 0 1 18.906 44.104 127.75 127.75 0 0 1 1.15 48.006 122.12 122.12 0 0 1-7.432 26.036c-.733 1.785 2.168 2.562 2.893.798Z"
    />
    <path
      fill="#ccc"
      d="M166.775 281.728c5.416-5.938 4.175-14.22 3.217-21.495-.529-4.013-.998-8.349.519-12.21 1.143-2.911 3.515-5.794 6.731-6.39 3.243-.6 6.123 1.467 7.958 3.958a23.744 23.744 0 0 1 3.893 10.027 34.975 34.975 0 0 1-21.942 38.818c-1.784.685-1.01 3.586.798 2.893a38.862 38.862 0 0 0 18.33-14.545 37.982 37.982 0 0 0 6.17-24.575c-.68-7.153-3.404-16.543-11.002-19.172-7.785-2.693-13.871 5.128-14.749 12.038-.682 5.375.748 10.645 1.12 15.98.302 4.35-.09 9.182-3.164 12.552-1.299 1.424.817 3.551 2.121 2.12ZM164.738 313.882c-7.25-4.787-6.187-15.361-7.682-22.788-.829-4.114-2.518-8.109-5.781-10.878-2.886-2.449-6.798-3.868-10.527-2.607-7.397 2.502-8.979 11.786-8.587 18.553a38.912 38.912 0 0 0 9.579 23.275c5.87 6.74 13.276 11.413 22.247 12.493 1.912.23 1.896-2.772 0-3-14.873-1.79-26.558-15.718-28.568-30.098a27.428 27.428 0 0 1 .38-10.753c.767-2.877 2.268-5.93 5.07-7.295 3.218-1.566 6.762-.04 9.12 2.325 2.877 2.883 3.924 6.844 4.519 10.762 1.216 8.017 1.132 17.593 8.716 22.601 1.615 1.067 3.12-1.53 1.514-2.59Z"
    />
    <path
      fill="#3f3d56"
      d="M141.258 232.883a42 42 0 1 1 42-42 42.048 42.048 0 0 1-42 42Zm0-82a40 40 0 1 0 40 40 40.045 40.045 0 0 0-40-40Z"
    />
    <path
      fill="#6c63ff" //aqui
      d="M152.698 170.105a10.59 10.59 0 0 1 10.558 10.559v21.115a10.59 10.59 0 0 1-10.558 10.558h-21.115a10.59 10.59 0 0 1-10.558-10.558v-21.115a10.59 10.59 0 0 1 10.558-10.558h21.115m0-4.223h-21.115a14.824 14.824 0 0 0-14.781 14.781v21.115a14.824 14.824 0 0 0 14.781 14.78h21.115a14.824 14.824 0 0 0 14.781-14.78v-21.115a14.824 14.824 0 0 0-14.781-14.781Z"
    />
    <path
      fill="#6c63ff"
      d="M155.865 180.664a3.16 3.16 0 1 1 .014 0h-.014ZM142.14 182.775a8.447 8.447 0 1 1-8.446 8.446 8.447 8.447 0 0 1 8.446-8.446m0-4.223a12.67 12.67 0 1 0 12.67 12.67 12.67 12.67 0 0 0-12.67-12.67Z"
    />
    <path
      fill="#ffb8b8"
      d="m381.243 406.999 11.72-3.597-8.296-46.918-17.298 5.308 13.874 45.207z"
    />
    <path
      fill="#2f2e41"
      d="m418.906 407.349-36.835 11.304-4.367-14.232 22.603-6.937a14.887 14.887 0 0 1 18.6 9.865Z"
    />
    <path
      fill="#ffb8b8"
      d="m281.245 407.269 12.259-.001 5.833-47.288-18.095.001.003 47.288z"
    />
    <path
      fill="#2f2e41"
      d="m317.149 418.651-38.531.002v-14.887l23.643-.001a14.887 14.887 0 0 1 14.888 14.886Z"
    />
    <path
      fill="#ffb8b8"
      d="M280.365 270.191a9.162 9.162 0 0 1 .318-14.045l9.505-119.695 19.331 4.83-15.477 116.758a9.212 9.212 0 0 1-13.677 12.152Z"
    />
    <circle cx={319.076} cy={65.466} r={24.561} fill="#ffb8b8" />
    <path
      fill="#ccc"
      d="M297.72 132.77c-5.543-7.609-5.713-18.087.256-25.365 3.896-4.749 10.413-8.549 21.293-8.549 29 0 40 23 40 23s12 22 5 42-7 22-7 22l-46-4s7.76-19.832-13.55-49.086Z"
    />
    <path
      fill="#ccc"
      d="m283.534 152.678 9.91-32.42a15.502 15.502 0 0 1 21.93-9.246 15.53 15.53 0 0 1 7.088 20.01l-12.598 28.691Z"
    />
    <path
      fill="#2f2e41"
      d="m356.269 180.856 39 202-21 6-51-133-23 136-22 3s-9.65-179.945 33-213Z"
    />
    <path
      fill="#ffb8b8"
      d="M361.365 195.515a15.822 15.822 0 0 1-14.925-10.547l-11.262-31.334 17.484-8.31 9.597 29.297 48.893-2.37a10.6 10.6 0 0 1 .868-1.187 11 11 0 0 1 15.74-1.033 11.132 11.132 0 0 1 1.204 15.061 11 11 0 0 1-15.706 1.48 10.212 10.212 0 0 1-.782-.746l-47.992 9.385a16.25 16.25 0 0 1-3.119.304Z"
    />
    <path
      fill="#ccc"
      d="M328.872 124.76a15.5 15.5 0 0 1 16.362-17.282 15.53 15.53 0 0 1 14.535 15.472v31.335l-26.936 4.144Z"
    />
    <path
      fill="#2f2e41"
      d="M324.477 90.25c-3.686-.016-6.53-3.897-6.364-7.58s2.638-6.923 5.618-9.093 6.475-3.492 9.798-5.087 6.618-3.586 8.67-6.648a14.716 14.716 0 0 0 1.023-13.505 21.669 21.669 0 0 0-9.333-10.224 28.378 28.378 0 0 0-37.608 8.25l-4.208 11.458c-4.287 5.156-4.968 12.814-2.332 18.98s8.248 10.806 14.581 13.011a35.794 35.794 0 0 0 19.841.595"
    />
    <path
      fill="#2f2e41"
      d="M302.03 52.09c-1.808-2.9-4.944-4.744-8.185-5.826a25.724 25.724 0 0 0-33.832 23.003c-.344 6.314 1.633 12.49 2.795 18.705s1.393 13.082-2.034 18.396c-2.611 4.048-6.964 6.597-11.261 8.773-3.582 1.813-7.455 3.516-11.453 3.154s-8.017-3.528-7.91-7.54a32.054 32.054 0 0 0-2.682 9.08c-.282 3.155.496 6.56 2.776 8.761 3.313 3.199 8.562 2.965 13.115 2.275 9.857-1.494 19.839-4.296 27.626-10.52s12.955-16.475 10.904-26.232c-.857-4.075-2.87-7.796-4.497-11.63s-2.897-8.027-2.15-12.124a14.735 14.735 0 0 1 7.493-9.981 19.601 19.601 0 0 1 12.523-1.9c2.799.46 6.294 1.173 7.954-1.127a4.508 4.508 0 0 0-.094-4.808 13.248 13.248 0 0 0-3.592-3.56"
    />
    <circle cx={541.388} cy={6.467} r={6.467} fill="#f2f2f2" />
    <circle cx={541.388} cy={32.24} r={6.467} fill="#f2f2f2" />
    <circle cx={541.388} cy={58.013} r={6.467} fill="#f2f2f2" />
    <circle cx={541.388} cy={83.787} r={6.467} fill="#f2f2f2" />
    <circle cx={568.388} cy={6.467} r={6.467} fill="#f2f2f2" />
    <circle cx={568.388} cy={32.24} r={6.467} fill="#f2f2f2" />
    <circle cx={568.388} cy={58.013} r={6.467} fill="#f2f2f2" />
    <circle cx={568.388} cy={83.787} r={6.467} fill="#f2f2f2" />
    <circle cx={595.388} cy={6.467} r={6.467} fill="#f2f2f2" />
    <circle cx={595.388} cy={32.24} r={6.467} fill="#f2f2f2" />
    <circle cx={595.388} cy={58.013} r={6.467} fill="#f2f2f2" />
    <circle cx={595.388} cy={83.787} r={6.467} fill="#f2f2f2" />
    <circle cx={622.388} cy={6.467} r={6.467} fill="#f2f2f2" />
    <circle cx={622.388} cy={32.24} r={6.467} fill="#f2f2f2" />
    <circle cx={622.388} cy={58.013} r={6.467} fill="#f2f2f2" />
    <circle cx={622.388} cy={83.787} r={6.467} fill="#f2f2f2" />
    <circle cx={649.388} cy={6.467} r={6.467} fill="#f2f2f2" />
    <circle cx={649.388} cy={32.24} r={6.467} fill="#f2f2f2" />
    <circle cx={649.388} cy={58.013} r={6.467} fill="#f2f2f2" />
    <circle cx={649.388} cy={83.787} r={6.467} fill="#f2f2f2" />
    <circle cx={676.388} cy={6.467} r={6.467} fill="#f2f2f2" />
    <circle cx={676.388} cy={32.24} r={6.467} fill="#f2f2f2" />
    <circle cx={676.388} cy={58.013} r={6.467} fill="#f2f2f2" />
    <circle cx={676.388} cy={83.787} r={6.467} fill="#f2f2f2" />
  </svg>
);
export default SvgInstagram;
