import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import LogoUff from "assets/logos/logoUff.png";

function displayDateTime() {
  // Cria uma nova data e hora com o horário de Brasília
  let date = new Date();
  let offset = -3; // horário de verão em Brasília é -3 horas em relação ao GMT
  let time =
    date.getTime() +
    date.getTimezoneOffset() * 60 * 1000 +
    offset * 60 * 60 * 1000;
  date = new Date(time);

  // Formata a data e hora atual
  let dayOfWeek = [
    "Domingo",
    "Segunda",
    "Terça",
    "Quarta",
    "Quinta",
    "Sexta",
    "Sábado",
  ][date.getUTCDay()];
  let day = date.getDate();
  let month = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ][date.getMonth()];
  let year = date.getFullYear();
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  // Exibe a data e hora atual em uma página web
  let clock =
    dayOfWeek +
    ", " +
    day +
    " de " +
    month +
    " de " +
    year +
    " - " +
    hours +
    ":" +
    minutes +
    ":" +
    seconds;
  document.getElementById("clock").innerHTML = clock;
}

const Image = ({ src }) => {
  return <img src={src} height="30" alt="logo" />;
};

const ButtonAppBar = ({ isLoggedIn }) => {
  useEffect(() => {
    // Atualiza a data e hora a cada 1 segundo
    setInterval(displayDateTime, 1000);
  }, []);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <Image src={LogoUff} />
            <Typography
              variant="h6"
              fontWeight="bold"
              component="div"
              sx={{ ml: 1, flexGrow: 1 }}
            >
              Quiosque Campus Aterrado
            </Typography>
            <Box
              id="clock"
              sx={{ ml: 2, display: { xs: "none", md: "block" } }}
            ></Box>
            <Box sx={{ alignItems: "right", ml: 10 }}></Box>
          </Toolbar>
        </AppBar>
      </Box>
      <Outlet />
    </>
  );
};

export default ButtonAppBar;
