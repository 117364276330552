import * as React from "react";
const SvgSiscoordSub = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10em"
    height="10em"
    viewBox="0 0 596.912 505.575"
    {...props}
  >
    <path
      fill="#e6e6e6"
      d="M575.427 413.696H21.485C9.638 413.696 0 404.058 0 392.21V325.54h596.912v66.67c0 11.848-9.638 21.486-21.485 21.486Z"
    />
    <path fill="#e6e6e6" d="M228.887 402.544h141.262v100.901H228.887z" />
    <path fill="#e6e6e6" d="M220.39 490.7h158.256v12.745H220.39z" />
    <path
      fill="#3f3d56"
      d="M596.381 353.686H0V14.744C0 6.614 6.614 0 14.744 0h566.893c8.13 0 14.744 6.614 14.744 14.744v338.942Z"
    />
    <path fill="#fff" d="M13.808 15.401h568.234v319.698H13.808z" />
    <path
      fill="#ffb6b6"
      d="M123.853 205.95c4.35-3.594 9.729-4.263 12.015-1.495 2.287 2.768.614 7.924-3.737 11.519a14.12 14.12 0 0 1-5.958 2.995l-18.62 14.993-6.85-8.887 19.084-13.838a14.12 14.12 0 0 1 4.066-5.288Z"
    />
    <path
      fill="currentColor"
      d="M16.233 239.78c-.01-.664-.004-6.448 4.278-10.104 4.92-4.2 11.86-2.5 14.295-1.903 5.623 1.377 6.085 3.79 11.366 6.268 9.88 4.638-2.5 2.264 3.38.777 2.035-.515 31.312-2.545 38.6-7.03 14.173-8.724 23.83-14.014 23.83-14.014l6.313 12.626s-9.129 12.694-21.977 22.097c-13.179 9.646-19.769 14.47-29.783 16.333-.863.16-22.072 3.782-39.155-8.981-3.897-2.911-11.036-8.245-11.147-16.07Z"
    />
    <circle cx={35.816} cy={185.675} r={21.993} fill="#ffb6b6" />
    <path
      fill="#ffb6b6"
      d="m208.055 333.43-22.65-23.184-11.691 12.196 16.874 22.23 16.738 1.914.729-13.156z"
    />
    <path
      fill="#2f2e41"
      d="M78.726 289.954c1.324.11 5.534.954 9.558-1.26 2.746-1.511 4.155-3.758 4.63-4.472 5.902-8.863 28.553-22.684 48.881-23.535 4.604-.193 7.634.34 10.388 1.776 3.906 2.036 5.465 4.952 10.42 11.783 2.711 3.737 5.71 7.257 8.454 10.97 16.062 21.736 22.151 23.443 21.53 30.411-.691 7.732-9.03 15.067-14.572 14.22-2.27-.346-3.149-1.925-6.181-5.12-8.891-9.367-12.57-7.637-18.9-14.812-7.39-8.377-6.423-15.323-10.279-15.591-3.575-.249-5.095 5.677-12.729 13.548-3.493 3.602-5.98 5.254-13.558 10.512-29.51 20.473-29.982 21.911-35.937 23.544-2.606.714-21.1 5.785-27.688-2.284-10.31-12.627 14.046-50.682 25.983-49.69ZM201.13 338.67c1.666-.749 3.614-1.623 4.24-3.635.424-1.36.18-3.039-.696-4.198-1.011-1.338-2.508-1.514-2.448-1.751.093-.36 3.632-.22 7.517 1.154.734.26 4.055 1.473 8.28 4.772 3.386 2.642 2.93 3.172 5.053 4.696 6.517 4.678 12.628.991 16.154 5.378 1.361 1.693 1.942 4.097 1.369 6.04-1.15 3.904-6.743 5.247-9.8 5.94-7.361 1.67-12.933.307-18.751-.673-19.787-3.334-30.904-.298-32.392-4.882-.282-.867.176-.793 1.702-7.16 1.505-6.284 1.456-8.01 2.738-8.513 1.93-.757 3.227 2.688 8.09 3.868 4.31 1.044 7.865-.552 8.944-1.037Z"
    />
    <path
      fill="#ffb6b6"
      d="m167.788 409.275-5.723-31.903-16.523 3.527 1.48 27.87 12.776 10.982 7.99-10.476z"
    />
    <path
      fill="#2f2e41"
      d="M85.188 300.678c1.034.835 4.044 3.896 8.617 4.324 3.12.292 5.547-.776 6.342-1.1 9.86-4.02 36.364-2.737 53.662 7.974 3.918 2.426 6.125 4.569 7.598 7.303 2.089 3.878 1.741 7.166 2.006 15.602.145 4.614.65 9.211.835 13.824 1.085 27.005 5.165 31.836.737 37.253-4.913 6.01-15.931 7.397-20.042 3.584-1.683-1.562-1.524-3.361-2.24-7.707-2.096-12.744-6.112-13.378-7.32-22.87-1.412-11.081 3.29-16.286.25-18.673-2.82-2.214-7.404 1.836-18.141 4.063-4.913 1.019-7.9.99-17.122 1.084-35.915.369-37.112 1.295-42.957-.698-2.557-.872-20.707-7.062-21.628-17.438-1.44-16.237 40.082-34.05 49.403-26.525ZM159.116 409.722c1.799.316 3.901.686 5.55-.626 1.114-.888 1.855-2.414 1.78-3.865-.085-1.675-1.225-2.662-1.042-2.824.28-.246 3.129 1.858 5.573 5.176.46.627 2.528 3.496 4.172 8.598 1.317 4.088.642 4.27 1.543 6.724 2.765 7.53 9.893 7.911 10.347 13.521.175 2.165-.694 4.48-2.26 5.767-3.143 2.584-8.525.555-11.444-.588-7.029-2.752-10.874-7.009-15.138-11.087-14.5-13.87-25.404-17.6-24.061-22.229.254-.876.591-.558 5.429-4.969 4.774-4.354 5.702-5.81 7.046-5.506 2.023.457 1.16 4.036 4.522 7.743 2.98 3.284 6.818 3.96 7.983 4.165ZM38.815 189.88c.856-3.063-.22-4.18.877-5.554 0 0 .44-.554 4.384-2.046-1.163-6.75-.292-7.892-.292-7.892 1.004-1.316 3.015-1.634 4.384-1.462 2.896.364 3.409 2.982 5.262 2.923 2.56-.08 5.63-5.205 4.676-9.645-.777-3.623-4.129-6.313-4.969-5.846-.833.463-3.832-1.578-4.969-1.17-.776.28-4.432-.092-5.261.293-.905.42-3.498 3.035-3.746 2.705-1.722-2.29-1.685-3.089-2.684-3.582-1.771-.874-3.696.742-7.015 2.338-6.83 3.285-8.83 1.326-11.984 4.092-1.594 1.398-2.449 4.069-4.093 9.354-2.299 7.39-3.448 11.086-2.338 14.614 1.132 3.599 2.8 2.816 5.261 7.892 2.74 5.651 1.796 8.936 4.385 10.23 2 1 5.216.364 6.43-1.461 2.079-3.125-3.294-7.352-1.461-12.276 1.125-3.023 4.594-5.315 6.722-4.677 2.407.722 2.584 5.036 4.093 4.969 1.292-.057 2.189-3.266 2.338-3.8Z"
    />
    <path
      fill="currentColor"
      d="M22.154 210.924c4.5-5.046 22.832.177 24.553 8.184.707 3.294-1.905 4.685-1.17 8.477 1.077 5.547 7.17 5.169 11.692 10.815 6.421 8.016-.194 15.852 7.015 29.23 1.54 2.858 2.84 5.267 5.554 7.599 6.23 5.35 12.027 3.515 15.2 8.476 2.371 3.71 1.397 8.282 1.168 9.354-.936 4.392-3.473 5.462-5.261 9.06-2.975 5.988.393 10.384.585 17.538.239 8.951-4.518 21.359-14.03 25.43-10.688 4.573-23.577-3.002-29.814-10.523-3.722-4.488-4.803-8.628-6.43-13.738-7.278-22.837-11.389-20.771-16.954-38.875-6.361-20.695-7.612-44.94 0-52.612 1.01-1.017 3.786-3.368 5.554-7.6 2.379-5.695.324-8.556 2.338-10.815Z"
    />
    <path
      fill="#ffb6b6"
      d="M88.653 328.391c3.49 4.433 4.03 9.827 1.209 12.047-2.822 2.22-7.937.426-11.427-4.01a14.12 14.12 0 0 1-2.852-6.027l-14.547-18.97 7.516-5.734L83.464 324.2c2.04.963 3.819 2.4 5.19 4.191Z"
    />
    <path
      fill="currentColor"
      d="M35.377 230.155c.648-.144 6.311-1.318 10.764 2.128 5.115 3.958 4.867 11.1 4.78 13.605-.2 5.786-2.468 6.73-3.817 12.406-2.524 10.619 2.282 20.487 4.938 25.94.919 1.886 3.872 7.661 9.75 13.88 11.432 12.095 21.632 18.46 21.632 18.46l-10.958 9.74s-17.455-5.32-29.282-15.979c-12.132-10.934-30.79-49.619-34.657-59.042-.333-.812 4.385 12.382 13.393-6.946 2.055-4.409 5.82-12.486 13.457-14.192Z"
    />
    <path
      fill="#3f3d56"
      d="m315.838 221.081-13.084-6.197c5.366 7.489 9.99 19.185 12.466 28.492 4.191-8.67 10.948-19.278 17.636-25.614l-13.828 3.557c8.521-41.76 40.57-71.74 77.27-71.74l.52-1.507c-38.335 0-72.28 29.563-80.98 73.01Z"
    />
    <path
      fill="currentColor"
      d="M376.057 284.71h-82.668c-3.507 0-6.36-2.853-6.36-6.359s2.853-6.359 6.36-6.359h82.668c3.506 0 6.359 2.853 6.359 6.359s-2.853 6.36-6.36 6.36Z"
    />
    <path
      fill="#e6e6e6"
      d="M486.057 284.71h-82.668c-3.507 0-6.36-2.853-6.36-6.359s2.853-6.359 6.36-6.359h82.668c3.506 0 6.359 2.853 6.359 6.359s-2.853 6.36-6.36 6.36Z"
    />
    <g fill="#3f3d56">
      <path d="M437.118 111.717h-80.357c-2.026 0-3.674-1.648-3.674-3.674s1.648-3.673 3.674-3.673h80.357c2.026 0 3.674 1.647 3.674 3.673s-1.648 3.674-3.674 3.674ZM480.239 150.915H313.64c-2.026 0-3.675-1.648-3.675-3.674s1.649-3.673 3.675-3.673h166.598a3.677 3.677 0 0 1 3.673 3.673 3.678 3.678 0 0 1-3.673 3.674Z" />
    </g>
    <path
      fill="#3f3d56"
      d="M36.121 504.385c0 .66.53 1.19 1.19 1.19h522.29c.66 0 1.19-.53 1.19-1.19 0-.66-.53-1.19-1.19-1.19H37.311c-.66 0-1.19.53-1.19 1.19ZM164.369 222.297c-.142 0-.283-.006-.425-.015-8.37-.537-37.005-4.589-54.1-34.147-1.41-2.434-1.132-5.528.69-7.699l31.66-37.722c1.294-1.542 3.294-2.431 5.32-2.407 1.4.034 4.715.387 5.359 2.943 3.803 15.096 19.863 32.847 39.177 35.2 1.958.237 3.53 1.984 4.204 4.671a6.66 6.66 0 0 1-1.335 5.916l-25.362 30.798a6.705 6.705 0 0 1-5.188 2.461Zm-16.977-80.472a5.31 5.31 0 0 0-4.035 1.865l-31.66 37.722c-1.413 1.683-1.63 4.079-.54 5.962 16.719 28.907 44.706 32.868 52.885 33.393 1.67.112 3.274-.6 4.343-1.897l25.362-30.798a5.158 5.158 0 0 0 1.034-4.581c-.398-1.586-1.373-3.347-2.915-3.535-19.953-2.43-36.54-20.753-40.466-36.336-.318-1.261-2.232-1.754-3.918-1.794l-.09-.001Z"
    />
    <path
      fill="#e6e6e6"
      d="m129.03 189.376 22.825-23.273c1.56-1.591-.884-4.04-2.446-2.447l-22.826 23.274c-1.56 1.59.884 4.039 2.447 2.446ZM142.806 195.933a90.179 90.179 0 0 0 16.257-19.358c1.183-1.893-1.81-3.632-2.988-1.746a86.874 86.874 0 0 1-15.715 18.657c-1.662 1.49.792 3.93 2.446 2.447ZM155.223 203.784a95.517 95.517 0 0 0 14.461-21.093c.997-1.984-1.986-3.738-2.987-1.746a92.14 92.14 0 0 1-13.92 20.393c-1.49 1.649.95 4.103 2.446 2.446Z"
    />
    <ellipse
      cx={145.443}
      cy={139.357}
      fill="currentColor"
      rx={23.013}
      ry={22.528}
    />
    <path
      fill="#fff"
      d="M157.417 150.043 146.8 123.938c-.49-1.202-2.02-1.244-2.497 0-3.497 9.123-5.783 18.643-6.881 28.349-.187 1.653 2.404 1.64 2.59 0a110.67 110.67 0 0 1 1.887-11.298 27.227 27.227 0 0 0 8.671-.953l4.35 10.696c.62 1.523 3.126.857 2.497-.689Zm-14.93-11.614c.871-3.57 1.917-7.098 3.13-10.57l3.97 9.759a25.01 25.01 0 0 1-7.1.811Z"
    />
  </svg>
);
export default SvgSiscoordSub;
