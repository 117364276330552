import * as React from "react";
const SvgFaq = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10em"
    height="10em"
    data-name="Layer 1"
    viewBox="0 0 844.675 595.262"
    {...props}
  >
    <circle cx={431.393} cy={548} r={46} fill="currentColor" />
    <path
      fill="#f2f2f2"
      d="M706.2 591.416c25.734-7.729 53.093-15.788 73.503-34.161 18.237-16.418 30.55-41.49 22.994-66.112-7.54-24.572-30.124-40.956-53.441-49.105-13.225-4.622-27.061-7.186-40.892-9.2-15.035-2.19-30.136-3.984-45.23-5.71q-91.677-10.486-184.044-12.812c-30.384-.765-60.763-.746-91.152-.305-27.14.394-55.722.384-80.9-11.15-19.578-8.97-37.347-25.29-42.803-46.734-6.297-24.755 5.318-49.964 21.98-67.892 8.783-9.45 19.048-17.404 29.637-24.718 11.487-7.934 23.375-15.306 35.52-22.188a494.634 494.634 0 0 1 74.767-34.468c12.746-4.635 25.68-8.633 38.728-12.322 11.017-3.115 22.068-6.234 32.716-10.477 20.583-8.204 40.16-22.1 45.394-44.882 4.96-21.591-3.403-45.03-18.065-61.07-16.963-18.557-42.54-26.692-67.06-28.008-27.528-1.478-54.422 5.412-80.297 14.155-27.596 9.326-54.598 20.05-82.778 27.604a556.958 556.958 0 0 1-85.196 15.836c-14.082 1.5-28.59 3.193-42.756 2.045-11.872-.962-23.684-4.454-32.434-12.88-7.503-7.224-11.972-17.154-10.435-27.632.279-1.903 3.17-1.094 2.892.798-1.87 12.755 6.8 24.508 17.485 30.529 12.348 6.958 27.374 6.968 41.121 6.172a537.825 537.825 0 0 0 88.515-12.796c28.592-6.53 56.164-15.866 83.704-25.839 26.156-9.471 52.897-18.716 80.84-20.767 24.246-1.78 49.752 1.752 70.874 14.423 18.564 11.136 32.215 29.707 36.565 51.018 4.25 20.825-1.637 41.785-17.4 56.317-16.322 15.047-38.701 21.48-59.557 27.404-26.452 7.514-52.337 16.298-77.392 27.703a485.824 485.824 0 0 0-72.8 40.928c-22.247 15.202-44.201 34.33-51.237 61.451-3.277 12.633-2.672 26.032 2.812 37.965 4.876 10.61 12.906 19.535 22.261 26.418 22.321 16.425 50.453 19.797 77.415 20.133 30.281.376 60.563-.646 90.855-.148q92.598 1.52 184.818 11.272 23.108 2.446 46.158 5.407c13.821 1.776 27.69 3.54 41.278 6.695 24.163 5.608 47.674 16.392 62.692 36.878a61.32 61.32 0 0 1 11.947 30.446c1.052 11.524-1.77 23.07-6.98 33.32-11.532 22.691-33.134 37.124-56.073 46.605-12.287 5.08-25.032 8.926-37.752 12.747-1.853.556-2.645-2.338-.797-2.893Z"
    />
    <circle cx={125.893} cy={69.5} r={24} fill="#f2f2f2" />
    <circle cx={292.393} cy={115} r={24} fill="#f2f2f2" />
    <circle cx={433.393} cy={24} r={24} fill="#f2f2f2" />
    <circle cx={521.393} cy={126} r={24} fill="#f2f2f2" />
    <circle cx={402.393} cy={244} r={24} fill="#f2f2f2" />
    <circle cx={251.393} cy={301} r={24} fill="#f2f2f2" />
    <circle cx={411.393} cy={390} r={24} fill="#f2f2f2" />
    <circle cx={583.393} cy={440} r={24} fill="#f2f2f2" />
    <circle cx={784.393} cy={429} r={24} fill="#f2f2f2" />
    <path
      fill="currentColor"
      d="M426.465 68.003c-71.892.508-130.756 58.93-131.777 130.817-.01.663-.014 5.33-.013 11.434A33.748 33.748 0 0 0 328.419 244a33.769 33.769 0 0 0 33.76-33.798l-.005-7.202a64 64 0 1 1 98.52 53.88l.012.014s-48.028 30.92-62.67 73.335l.012.003A94.004 94.004 0 0 0 394.174 357c0 3.725.22 36.321.643 62.778A34.786 34.786 0 0 0 429.607 454a34.796 34.796 0 0 0 34.794-35.01c-.147-24.23-.227-52.442-.227-54.49 0-26.045 25.126-51.995 45.764-68.917 23.766-19.487 40.868-46.043 47.737-76a86.762 86.762 0 0 0 2.5-18.833 132.75 132.75 0 0 0-133.71-132.747Z"
    />
    <path
      fill="#3f3d56"
      d="M843.485 595.262H1.19a1.19 1.19 0 0 1 0-2.382h842.294a1.19 1.19 0 0 1 0 2.382Z"
    />
    <circle cx={628.449} cy={242.996} r={30} fill="#2f2e41" />
    <path
      fill="#a0616a"
      d="M573.012 582.129h-12.259l-5.833-47.288h18.095l-.003 47.288z"
    />
    <path
      fill="#2f2e41"
      d="M551.996 578.626h23.643v14.886h-38.53a14.887 14.887 0 0 1 14.887-14.886Z"
    />
    <path
      fill="#a0616a"
      d="M668.012 582.129h-12.259l-5.833-47.288h18.095l-.003 47.288z"
    />
    <path
      fill="#2f2e41"
      d="M646.996 578.626h23.643v14.886h-38.53a14.887 14.887 0 0 1 14.887-14.886Z"
    />
    <circle cx={623.89} cy={248.61} r={24.561} fill="#a0616a" />
    <path
      fill="#2f2e41"
      d="m638.529 352.406 10.99-25.25a31.383 31.383 0 0 0-6.95-35.6 31.873 31.873 0 0 0-3.07-2.67 30.935 30.935 0 0 0-18.99-6.57 32.179 32.179 0 0 0-13.4 2.98c-.36.16-.71.33-1.07.5-.69.33-1.37.69-2.03 1.06a31.678 31.678 0 0 0-15.7 23.88l-4.85 40.64c-1.22 3.19-44.74 118.39-29.52 206.34a4.467 4.467 0 0 0 3.82 3.67l15.43 2.1a4.497 4.497 0 0 0 5.01-3.53l25.89-123.41a3.503 3.503 0 0 1 6.8-.23l36.58 129.78a4.471 4.471 0 0 0 4.31 3.28 5.122 5.122 0 0 0 .87-.08l18.85-3.63a4.471 4.471 0 0 0 3.63-4.81c-2.77-30.89-17.49-185.62-36.6-208.45Z"
    />
    <path
      fill="#a0616a"
      d="M528.44 269.05a10.056 10.056 0 0 0 10.594 11.203l13.729 32.993 10.385-15.395-14.63-28.97a10.11 10.11 0 0 0-20.079.169ZM622.528 385.626a10.056 10.056 0 0 0 8.426-12.913l28.886-21.038-17.39-6.513-24.764 20.977a10.11 10.11 0 0 0 4.842 19.487Z"
    />
    <path
      fill="#2f2e41"
      d="M575.44 335.241a17.057 17.057 0 0 1-3.299-.325 16.305 16.305 0 0 1-11.947-9.616l-19.235-23.454a4.5 4.5 0 0 1 1.111-6.68l13.685-8.47a4.5 4.5 0 0 1 6.215 1.49l13.556 22.333 25.962-19.186a9.721 9.721 0 0 1 11.31 15.561l-25.912 23.637a16.253 16.253 0 0 1-11.446 4.71ZM645.59 370.513h-.106a4.508 4.508 0 0 1-3.31-1.57l-12.167-14.194a4.5 4.5 0 0 1 .92-6.673l22.782-15.188-20.639-24.812a9.721 9.721 0 0 1 14.888-12.183l25.083 24.513a16.525 16.525 0 0 1-3.675 26.94L648.865 369.1a4.507 4.507 0 0 1-3.275 1.413Z"
    />
    <path
      fill="#e6e6e6"
      d="M617.645 318.214a4.632 4.632 0 0 1-.584-.037 4.461 4.461 0 0 1-3.71-3.069l-9.143-28.029a3.083 3.083 0 0 1 1.594-3.725l.297-.14c.269-.128.535-.254.807-.375a32.574 32.574 0 0 1 13.603-3.023 31.327 31.327 0 0 1 17.161 5.157 3.13 3.13 0 0 1 .902 4.294l-17.15 26.868a4.455 4.455 0 0 1-3.777 2.08Z"
    />
    <circle cx={652.101} cy={219.786} r={9.817} fill="#2f2e41" />
    <path
      fill="#2f2e41"
      d="M618.449 208.996a26 26 0 0 0-26 26v11h13.53l6.47-11 1.941 11h41.059l-11-11a26 26 0 0 0-26-26ZM657.147 213.062a15.15 15.15 0 0 1 16.48-10.396c6.256 1.046 11.203 6.075 14.15 11.691s4.308 11.903 6.29 17.928 4.79 12.084 9.792 15.984 12.677 4.959 17.59.946a20.118 20.118 0 0 1-37.477 7.182c-2.592-4.612-3.261-10.017-4.03-15.251s-1.768-10.65-4.862-14.94-8.888-7.094-13.804-5.14Z"
    />
    <path
      fill="#3f3d56"
      d="M337.947 228.038a33.748 33.748 0 0 1-33.745-33.746c0-6.103.004-10.77.014-11.434a131.507 131.507 0 0 1 15.358-59.909 131.803 131.803 0 0 0-25.358 75.91c-.01.663-.014 5.33-.014 11.433a33.748 33.748 0 0 0 33.745 33.746 33.773 33.773 0 0 0 32.487-24.59 33.622 33.622 0 0 1-22.487 8.59ZM428.753 139.11a64.004 64.004 0 0 1 55.659 89.412 63.997 63.997 0 1 0-107.426-66.985 63.87 63.87 0 0 1 51.767-22.428ZM439.135 438.038a34.787 34.787 0 0 1-34.79-34.222c-.424-26.456-.643-59.052-.643-62.778a94.004 94.004 0 0 1 3.873-26.768l-.012-.003a95.069 95.069 0 0 1 5.494-12.7 110.047 110.047 0 0 0-15.494 28.7l.012.003a94.004 94.004 0 0 0-3.873 26.768c0 3.726.22 36.322.643 62.778a34.787 34.787 0 0 0 34.79 34.222 34.803 34.803 0 0 0 33.401-25.048 34.66 34.66 0 0 1-23.401 9.048Z"
    />
    <path
      fill="#ffb8b8"
      d="m126.541 582.585 12.259-.001 5.833-47.288-18.095.001.003 47.288z"
    />
    <path
      fill="#2f2e41"
      d="m162.445 593.967-38.531.002v-14.887h23.643a14.887 14.887 0 0 1 14.888 14.885Z"
    />
    <path
      fill="#ffb8b8"
      d="m82.541 582.585 12.259-.001 5.833-47.288-18.095.001.003 47.288z"
    />
    <path
      fill="#2f2e41"
      d="m118.445 593.967-38.531.002v-14.887h23.643a14.887 14.887 0 0 1 14.888 14.885ZM93.254 568.041l-11.975-.63a4.673 4.673 0 0 1-4.418-4.966l14.312-158.466 65.911 17.785 6.35-1.732-19.858 140.285a4.696 4.696 0 0 1-4.358 3.944l-12.909.602a4.674 4.674 0 0 1-4.931-4.796l2.339-84.196a1.558 1.558 0 0 0-3.083-.36l-22.557 88.812a4.646 4.646 0 0 1-4.57 3.726c-.083 0-.168-.002-.253-.008Z"
    />
    <circle cx={128.742} cy={249.759} r={24.561} fill="#ffb8b8" />
    <path
      fill="#ccc"
      d="m87.85 321.918 2.7 58.267.976 21.199a4.642 4.642 0 0 0 3.075 4.175l63.336 22.943a4.477 4.477 0 0 0 1.6.28 4.644 4.644 0 0 0 4.663-4.787l-2.597-104.767a36.93 36.93 0 0 0-30.743-35.677c-.613-.094-1.236-.187-1.86-.27a36.25 36.25 0 0 0-29.165 9.44 37.236 37.236 0 0 0-11.985 29.197Z"
    />
    <path
      fill="#ffb8b8"
      d="M188.192 416.876a10.064 10.064 0 0 1-5.369-15.227l-21.478-28.56 18.535-1.147 17.554 27.297a10.111 10.111 0 0 1-9.242 17.637Z"
    />
    <path
      fill="#ccc"
      d="M173.091 396.481a4.644 4.644 0 0 1-2.541-2.518l-32.358-77.095a12.463 12.463 0 1 1 22.984-9.647l32.358 77.096a4.679 4.679 0 0 1-2.5 6.118l-14.366 6.029a4.642 4.642 0 0 1-3.577.017Z"
    />
    <path
      fill="#3f3d56"
      d="m120.845 393.762 31.08-59.51a4.878 4.878 0 0 1 6.575-2.063l45.111 23.56a4.878 4.878 0 0 1 2.064 6.575l-31.08 59.51a4.878 4.878 0 0 1-6.575 2.063l-45.111-23.56a4.878 4.878 0 0 1-2.064-6.575Z"
    />
    <path
      fill="#ffb8b8"
      d="M141.688 366.574a10.064 10.064 0 0 0-15.517-4.46l-29.778-19.755-.05 18.57 28.285 15.912a10.111 10.111 0 0 0 17.06-10.267Z"
    />
    <path
      fill="#ccc"
      d="m104.038 370.75-24.336-19.278a17.163 17.163 0 0 1-7.824-27.135l22.097-28.96a10.096 10.096 0 0 1 17.13 10.285l-17.484 28.6 25.694 12.186a4.674 4.674 0 0 1 1.948 6.72l-10.372 16.414a4.682 4.682 0 0 1-3.166 2.111 3.108 3.108 0 0 1-.078.012 4.696 4.696 0 0 1-3.609-.955Z"
    />
    <path
      fill="#2f2e41"
      d="M110.183 266.205a2.135 2.135 0 0 1 1.856-2.819 4.93 4.93 0 0 1 3.477 1.715 13.833 13.833 0 0 0 3.07 2.637c1.189.6 2.8.514 3.478-.628.636-1.072.2-2.508-.185-3.753a36.907 36.907 0 0 1-1.63-9.77c-.111-3.7.41-7.562 2.46-10.449 2.643-3.724 7.37-5.138 11.845-5.036s8.875 1.484 13.307 2.357c1.53.301 3.328.455 4.352-.73 1.088-1.261.688-3.302.225-5.004-1.2-4.418-2.475-8.985-5.265-12.552a18.898 18.898 0 0 0-12.06-6.79 28.938 28.938 0 0 0-13.463 1.528 36.096 36.096 0 0 0-17.683 12.318 29.236 29.236 0 0 0-5.578 21.6 26.667 26.667 0 0 0 9.886 16.855Z"
    />
    <path
      fill="#3f3d56"
      d="M421.258 582.78a45.994 45.994 0 0 1-17.07-71.489 45.997 45.997 0 1 0 62.569 66.464 45.97 45.97 0 0 1-45.499 5.025Z"
    />
  </svg>
);
export default SvgFaq;
