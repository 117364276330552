import React from "react";
import SubPageContainer from "components/templates/SubPageContainer";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import OptionCard from "components/atoms/OptionCard";
import GlpiAcesso from "draws/glpi/GlpiAcesso";
import GlpiConta from "draws/glpi/GlpiConta";

const href = (link) => {
  return (window.location.href = link);
};

const Fields = () => {
  const handleClickAcesso = () => {
    href("https://atendimento.vr.uff.br/");
  };

  const handleClickConta = () => {
    href("https://gdi.vr.uff.br/gdi-front/login.html");
  };

  return (
    <>
      <Box
        sx={{
          mt: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid container spacing={2} width={{ xs: 300, sm: 604 }}>
          <Grid item xs={12} sm={6}>
            <OptionCard
              title="GLPI"
              description="Acesso ao sistema"
              image={<GlpiAcesso />}
              action={handleClickAcesso}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <OptionCard
              title="GLPI"
              description="Criar ou alterar Conta"
              image={<GlpiConta />}
              action={handleClickConta}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const Home = () => {
  return (
    <SubPageContainer>
      <Fields />
    </SubPageContainer>
  );
};

export default Home;
