import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Slide from "@mui/material/Slide";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";

import styled from "@emotion/styled";

const SvgThemeColor = styled("div")(({ theme }) => ({
  svg: {
    color: theme.palette.primary.main,
  },
}));

const OptionCard = (props) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(true);
  }, []);

  return (
    <Slide direction="right" timeout={1500} in={checked}>
      <Card sx={{ maxWidth: 300 }}>
        <CardActionArea onClick={props.action}>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <SvgThemeColor>{props.image}</SvgThemeColor>
            </Box>
            <Typography gutterBottom variant="h5" component="div">
              {props.title}
            </Typography>
            <Typography variant="h6" color="text.secondary">
              {props.description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Slide>
  );
};

OptionCard.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.node.isRequired,
  action: PropTypes.func,
};

export default OptionCard;
