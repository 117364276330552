import React from "react";
import SubPageContainer from "components/templates/SubPageContainer";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import OptionCard from "components/atoms/OptionCard";
import Android from "draws/wifi/Android";
import Apple from "draws/wifi/Apple";
import Windows from "draws/wifi/Windows";
import Linux from "draws/wifi/Linux";
import Visitantes from "draws/wifi/Visitantes";

import {
  linkWifiVisitantes,
  linkWifiAndroid,
  linkWifiApple,
  linkWifiLinux,
  linkWifiWindows,
} from "links/links";

const href = (link) => {
  return (window.location.href = link);
};

const Fields = () => {
  const handleClickVisitantes = () => {
    href(linkWifiVisitantes);
  };

  const handleClickAndroid = () => {
    href(linkWifiAndroid);
  };

  const handleClickApple = () => {
    href(linkWifiApple);
  };

  const handleClickLinux = () => {
    href(linkWifiLinux);
  };

  const handleClickWindows = () => {
    href(linkWifiWindows);
  };

  return (
    <>
      <Box
        sx={{
          mt: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid container spacing={2} width={{ xs: 300, sm: 604, md: 906 }}>
          <Grid
            sx={{
              display: "none",
            }}
            item
            xs={12}
            sm={6}
            md={4}
          >
            <OptionCard
              title="Visitantes na UFF"
              description="Rede visit"
              image={<Visitantes />}
              action={handleClickVisitantes}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <OptionCard
              title="Android"
              description="Rede Eduroam"
              image={<Android />}
              action={handleClickAndroid}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <OptionCard
              title="Apple"
              description="Rede Eduroam"
              image={<Apple />}
              action={handleClickApple}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <OptionCard
              title="Linux"
              description="Rede Eduroam"
              image={<Linux />}
              action={handleClickLinux}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <OptionCard
              title="Windows"
              description="Rede Eduroam"
              image={<Windows />}
              action={handleClickWindows}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const Home = () => {
  return (
    <SubPageContainer>
      <Fields />
    </SubPageContainer>
  );
};

export default Home;
