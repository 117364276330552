import * as React from "react";
const SvgSissala = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10em"
    height="10em"
    viewBox="0 0 851.034 557.347"
    {...props}
  >
    <path
      fill="#3f3d56"
      d="M785.204 269.129H95.109c-9.25 0-16.775-7.525-16.775-16.776V16.775C78.334 7.525 85.859 0 95.109 0h690.095c9.25 0 16.775 7.525 16.775 16.775v235.578c0 9.25-7.525 16.776-16.775 16.776ZM95.109 2c-8.147 0-14.775 6.628-14.775 14.775v235.578c0 8.147 6.628 14.776 14.775 14.776h690.095c8.147 0 14.775-6.629 14.775-14.776V16.775C799.98 8.628 793.351 2 785.204 2H95.109Z"
    />
    <path
      fill="#e6e6e6"
      d="M0 550.557h851.034v1.683H0zM0 464.557h851.034v1.683H0z"
    />
    <path
      fill="#ffb6b6"
      d="M387.84 277.792c.611 7.68 5.07 13.59 9.957 13.2 4.887-.39 8.353-6.93 7.74-14.613a19.28 19.28 0 0 0-2.727-8.688l-3-32.507-15.225 1.733 4.574 31.862a19.282 19.282 0 0 0-1.319 9.013Z"
    />
    <path
      fill="#e6e6e6"
      d="M369.653 126.133s14.231-7.534 21.448 3.725c7.216 11.258 12.197 133.02 12.197 133.02l-20.002 2.136-3.791-85.802-9.852-53.079Z"
    />
    <path
      fill="#ffb6b6"
      d="M340.114 458.204H351.7l5.512-44.695h-17.101l.002 44.695z"
    />
    <path
      fill="#2f2e41"
      d="M379.814 457.941c.36.608.55 2.57.55 3.276a3.933 3.933 0 0 1-3.932 3.933h-35.885a2.683 2.683 0 0 1-2.683-2.683v-1.494s-1.775-4.49 1.88-10.025c0 0 4.542 4.334 11.33-2.454l2.001-3.626 14.49 10.596 8.03.989c1.757.216 3.315-.034 4.218 1.488Z"
    />
    <path
      fill="#ffb6b6"
      d="m407.168 456.286 11.227-2.87-5.721-44.667-16.57 4.234 11.064 43.303z"
    />
    <path
      fill="#2f2e41"
      d="M445.568 446.205c.5.5 1.17 2.353 1.345 3.038a3.933 3.933 0 0 1-2.837 4.783l-34.769 8.882a2.683 2.683 0 0 1-3.263-1.935l-.37-1.448s-2.831-3.911-.66-10.178c0 0 5.474 3.074 10.37-5.182l1.042-4.008 16.66 6.68 8.026-1.03c1.756-.225 3.203-.853 4.456.398Z"
    />
    <path
      fill="#ffb6b6"
      d="m330.527 116.525-.549-3.987 6.53-15.948 17.941-1.993 3.874 22.831-27.796-.903z"
    />
    <path
      d="m330.527 116.525-.549-3.987 6.53-15.948 17.941-1.993 3.874 22.831-27.796-.903z"
      opacity={0.1}
    />
    <path
      fill="#2f2e41"
      d="m309.596 229.158-7.974 21.928 5.98 11.961 29.903 173.434h19.88l-3.932-153.499 45.85 153.5h19.676L403.157 332.82l-11.828-95.688-7.174-4.432-34.69-19.49-39.87 15.948z"
    />
    <path
      fill="#e6e6e6"
      d="m360.43 244.109-11.961 1.993-15.948-37.876h35.883l-7.974 35.883z"
    />
    <path
      fill="#2f2e41"
      d="m384.352 234.141-23.922 9.968-7.974-17.942-3.987 19.935-41.864-13.476 5.98-24.4-5.98-79.74 21.112-14.074 2.81-1.874 25.916 1.994 2.272 1.236 15.67 8.731 3.986 65.786 5.98 43.856z"
    />
    <path
      fill="#e6e6e6"
      d="m358.715 115.768-11.243 47.604-19.755-48.96 2.81-1.874 25.916 1.994 2.272 1.236z"
    />
    <path
      fill="#2f2e41"
      d="m339.498 112.538-3.987 6.867 3.987 2.289-1.993 41.802 7.974 10.84 7.974-16.021-5.98-36.621 1.993-9.156h-9.968z"
    />
    <circle cx={345.479} cy={89.613} r={18.938} fill="#ffb6b6" />
    <path
      fill="#2f2e41"
      d="m333.376 91.274 4.092 1.786 1.786-10.715s7.143-3.572 12.5-1.786 14.287 0 14.287 0 0-17.858-12.5-17.858-25.002 1.786-25.002 7.143-5.358 10.715-5.358 10.715-7.143 10.715 0 17.859c7.143 7.143 10.715 7.143 10.715 7.143l-.52-14.287Z"
    />
    <path
      fill="#ffb6b6"
      d="M296.629 277.232c-.208 7.702 3.597 14.051 8.499 14.183s9.041-6.005 9.248-13.71a19.28 19.28 0 0 0-1.79-8.928l.468-32.642-15.323.107 1.166 32.168a19.282 19.282 0 0 0-2.268 8.822Z"
    />
    <path
      fill="#e6e6e6"
      d="M315.586 124.462s-12.958-3.987-18.938 7.974C290.668 144.396 298.64 266 298.64 266h20.116l-5.339-85.72 2.168-55.818Z"
    />
    <path
      fill="#3f3d56"
      d="m812.657 351.008-48.321-14.792a8.753 8.753 0 0 0-3.305-.352L516.13 356.707c-3.492.297-4.37 5.007-1.22 6.541l7.845 3.822h-.209v182.952h7.392V370.57l24.586 11.977c1.88.917 3.98 1.29 6.062 1.08l2.616-.264v166.659h7.392V382.617l189.42-19.134v186.539h7.392V362.737l31.416-3.174v190.459h7.392V358.817l5.731-.58c4.024-.406 4.58-6.045.712-7.229Z"
    />
    <path
      fill="#fff"
      d="M587.226 355.982c4.62 0 72.996-8.316 72.996-8.316l10.164 13.86-71.148 13.86-12.012-19.404Z"
    />
    <path
      fill="#a0616a"
      d="m662.27 542.917-11.88-.022-5.572-45.83 17.532.03-.08 45.822z"
    />
    <path
      fill="#2f2e41"
      d="M621.57 542.577c-.371.623-.57 2.633-.571 3.357a4.032 4.032 0 0 0 4.025 4.04l36.789.062a2.75 2.75 0 0 0 2.755-2.746l.003-1.531s1.828-4.6-1.91-10.28c0 0-4.664 4.434-11.61-2.537l-2.046-3.72-14.873 10.837-8.235 1c-1.802.218-3.398-.04-4.327 1.518Z"
    />
    <path
      fill="#a0616a"
      d="m726.027 521.605-9.372 7.069-31.73-32.787 13.834-10.434 27.268 36.152z"
    />
    <path
      fill="#2f2e41"
      d="M693.757 545.615c.079.712 1.121 2.414 1.553 2.986a3.985 3.985 0 0 0 5.58.78l29.025-21.894a2.718 2.718 0 0 0 .533-3.807l-.912-1.209s-1.304-4.715-7.637-6.961c0 0-1.03 6.276-10.661 4.928l-3.831-1.712-5.254 17.411-5.892 5.7c-1.29 1.247-2.702 1.995-2.504 3.778Z"
    />
    <path
      fill="#a0616a"
      d="M701.2 284.74c0 10.955-8.88 19.835-19.835 19.835s-19.836-8.88-19.836-19.835 8.88-19.836 19.836-19.836 19.835 8.881 19.835 19.836Z"
    />
    <path
      fill="#2f2e41"
      d="M699.331 399.107s-73.032-4.134-77.165 4.134 22.348 121.833 22.348 121.833l23.626-4.952-11.525-79.676s60.943 16.14 69.21-3.152c8.268-19.291 5.888-26.475 5.888-26.475l-32.382-11.712Z"
    />
    <path
      fill="#2f2e41"
      d="M718.043 385.229s-71.524 15.332-73.324 24.4 53.782 111.58 53.782 111.58l21.475-11.025-32.193-73.79s63.042-.556 65.912-21.347c2.87-20.791-1.325-27.09-1.325-27.09l-34.327-2.728Z"
    />
    <path
      fill="currentColor"
      d="M726.202 307.472h-34.363l-23.512 38.583s-5.512 24.934 0 27.625c5.512 2.69 5.512 31.628 5.512 31.628l57.874 5.511s8.268-86.811 5.512-95.079c-2.756-8.268-11.023-8.268-11.023-8.268Z"
    />
    <path
      fill="#a0616a"
      d="m671.772 332.965-3.445 40.714-38.747-16.444-9.248-4.651a18.64 18.64 0 0 0-6.855-5.523c-6.697-3.264-13.808-2.455-15.884 1.805-2.077 4.26 1.667 10.36 8.363 13.622a18.64 18.64 0 0 0 8.576 1.996l27.058 15.368.032-.06.12.214 42.432 17.723 9.763-53.45-22.165-11.314Z"
    />
    <path
      fill="#2f2e41"
      d="M667.638 287.492s-20.67 2.756-12.401-15.158c0 0-1.261-9.71 4.881-10.367s14.41-11.68 14.41-11.68 18.955-9.646 28.769 0 38.751 66.142 38.751 66.142l-19.291 20.67s-11.598-30.49-25.09-26.958c-13.493 3.532-25.895 14.062-25.895 14.062s-13.693-6.852-7.535-12.825c6.157-5.973 3.401-23.886 3.401-23.886Z"
    />
    <path
      fill="currentColor"
      d="M695.197 317.807s-9.645-12.402-16.535-2.756c-6.89 9.646-12.402 22.047-12.402 22.047l27.56 9.646 1.377-28.937Z"
    />
    <path
      fill="#e6e6e6"
      d="m799.836 547.279-32.02-101.77h.079l-.129-.167-.003-.004-2.28-7.248v-.001a18.346 18.346 0 0 0 6.312-12.197l-14.637-80.499c1.008-10.57-7.096-19.81-17.713-20.19l-33.023-.082c-9.388-.333-17.52 6.45-18.887 15.745l-.73 4.934-10.118 68.828a18.206 18.206 0 0 0 .36 7.198l-7.28.952-2.209.296-8.731 1.136-.028.01-1.09.138h-1.183v29.568h.074v94.507a1.114 1.114 0 0 0 2.227 0v-94.507h12.954l12.927 94.396c.083.554.554.96 1.109.96.046 0 .101 0 .147-.009a1.115 1.115 0 0 0 .952-1.256l-12.88-94.09h62.157c6.172 0 11.89-1.96 16.547-5.313l24.645 99.535a1.113 1.113 0 0 0 2.157-.55L754.66 447.104c.331-.276.662-.57.975-.874l9.893-.582 32.186 102.3a1.114 1.114 0 1 0 2.123-.668Zm-36.468-107.61c.083-.046.157-.102.23-.148h.001l.238.755-.47-.607Z"
    />
    <path
      fill="#ffb6b6"
      d="m171.565 374.775 21.55-13.704 8.76 5.284-30.31 34.828v-26.408z"
    />
    <path
      fill="#e6e6e6"
      d="M142.097 321.733s-14.313 3.367-7.577 22.732 28.626 59.778 35.361 57.252c6.736-2.526 7.578-27.784 7.578-27.784s-.842.842-4.21-2.526-3.368-1.83-3.368-5.546-2.754-6.47-2.754-6.47l-3.14-6.507s0-30.31-12.628-31.993c-12.63-1.684-9.262.842-9.262.842Z"
    />
    <ellipse
      cx={202.717}
      cy={359.199}
      fill="#ffb6b6"
      rx={14.313}
      ry={8.84}
      transform="rotate(-45 202.717 359.199)"
    />
    <g fill="#e6e6e6">
      <path d="m86.348 433.29 14.704 24.338 85.69-5.07-22.817-29.409-77.577 10.141z" />
      <path d="M111.952 547.12c-.499 0-.934-.369-1.003-.877L85.343 359.236a1.014 1.014 0 1 1 2.01-.275l25.605 187.007a1.014 1.014 0 0 1-1.006 1.152Z" />
      <path d="M86.348 547.358c-.56 0-1.014-.455-1.014-1.015V359.135a1.014 1.014 0 0 1 2.028 0v187.208c0 .56-.454 1.015-1.014 1.015ZM214.882 546.613c-.431 0-.831-.277-.967-.71L153.324 353.32a1.015 1.015 0 0 1 1.934-.61l60.591 192.585a1.015 1.015 0 0 1-.967 1.32ZM196.374 546.85c-.452 0-.865-.305-.982-.764l-42.084-169.969a1.015 1.015 0 0 1 1.966-.5l42.084 169.97a1.015 1.015 0 0 1-.984 1.263Z" />
      <path d="m86.348 362.117 70.225-1.08 12.93 73.774h-82.14l-1.015-72.694z" />
    </g>
    <path
      fill="#ffb6b6"
      d="m194.266 470.597-17.698 31.158 20.549 4.524 8.567-22.161-11.418-13.521z"
    />
    <path
      fill="#2f2e41"
      d="M182.925 495.873s-.688-5.082-4.731-3.47-15.711 7.606-15.711 7.606 30.433 49.45 36.209 47.147c5.776-2.303-1.138-16.282-1.138-16.282l1.526-24.709-16.155-10.292ZM115.997 410.136s-6.314 29.047 13.05 39.15c19.365 10.104 85.457-14.733 85.457-14.733l-30.31 45.415 21.049 8.469s47.99-63.146 44.623-73.25c-3.368-10.102-26.1-10.944-26.1-10.944l-51.359 2.526-13.47-4.21-42.94 7.577Z"
    />
    <path
      fill="#ffb6b6"
      d="m168.084 484.425-27.823 22.583 17.494 11.69 16.043-17.524-5.714-16.749z"
    />
    <path
      fill="#2f2e41"
      d="M148.322 503.842s1.209-4.984-3.144-4.953-17.401 1.367-17.401 1.367 10.353 57.135 16.57 57.09c6.218-.043 4.865-15.58 4.865-15.58l10.412-22.459-11.302-15.465ZM117.181 399.63s-16.451 24.757-2.09 41.214c14.36 16.457 84.96 17.373 84.96 17.373l-44.759 31.272 16.524 15.548s67.678-41.354 68.218-51.99c.54-10.636-20.328-19.692-20.328-19.692l-48.757-16.336-11.015-8.823-42.753-8.567ZM159.443 342.284s-15.997-5.893-23.574-5.893c-.724 0-1.44.084-2.155.236-4.85 1.027-9.312 5.194-11.45 7.468-.834.892-1.314 1.49-1.314 1.49s.05-.581.151-1.6c.497-5.355 2.333-22.926 5.625-34.174 1.178-4.016 2.534-7.224 4.091-8.782 3.63-3.637 5.305-7.998 6.08-11.248.656-2.787.656-4.749.656-4.749l-6.138-2.643-1.137-7.426c-.202.875-1.136 2.585-4.277 5.86-.269.278-.564.513-.875.715-4.53 2.846-13.766-2.104-18.742-5.22 2.071-7.198 5.826-13.782 12.646-14.86 15.997-2.526 15.997-11.787 26.1-1.684a83.548 83.548 0 0 0 2.518 2.408c3.233 6.18 4.411 14.465 4.832 20.224.304 4.032.228 6.836.228 6.836s4.21 25.258 10.945 30.31c6.736 5.052-4.21 22.732-4.21 22.732Z"
    />
    <path
      fill="#ffb6b6"
      d="m128.292 301.871-11.377 16.687 25.69 6.888V301.87h-14.313z"
    />
    <path
      d="m128.292 301.871-11.377 16.687 25.69 6.888V301.87h-14.313z"
      opacity={0.1}
    />
    <path
      fill="#e6e6e6"
      d="m144.202 317.944-24.416-10.103s-32.836 14.313-30.31 42.097 26.1 75.774 26.1 75.774l47.149-20.206-5.052-53.885-13.47-33.677Z"
    />
    <circle cx={133.734} cy={287.78} r={21.541} fill="#ffb6b6" />
    <path
      fill="#2f2e41"
      d="M162.811 279.98c-4.21 4.21-7.577 8.42-7.577 8.42l-1.684-6.735s-.388.286-1.07.74c-2.668 1.82-9.833 6.39-15.584 7.376-2.56.438-4.833.16-6.314-1.38-3.14-3.276-4.075-4.985-4.277-5.86l-1.137 7.425-6.138 2.644s0 9.244 6.736 15.997c.328.328.648.732.96 1.204 3.216 4.85 5.523 16.72 6.988 26.816a304.314 304.314 0 0 1 1.92 16.535s-6.374-7.981-13.37-9.067a9.038 9.038 0 0 0-1.163-.11c-.126-.017-.26-.017-.387-.017-7.577 0-23.574 5.894-23.574 5.894s-10.945-17.68-4.21-22.733c6.736-5.051 10.945-30.31 10.945-30.31s-.278-10.81 2.51-20.5c2.07-7.2 5.825-13.783 12.645-14.86 15.997-2.527 15.997-11.788 26.1-1.685a83.548 83.548 0 0 0 2.518 2.408c9.413 8.655 19.028 13.934 15.163 17.799Z"
    />
    <path fill="#3f3d56" d="M60.334 393h8v158h-8zM254.334 393h8v158h-8z" />
    <path fill="currentColor" d="M158.334 326h53v53h-53z" />
    <path
      fill="#ffb6b6"
      d="m127.784 382.352 21.55-13.704 8.76 5.285-30.31 34.828v-26.409z"
    />
    <path
      fill="#e6e6e6"
      d="M98.316 329.31s-14.313 3.368-7.577 22.732c6.736 19.365 28.626 59.778 35.361 57.252 6.736-2.526 7.578-27.784 7.578-27.784s-.842.842-4.21-2.526c-3.368-3.367-3.368-1.83-3.368-5.545s-2.754-6.47-2.754-6.47l-3.14-6.507s0-30.31-12.628-31.994c-12.63-1.684-9.262.842-9.262.842Z"
    />
    <ellipse
      cx={158.936}
      cy={366.776}
      fill="#ffb6b6"
      rx={14.313}
      ry={8.84}
      transform="rotate(-45 158.936 366.776)"
    />
    <path fill="#3f3d56" d="M36.334 387h242v18h-242z" />
    <rect
      width={46}
      height={25}
      x={483.334}
      y={243}
      fill="#3f3d56"
      rx={5.13}
      ry={5.13}
    />
    <rect
      width={46}
      height={10.26}
      x={558.334}
      y={257.74}
      fill="currentColor"
      rx={5.13}
      ry={5.13}
    />
  </svg>
);
export default SvgSissala;
