import React from "react";
import PropTypes from "prop-types";
import Footer from "components/organisms/Footer";

import { useNavigate } from "react-router-dom";

import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

const SubPageContainer = (props) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  };

  return (
    <>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        {props.children}
        <Box
          sx={{
            mt: 4,
            textAlign: "center",
          }}
        >
          <Button
            type="submit"
            onClick={handleClick}
            variant="contained"
            sx={{
              bgcolor: "secondary.main",
              "&:hover": {
                backgroundColor: "secondary.dark",
              },
            }}
          >
            Voltar para a página inicial
          </Button>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

SubPageContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SubPageContainer;
