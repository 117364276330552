import React from "react";
import PropTypes from "prop-types";
import Footer from "components/organisms/Footer";

import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";

const PageContainer = (props) => {
  return (
    <>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        {props.children}
      </Container>
      <Footer />
    </>
  );
};

PageContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageContainer;
