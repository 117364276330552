import * as React from "react";
const SvgCarteirinha = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10em"
    height="10em"
    data-name="Layer 1"
    viewBox="0 0 898.098 398.742"
    {...props}
  >
    <path
      fill="#fff"
      d="M382.338 254.742H18.5a18.52 18.52 0 0 1-18.5-18.5V14.088A14.104 14.104 0 0 1 14.088 0H386.54a14.313 14.313 0 0 1 14.297 14.297v221.945a18.52 18.52 0 0 1-18.5 18.5Z"
    />
    <path
      fill="#e6e6e6"
      d="M382.338 254.742H18.5a18.52 18.52 0 0 1-18.5-18.5V14.088A14.104 14.104 0 0 1 14.088 0H386.54a14.313 14.313 0 0 1 14.297 14.297v221.945a18.52 18.52 0 0 1-18.5 18.5ZM14.088 4A10.1 10.1 0 0 0 4 14.088v222.154a14.517 14.517 0 0 0 14.5 14.5h363.838a14.517 14.517 0 0 0 14.5-14.5V14.297A10.309 10.309 0 0 0 386.541 4Z"
    />
    <path
      fill="#e6e6e6"
      d="M166.111 198.496H57.091a11.018 11.018 0 0 1-10.905-9.563 9.975 9.975 0 0 1-.095-1.437V67.246a11.012 11.012 0 0 1 11-11h109.02a11.012 11.012 0 0 1 11 11v120.25a11.012 11.012 0 0 1-11 11ZM57.091 62.246a5.006 5.006 0 0 0-5 5v120.25a4.26 4.26 0 0 0 .039.615 5.015 5.015 0 0 0 4.961 4.385h109.02a5.006 5.006 0 0 0 5-5V67.246a5.006 5.006 0 0 0-5-5ZM346.747 159.396h-132a8 8 0 1 1 0-16h132a8 8 0 0 1 0 16ZM286.747 125.396h-72a8 8 0 1 1 0-16h72a8 8 0 0 1 0 16Z"
    />
    <path
      fill="#2f2e41"
      d="M147.548 124.066c0 17.673-16.342 15-36.5 15s-36.5 2.673-36.5-15 7.982-49 36.5-49c29.5 0 36.5 31.327 36.5 49Z"
    />
    <path
      fill="#3f3d56"
      d="M149.024 153.81a9.064 9.064 0 0 0-3.96-4.84 7.867 7.867 0 0 0-1.12-.57 9.054 9.054 0 0 0-11.98 11.47l14.54 33.05h19.15ZM85.574 155.61a9.055 9.055 0 0 0-12.49 5.17l-13.54 31.85a7.876 7.876 0 0 0 2.13.29h16.99l11.48-26.08a9.037 9.037 0 0 0-4.57-11.23Z"
    />
    <circle cx={110.926} cy={114.475} r={24.561} fill="#ffb8b8" />
    <path
      fill="#3f3d56"
      d="M145.494 149.36c-.14-.13-.28-.27-.43-.39a15.953 15.953 0 0 0-12.75-3.97l-45.26 5.73a16.062 16.062 0 0 0-14.02 17.44c.62 6.98 1.62 15.79 3.22 24.75h71.5l2.63-30.56a16.035 16.035 0 0 0-4.89-13Z"
    />
    <path
      fill="#2f2e41"
      d="M82.548 112.066h9.714l4.286-12 .857 12h4.643l2.5-7 .5 7h34.5a26 26 0 0 0-26-26h-5a26 26 0 0 0-26 26Z"
    />
    <path
      fill="#fff"
      d="M497.26 236.242V14.297A14.313 14.313 0 0 1 511.557 0H884.01a14.104 14.104 0 0 1 14.088 14.088v222.154a18.52 18.52 0 0 1-18.5 18.5H515.76a18.52 18.52 0 0 1-18.5-18.5Z"
    />
    <path
      fill="#e6e6e6"
      d="M497.26 236.242V14.297A14.313 14.313 0 0 1 511.557 0H884.01a14.104 14.104 0 0 1 14.088 14.088v222.154a18.52 18.52 0 0 1-18.5 18.5H515.76a18.52 18.52 0 0 1-18.5-18.5ZM511.557 4a10.309 10.309 0 0 0-10.297 10.297v221.945a14.517 14.517 0 0 0 14.5 14.5h363.838a14.517 14.517 0 0 0 14.5-14.5V14.088A10.1 10.1 0 0 0 884.01 4Z"
    />
    <path
      fill="#e6e6e6"
      d="M720.987 187.496V67.246a11.012 11.012 0 0 1 11-11h109.02a11.012 11.012 0 0 1 11 11v120.25a9.975 9.975 0 0 1-.095 1.437 11.018 11.018 0 0 1-10.905 9.563h-109.02a11.012 11.012 0 0 1-11-11Zm11-125.25a5.006 5.006 0 0 0-5 5v120.25a5.006 5.006 0 0 0 5 5h109.02a5.015 5.015 0 0 0 4.962-4.385 4.26 4.26 0 0 0 .038-.615V67.246a5.006 5.006 0 0 0-5-5ZM543.351 151.396a8.01 8.01 0 0 1 8-8h132a8 8 0 1 1 0 16h-132a8.01 8.01 0 0 1-8-8ZM543.351 117.396a8.01 8.01 0 0 1 8-8h72a8 8 0 1 1 0 16h-72a8.01 8.01 0 0 1-8-8Z"
    />
    <path
      fill="#2f2e41"
      d="M759.555 102.976c7.01 13.783 12.536 28 28 28a28 28 0 0 0 0-56c-15.716-.197-41.05 2.344-28 28Z"
    />
    <circle cx={786.177} cy={106.96} r={24.561} fill="#a0616a" />
    <path
      fill="#3f3d56"
      d="M819.709 151.73a42.79 42.79 0 0 0-5.15-4.76 41.725 41.725 0 0 0-28.39-8.8q-.615.03-1.23.09a41.825 41.825 0 0 0-30.16 16.64 42.346 42.346 0 0 0-7.52 34.15c.27 1.25.51 2.51.74 3.77h81.42l.94-6.3a42.094 42.094 0 0 0-10.65-34.79Z"
    />
    <path
      fill="#3f3d56"
      d="M832.179 158.3a14.254 14.254 0 0 0-13.93-11.8c-.13 0-.27.01-.4.01a14.347 14.347 0 0 0-3.29.46 14.2 14.2 0 0 0-10.06 17.43l7.69 28.42h25.88ZM775.229 150.85a14.175 14.175 0 0 0-4.09-4.88 13.769 13.769 0 0 0-4.02-2.1 13.358 13.358 0 0 0-1.81-.48 14.272 14.272 0 0 0-16.1 9.24l-14.08 40.19h26.72l13.63-29.56a14.091 14.091 0 0 0-.25-12.41Z"
    />
    <path
      fill="#2f2e41"
      d="M760.113 93.08a33.405 33.405 0 0 0 19.091 5.9 20.47 20.47 0 0 1-8.113 3.337 67.359 67.359 0 0 0 27.514.155 17.807 17.807 0 0 0 5.76-1.978 7.29 7.29 0 0 0 3.555-4.755c.603-3.448-2.084-6.582-4.876-8.693a35.967 35.967 0 0 0-30.225-6.04c-3.376.873-6.758 2.348-8.951 5.059s-2.843 6.891-.754 9.683Z"
    />
    <path
      fill="#fff"
      d="M630.968 398.742H267.13a18.52 18.52 0 0 1-18.5-18.5V158.088A14.104 14.104 0 0 1 262.718 144H635.17a14.313 14.313 0 0 1 14.297 14.297v221.945a18.52 18.52 0 0 1-18.5 18.5Z"
    />
    <path
      fill="#3f3d56"
      d="M630.968 398.742H267.13a18.52 18.52 0 0 1-18.5-18.5V158.088A14.104 14.104 0 0 1 262.718 144H635.17a14.313 14.313 0 0 1 14.297 14.297v221.945a18.52 18.52 0 0 1-18.5 18.5ZM262.718 148a10.1 10.1 0 0 0-10.088 10.088v222.154a14.517 14.517 0 0 0 14.5 14.5h363.838a14.517 14.517 0 0 0 14.5-14.5V158.297A10.309 10.309 0 0 0 635.171 148Z"
    />
    <path
      fill="#e6e6e6"
      d="M414.741 342.496h-109.02a11.018 11.018 0 0 1-10.905-9.563 9.976 9.976 0 0 1-.095-1.437v-120.25a11.012 11.012 0 0 1 11-11h109.02a11.012 11.012 0 0 1 11 11v120.25a11.012 11.012 0 0 1-11 11Zm-109.02-136.25a5.006 5.006 0 0 0-5 5v120.25a4.262 4.262 0 0 0 .038.615 5.015 5.015 0 0 0 4.962 4.385h109.02a5.006 5.006 0 0 0 5-5v-120.25a5.006 5.006 0 0 0-5-5Z"
    />
    <path
      fill="#2f2e41"
      d="M358.648 222.23c-7.097-3.302-15.81-4.384-23.032-1.316s-12.144 10.931-9.809 17.89c1.059 3.156 3.47 6.26 2.53 9.446-.723 2.453-3.245 4.072-5.722 5.223s-5.187 2.12-6.978 4.062-2.166 5.285.072 6.791c.738.497 1.66.732 2.374 1.255a3.772 3.772 0 0 1 1.164 4.222 8.893 8.893 0 0 1-2.85 3.75c-2.54 2.192-5.898 4.7-5.11 7.81a5.478 5.478 0 0 0 3.697 3.457 18.367 18.367 0 0 0 5.427.716l74.966 2.362a28.423 28.423 0 0 0 7.402-.413 8.762 8.762 0 0 0 5.813-3.905c1.436-2.658.493-5.931-1.28-8.412s-4.282-4.439-6.355-6.717-3.77-5.123-3.404-8.068c.293-2.357 1.847-4.395 2.963-6.536s1.764-4.819.32-6.79c-2.038-2.776-6.928-2.525-9.244-5.112-1.748-1.953-1.41-4.764-1.584-7.282-.418-6.056-4.61-11.776-10.58-14.432a20.83 20.83 0 0 0-18.953 1.29Z"
    />
    <circle cx={361.422} cy={262.756} r={24.561} fill="#a0616a" />
    <path
      fill="#3f3d56"
      d="M393.668 319.036a23.788 23.788 0 0 0-12.68-20.9c-.31-.17-.63-.32-.95-.47a23.62 23.62 0 0 0-8.81-2.24l-23.38-1.31a23.6 23.6 0 0 0-9.7 1.49 22.66 22.66 0 0 0-2.21.96 23.54 23.54 0 0 0-6.12 4.35 23.806 23.806 0 0 0-7.06 17.94l.42 9.91 2.94 7.63h65.37a17.96 17.96 0 0 0 2.1-3.18 15.919 15.919 0 0 0 .08-14.18Z"
    />
    <path
      fill="#3f3d56"
      d="M392.068 305.896a14.143 14.143 0 0 0-4.84-5.74 14.451 14.451 0 0 0-5.01-2.18 14.665 14.665 0 0 0-2.18-.31 14.087 14.087 0 0 0-4.3.37 14.274 14.274 0 0 0-9.19 20.49l8.99 17.02.45.85h30.19ZM342.658 297.026a14.341 14.341 0 0 0-4.51-1.42 13.898 13.898 0 0 0-3.53-.05 14.257 14.257 0 0 0-12.36 11.29l-6.09 29.55h23.82l4.45-1.82 5.54-21.26a14.32 14.32 0 0 0-7.32-16.29Z"
    />
    <path
      fill="#2f2e41"
      d="M336.295 259.264c7.757-.623 14.197-8.371 13.39-16.11a13.01 13.01 0 0 0 11.141 13.245c3.558.392 7.459-.684 10.556 1.11 3.43 1.989 4.527 6.816 8.1 8.533 3.453 1.66 7.838-.603 9.544-4.033s1.287-7.55.157-11.21a31.652 31.652 0 0 0-52.69-12.975c-3.261 3.28-5.85 7.461-6.27 12.068s1.716 9.606 5.853 11.675Z"
    />
    <path
      fill="#e6e6e6"
      d="M595.377 303.396h-132a8 8 0 1 1 0-16h132a8 8 0 0 1 0 16Z"
    />
    <path
      fill="currentColor"
      d="M535.377 269.396h-72a8 8 0 1 1 0-16h72a8 8 0 0 1 0 16ZM574.01 327.82a26.378 26.378 0 0 0 0 52.756 26.378 26.378 0 0 0 0-52.756Z"
    />
    <path
      fill="#fff"
      d="M571.507 365.153a3.201 3.201 0 0 1-1.926-.64l-.035-.026-7.254-5.55a3.224 3.224 0 0 1 3.923-5.116l4.699 3.603 11.103-14.485a3.222 3.222 0 0 1 4.517-.596h.001l-.069.096.071-.096a3.226 3.226 0 0 1 .596 4.519l-13.06 17.03a3.224 3.224 0 0 1-2.563 1.257Z"
      data-name="Path 395"
    />
  </svg>
);
export default SvgCarteirinha;
