import React from "react";
import SubPageContainer from "components/templates/SubPageContainer";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import OptionCard from "components/atoms/OptionCard";
import Siscoord from "draws/home/Siscoord";

import { linkSiscoordIcex, linkSiscoordIchs } from "links/links";

const href = (link) => {
  return (window.location.href = link);
};

const Fields = () => {
  const handleClickIcex = () => {
    href(linkSiscoordIcex);
  };

  const handleClickIchs = () => {
    href(linkSiscoordIchs);
  };

  return (
    <>
      <Box
        sx={{
          mt: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid container spacing={2} width={{ xs: 300, sm: 604 }}>
          <Grid item xs={12} sm={6}>
            <OptionCard
              title="Siscoord"
              description="ICEx"
              image={<Siscoord />}
              action={handleClickIcex}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <OptionCard
              title="Siscoord"
              description="ICHS"
              image={<Siscoord />}
              action={handleClickIchs}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const Home = () => {
  return (
    <SubPageContainer>
      <Fields />
    </SubPageContainer>
  );
};

export default Home;
