import * as React from "react";
const SvgSei = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10em"
    height="10em"
    viewBox="0 0 857.758 609.998"
    {...props}
  >
    <path
      fill="#f2f2f2"
      d="M669.316 602.1a48.675 48.675 0 0 1-39.26-14.645c-6.59-6.948-11.113-15.892-15.487-24.541-1.273-2.517-2.589-5.12-3.928-7.615a15.534 15.534 0 0 1 1.612-17.331 15.273 15.273 0 0 1 16.303-5.21l.638.185 5.744 22.414c.857-3.343 2.697-10.876 3.858-18.256l.098-.618.572.255a78.131 78.131 0 0 1 16.786 10.115 80.529 80.529 0 0 1 30.112 60.643l.015.697-17.063-6.093Z"
    />
    <path
      fill="#cacaca"
      d="m856.563 609.69-278.75.308a1.19 1.19 0 0 1 0-2.381l278.75-.308a1.19 1.19 0 1 1 .008 2.382h-.008Z"
    />
    <path
      fill="#f2f2f2"
      d="M117.392 111.505h-10.415a3.75 3.75 0 0 0-3.75 3.75 3.75 3.75 0 0 1-3.751 3.751H64.753a3.75 3.75 0 0 1-3.75-3.75l-.687-3.802.044.051-.044-.051-6.294-8.024a2.184 2.184 0 0 0-1.75-.85H40.47a2.225 2.225 0 0 0-2.223 2.223l-.244 6.65H33.54a7.137 7.137 0 0 0-7.137 7.138v46.484a6.334 6.334 0 0 0 6.333 6.333h84.656a6.34 6.34 0 0 0 6.332-6.333v-47.238a6.332 6.332 0 0 0-6.332-6.332Z"
    />
    <path
      fill="#e6e6e6"
      d="M60.32 111.282H38.245v-6.65a2.225 2.225 0 0 1 2.223-2.224h11.804a2.184 2.184 0 0 1 1.749.85l6.297 8.024Z"
    />
    <path
      fill="#fff"
      d="M71.621 166.293H34.765a4.405 4.405 0 0 1-4.4-4.4v-10.08a4.405 4.405 0 0 1 4.4-4.4H79a4.405 4.405 0 0 1 4.4 4.4v2.7c-.007 6.503-5.277 11.773-11.78 11.78Z"
    />
    <path
      fill="#f2f2f2"
      d="M45.374 155.016h-9.097a2.231 2.231 0 0 1-.014-4.463h9.111a2.231 2.231 0 0 1 .015 4.463h-.015ZM76.27 162.053H36.277a2.231 2.231 0 0 1-.014-4.462H76.27a2.231 2.231 0 0 1 .014 4.462h-.014ZM117.392 257.505h-10.415a3.75 3.75 0 0 0-3.75 3.75 3.75 3.75 0 0 1-3.751 3.751H64.753a3.75 3.75 0 0 1-3.75-3.75l-.687-3.802.044.051-.044-.051-6.294-8.024a2.184 2.184 0 0 0-1.75-.85H40.47a2.225 2.225 0 0 0-2.223 2.223l-.244 6.65H33.54a7.137 7.137 0 0 0-7.137 7.138v46.484a6.334 6.334 0 0 0 6.333 6.333h84.656a6.34 6.34 0 0 0 6.332-6.333v-47.238a6.332 6.332 0 0 0-6.332-6.332Z"
    />
    <path
      fill="#e6e6e6"
      d="M60.32 257.282H38.245v-6.65a2.225 2.225 0 0 1 2.223-2.224h11.804a2.184 2.184 0 0 1 1.749.85l6.297 8.024Z"
    />
    <path
      fill="#fff"
      d="M71.621 312.293H34.765a4.405 4.405 0 0 1-4.4-4.4v-10.08a4.405 4.405 0 0 1 4.4-4.4H79a4.405 4.405 0 0 1 4.4 4.4v2.7c-.007 6.503-5.277 11.773-11.78 11.78Z"
    />
    <path
      fill="#f2f2f2"
      d="M45.374 301.016h-9.097a2.231 2.231 0 0 1-.014-4.463h9.111a2.231 2.231 0 0 1 .015 4.463h-.015ZM76.27 308.053H36.277a2.231 2.231 0 0 1-.014-4.462H76.27a2.231 2.231 0 0 1 .014 4.462h-.014ZM117.392 403.505h-10.415a3.75 3.75 0 0 0-3.75 3.75 3.75 3.75 0 0 1-3.751 3.751H64.753a3.75 3.75 0 0 1-3.75-3.75l-.687-3.802.044.051-.044-.051-6.294-8.024a2.184 2.184 0 0 0-1.75-.85H40.47a2.225 2.225 0 0 0-2.223 2.223l-.244 6.651H33.54a7.137 7.137 0 0 0-7.137 7.137v46.484a6.334 6.334 0 0 0 6.333 6.333h84.656a6.34 6.34 0 0 0 6.332-6.333v-47.238a6.332 6.332 0 0 0-6.332-6.332Z"
    />
    <path
      fill="#e6e6e6"
      d="M60.32 403.282H38.245v-6.65a2.225 2.225 0 0 1 2.223-2.224h11.804a2.184 2.184 0 0 1 1.749.85l6.297 8.024Z"
    />
    <path
      fill="#fff"
      d="M71.621 458.293H34.765a4.405 4.405 0 0 1-4.4-4.4v-10.08a4.405 4.405 0 0 1 4.4-4.4H79a4.405 4.405 0 0 1 4.4 4.4v2.7c-.007 6.503-5.277 11.773-11.78 11.78Z"
    />
    <path
      fill="#f2f2f2"
      d="M45.374 447.016h-9.097a2.231 2.231 0 1 1 0-4.463h9.097a2.231 2.231 0 0 1 0 4.463ZM76.27 454.053H36.277a2.231 2.231 0 0 1 0-4.462H76.27a2.231 2.231 0 1 1 0 4.462Z"
    />
    <path
      fill="#e6e6e6"
      d="M305.891 136.408a6.164 6.164 0 0 0-6.156 6.157v107.891a6.164 6.164 0 0 0 6.156 6.157H458.5a6.164 6.164 0 0 0 6.156-6.157v-107.89a6.164 6.164 0 0 0-6.156-6.158H305.89Z"
    />
    <path
      fill="#fff"
      d="M403.616 251.363h-93.412a6.634 6.634 0 0 1-6.627-6.627V146.88a6.634 6.634 0 0 1 6.627-6.627h143.984a6.634 6.634 0 0 1 6.627 6.627v47.283c-.036 31.576-25.624 57.164-57.2 57.2Z"
    />
    <path
      fill="#6c63ff"
      d="M367.024 167.334h-22.198a2.12 2.12 0 0 1 0-4.24h22.198a2.12 2.12 0 0 1 0 4.24Z"
    />
    <path
      fill="#e6e6e6"
      d="M416.785 176.004h-71.958a2.12 2.12 0 0 1 0-4.24h71.958a2.12 2.12 0 0 1 0 4.24ZM416.785 185.956h-71.958a2.12 2.12 0 0 1 0-4.24h71.958a2.12 2.12 0 0 1 0 4.24ZM416.785 195.908h-71.958a2.12 2.12 0 0 1 0-4.24h71.958a2.12 2.12 0 0 1 0 4.24ZM416.785 205.86h-71.958a2.12 2.12 0 0 1 0-4.24h71.958a2.12 2.12 0 0 1 0 4.24Z"
    />
    <path
      fill="#e6e6e6"
      d="M474.48 211.735h-30.124c-5.99 0-10.847 4.857-10.847 10.848 0 5.99-4.856 10.847-10.847 10.847h-100.43c-5.99 0-10.847-4.857-10.847-10.847l-1.985-10.997.129.15-.13-.15-18.204-23.208a6.318 6.318 0 0 0-5.059-2.457h-34.14a6.437 6.437 0 0 0-6.429 6.428l-.705 19.237h-12.906c-11.4 0-20.643 9.242-20.643 20.643v134.445c0 10.117 8.202 18.319 18.319 18.319H474.48c10.111-.013 18.304-8.208 18.314-18.319V230.05c0-10.114-8.2-18.314-18.314-18.314Z"
    />
    <path
      fill="#ccc"
      d="M309.41 211.09h-63.843v-19.237a6.437 6.437 0 0 1 6.43-6.429h34.14a6.318 6.318 0 0 1 5.058 2.458l18.214 23.208Z"
    />
    <path
      fill="#fff"
      d="M342.098 370.199h-106.6c-7.025-.008-12.72-5.702-12.727-12.728v-29.153c.008-7.026 5.702-12.72 12.727-12.728h127.945c7.026.008 12.72 5.702 12.728 12.728v7.808c-.022 18.81-15.264 34.051-34.073 34.073Z"
    />
    <path
      fill="#6c63ff"
      d="M266.184 337.581h-26.311a6.454 6.454 0 0 1 0-12.907h26.311a6.454 6.454 0 0 1 .017 12.907h-.017ZM355.543 357.935h-115.67a6.454 6.454 0 0 1 0-12.907h115.67a6.454 6.454 0 1 1 .017 12.907h-.017Z"
    />
    <path
      fill="#cacaca"
      d="M570.061 42.768H1.768a1.807 1.807 0 0 1 0-3.613h568.293a1.807 1.807 0 0 1 0 3.613Z"
    />
    <path
      fill="#9e616a"
      d="M790.504 596.918h-9.38l-4.464-36.186 13.846.001-.002 36.185z"
    />
    <path
      fill="#2f2e41"
      d="m790.835 606.705-28.85-.001v-.365c.001-6.201 5.028-11.228 11.23-11.228l5.27-3.998 9.832 3.998h2.518v11.594Z"
    />
    <path
      fill="#9e616a"
      d="m761.534 564.4-8.982 2.706-14.718-33.357 13.257-3.996 10.443 34.646z"
    />
    <path
      fill="#2f2e41"
      d="M764.676 573.674 737.054 582l-.105-.35c-1.79-5.937 1.573-12.2 7.51-13.991l3.892-5.35 10.567.991 2.412-.727 3.346 11.1Z"
    />
    <path
      fill="#2f2e41"
      d="M748.652 367.652s-10.037 15.32-13.587 25.744c-8.702 25.544-12.863 19.416-22.884 81.523 13.596 45.875 27.06 76.117 27.06 76.117s20.235-5.69 20.235-9.484-12.91-50.06-14.807-55.435-5.059-7.272-2.213-11.698 26.219-43.122 26.219-43.122-.632 65.956 0 67.22 3.673 77.217 3.673 77.217 20.81 10.652 22.07-.7c.317-2.845.716-78.112.716-79.376s19.264-119.107 10.095-123.85-56.577-4.156-56.577-4.156Z"
    />
    <path
      fill="#9e616a"
      d="M706.599 378.57a8.792 8.792 0 0 1 11.227-7.462l18.18-25.411 5.822 15.156-18.156 21.807a8.84 8.84 0 0 1-17.073-4.09Z"
    />
    <path
      fill="#3f3d56"
      d="m731.67 374.632-12.087-9.166 25.376-39.074 20.383-78.522 16.137 15.026a10.542 10.542 0 0 1 6.38 5.474c1.298 2.65 4.055-1.787 3.024.977l-22.7 61.293-.032.042-36.48 43.95Z"
    />
    <path
      fill="#3f3d56"
      d="m774.396 244.653-17.192 6.68-7.066 9.76-17.13 87.855.682 2.965-1.705 2.286-.67 3.436 1.66 2.86-2.916 3.581s.77 2.854 3.631 27.168c1.765 15.002 30.32 5.762 30.32 5.762l12.963-133.239-2.577-19.114Z"
    />
    <path
      fill="#e6e6e6"
      d="m811.092 256.004-14.832-13.268-21.933 1.322-12.627 15.355-24.49 126.832 51.488 4.29 22.394-134.53z"
    />
    <path
      fill="#3f3d56"
      d="m798.091 244.87 13.49 10.357 5.175 7.55a22.072 22.072 0 0 1 10.047 25.955l-16.385 57.76-.383 1.18c-.39 1.145 1.477 10.202.548 19.957-.242 2.541 2.706 5.374 2.485 7.883-.174 1.962-3.523 3.631-3.671 5.434-.134 1.627 1.63 3.175 1.525 4.577a23.373 23.373 0 0 1-2.2 7.873c-13.96 5.774-40.914 1.585-40.914 1.585l24.424-131.62 5.86-18.49Z"
    />
    <circle cx={783.011} cy={213.351} r={24.28} fill="#9e616a" />
    <path
      fill="#2f2e41"
      d="M814.492 214.389c-1.409 7.98-7.108 15.875-15.067 17.392l-.15-.2c.443-3.46.636-6.947.579-10.434a4.048 4.048 0 0 0-7.838-1.366c.143-3.04-1.144-6.143-1.974-9.075-.83-2.925-1.394-5.7-4.283-6.658-2.41-.793-5.034-.243-7.509.322-3.418.773-5.156.594-5.463-.271l-3.795 1.386a2.338 2.338 0 0 1-2.695-.828l-.01-.015c-2.21.572-4.42 1.14-6.63 1.702a40.398 40.398 0 0 1-1.022-4.305 11.56 11.56 0 0 0 3.869-6.014 14.647 14.647 0 0 1-4.255 1.63 10.924 10.924 0 0 1 3.99-8.781c5.227-4.198 10.47-6.772 17.163-6.436 8.095.407 10.519 9.118 17.877 5.72 9.297-4.29 18.622 18.25 17.213 26.231Z"
    />
    <path
      fill="#ccc"
      d="M678.379 229.191a4.948 4.948 0 0 0-6.95.746l-54.356 67.428a4.948 4.948 0 0 0 .746 6.95l95.375 76.885a4.948 4.948 0 0 0 6.95-.746l54.355-67.428a4.948 4.948 0 0 0-.745-6.95l-95.375-76.885Z"
    />
    <path
      fill="#fff"
      d="m681.538 350.268-58.379-47.062a5.325 5.325 0 0 1-.803-7.48l49.3-61.156a5.325 5.325 0 0 1 7.48-.803l89.986 72.54a5.325 5.325 0 0 1 .803 7.48l-23.822 29.55c-15.93 19.716-44.813 22.816-64.565 6.93Z"
    />
    <path
      fill="#6c63ff"
      d="m701.004 279.318-13.873-11.183a1.702 1.702 0 0 1 2.126-2.658l.01.008 13.873 11.183a1.702 1.702 0 0 1-2.136 2.65Z"
    />
    <path
      fill="#e6e6e6"
      d="m727.735 309.806-44.972-36.253a1.702 1.702 0 0 1 2.126-2.658l.01.009 44.971 36.253a1.702 1.702 0 0 1-2.135 2.65ZM722.72 316.026l-44.97-36.253a1.702 1.702 0 0 1 2.125-2.658l.01.008 44.972 36.253a1.702 1.702 0 0 1-2.136 2.65ZM717.707 322.246l-44.972-36.254a1.702 1.702 0 0 1 2.126-2.658l.01.009 44.972 36.253a1.702 1.702 0 0 1-2.136 2.65ZM712.693 328.465l-44.972-36.253a1.702 1.702 0 0 1 2.126-2.658l.01.009 44.972 36.253a1.702 1.702 0 0 1-2.136 2.65Z"
    />
    <path
      fill="#9e616a"
      d="M702.9 327.266a8.792 8.792 0 0 1 13.481.006l29.212-11.086-3.548 15.844-27.196 8.098a8.84 8.84 0 0 1-11.95-12.862Z"
    />
    <path
      fill="#3f3d56"
      d="m725.955 338.59-4.986-14.327 42.771-18.476 53.148-39.485 6.709 8.281c3.698 4.566 2.995 11.264-1.57 14.962-.15.121-.302.238-.457.35l-40.847 32.294-.05.017-12.679 3.797-2.839.166-3.03 1.59-2.69-.875-3.726 2.797-4.279 1.281-25.475 7.628Z"
    />
    <path
      fill="#6c63ff"
      d="M482.858 401.083c-14.45 0-26.164-11.714-26.164-26.164s11.714-26.164 26.164-26.164 26.163 11.714 26.163 26.164c0 14.45-11.714 26.164-26.163 26.164Z"
    />
    <path
      fill="#fff"
      d="M493.181 372.338h-7.743v-7.743a2.58 2.58 0 1 0-5.161 0v7.743h-7.743a2.58 2.58 0 0 0 0 5.162h7.743v7.742a2.58 2.58 0 1 0 5.161 0V377.5h7.743a2.58 2.58 0 1 0 0-5.162Z"
    />
    <path
      fill="#e6e6e6"
      d="M121.405 183.43c-6.91 0-12.51-5.602-12.51-12.511s5.6-12.51 12.51-12.51 12.51 5.6 12.51 12.51c0 6.909-5.6 12.51-12.51 12.51Z"
    />
    <path
      fill="#fff"
      d="M126.341 169.685h-9.872a1.234 1.234 0 0 0 0 2.468h9.872a1.234 1.234 0 0 0 .001-2.468Z"
    />
    <path
      fill="#e6e6e6"
      d="M121.405 327.43c-6.91 0-12.51-5.602-12.51-12.511s5.6-12.51 12.51-12.51 12.51 5.6 12.51 12.51c0 6.909-5.6 12.51-12.51 12.51Z"
    />
    <path
      fill="#fff"
      d="M126.341 313.685h-9.872a1.234 1.234 0 0 0 0 2.468h9.872a1.234 1.234 0 0 0 .001-2.468Z"
    />
    <path
      fill="#e6e6e6"
      d="M121.405 471.43c-6.91 0-12.51-5.602-12.51-12.511s5.6-12.51 12.51-12.51 12.51 5.6 12.51 12.51-5.6 12.51-12.51 12.51Z"
    />
    <path
      fill="#fff"
      d="M126.341 457.685h-9.872a1.234 1.234 0 0 0 0 2.468h9.872a1.234 1.234 0 1 0 .001-2.468Z"
    />
    <g fill="#3f3d56">
      <ellipse cx={76.875} cy={8.645} rx={8.457} ry={8.645} />
      <ellipse cx={106.088} cy={8.645} rx={8.457} ry={8.645} />
      <ellipse cx={135.302} cy={8.645} rx={8.457} ry={8.645} />
      <path d="M168.614 15.478c-.26 0-.52-.1-.715-.3l-5.706-5.834a1 1 0 0 1 0-1.398l5.706-5.833a1.001 1.001 0 0 1 1.43 1.399l-5.023 5.133 5.022 5.134a1 1 0 0 1-.714 1.699ZM178.202 15.478a1 1 0 0 1-.715-1.7l5.022-5.133-5.022-5.133a1 1 0 1 1 1.43-1.399l5.705 5.833a1 1 0 0 1 0 1.398l-5.705 5.833c-.196.2-.455.3-.715.3ZM476.712 1h-10.61c-1.21 0-2.19.98-2.19 2.19v10.62c0 1.21.98 2.19 2.19 2.19h10.61c1.21 0 2.2-.98 2.2-2.19V3.19c0-1.21-.99-2.19-2.2-2.19ZM450.712 1h-10.61c-1.21 0-2.19.98-2.19 2.19v10.62c0 1.21.98 2.19 2.19 2.19h10.61c1.21 0 2.2-.98 2.2-2.19V3.19c0-1.21-.99-2.19-2.2-2.19ZM501.212 1.5h-10.61c-1.21 0-2.19.98-2.19 2.19v10.62c0 1.21.98 2.19 2.19 2.19h10.61c1.21 0 2.2-.98 2.2-2.19V3.69c0-1.21-.99-2.19-2.2-2.19ZM341.29 5.54h-84.81a2.67 2.67 0 1 0 0 5.34h84.81c1.47 0 2.67-1.2 2.67-2.67s-1.2-2.67-2.67-2.67Z" />
    </g>
  </svg>
);
export default SvgSei;
