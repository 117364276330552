import * as React from "react";
const SvgBavr = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10em"
    height="10em"
    data-name="Layer 1"
    viewBox="0 0 669.175 511.628"
    {...props}
  >
    <path
      fill="#f2f2f2"
      d="m623.902 441.044 1.144-25.72a83.066 83.066 0 0 1 38.744-9.807c-18.608 15.214-16.283 44.54-28.899 65a49.964 49.964 0 0 1-36.639 23.089l-15.572 9.534a83.724 83.724 0 0 1 17.647-67.845 80.874 80.874 0 0 1 14.863-13.81c3.73 9.834 8.712 19.56 8.712 19.56ZM387.76 509.543c-7.93-.565-10 1-22 0h-22l2.863-52.649 34.634 13.879 172.114 4.225-.1.292c-.079.225-7.72 22.577-1.747 31.042l.247.35-.483.004-157.888 1.448c-1.723.016-3.93 1.53-5.64 1.409Z"
    />
    <path
      fill="#ccc"
      d="M396.688 510.231c-15.321-1.09-31.186 4.866-44.428-1.858l-10.584.641-.03-.09c-.082-.243-5.082-39.585 5.026-54.839l.445-.671.347 3.613-3.332 49.008 7.386-2.465c17.937 8.443 39.27 2.867 59.8 2.79l139.714-.517c2.926-.01 5.364 1.99 5.442 4.459l.008.24-151.355-.01q-4.234-.001-8.439-.3Z"
    />
    <path
      fill="#e6e6e6"
      d="m543.667 485.616-157.227-3.125-.161-.06-37.147-13.922.702-1.873 36.987 13.863 156.886 3.117-.04 2zM542.828 494.035l-157.227-3.125-.16-.06-37.148-13.922.702-1.873 36.988 13.863 156.885 3.117-.04 2z"
    />
    <path
      fill="#e6e6e6"
      d="m541.989 502.455-157.227-3.126-.16-.06-37.148-13.923.702-1.873 36.988 13.864 156.885 3.118-.04 2zM397.774 475.542a105.217 105.217 0 0 1-41.733-11.776l-10.898-5.78.285-2.857a1.73 1.73 0 0 1 1.838-1.402l.962.011 146.629 2.158a85.935 85.935 0 0 1 28.132 5.106l31.948 11.6-.017.168c-.268 2.692-2.982 4.772-6.18 4.735l-144.434-1.693q-3.277-.038-6.532-.27Z"
    />
    <path
      fill="#ccc"
      d="m554.63 473.22-.033-.002-175.834-4.029-.122-.056-31.702-14.681.322-.491.963.012 30.833 14.286 174.204 3.988 1.396.033-.027.94z"
    />
    <path
      fill="#ccc"
      d="m417.935 455-2.232-.031-50.392-.746-2.388-.033c11.514 12.258 36.972 15.325 74.288 10.576Z"
    />
    <path
      fill="#ffb6b6"
      d="m559.613 161.814 3.074 7.151-5.024 25.395-51.255-7.126.409-30.967 1.029-6.782 51.767 12.329zM586.094 490.943h-11.308l-5.382-43.616h16.69v43.616z"
    />
    <path
      fill="#2f2e41"
      d="M552.458 505.705a4.835 4.835 0 0 0 4.831 4.83h21.513l3.709-7.654 1.44 7.655h8.108l-2.279-27.342-3.686-.216-12.384-.737-1.996-.125v7.746l-17.17 11.862a4.838 4.838 0 0 0-2.086 3.98Z"
    />
    <path
      fill="#ffb6b6"
      d="M481.576 440.943h-11.308l-6.652-64.213 21.247-1.269-3.287 65.482z"
    />
    <path
      fill="#2f2e41"
      d="M447.94 455.705a4.835 4.835 0 0 0 4.83 4.83h21.513l3.709-7.654 1.44 7.655h8.109l-2.28-27.342-3.289-.193-12.6-.76-2.176-.125v7.746l-17.17 11.862a4.838 4.838 0 0 0-2.087 3.98ZM590.894 453.33h-.044l-2.175-70.918a18.544 18.544 0 0 0-.243-17.524l-.311-.564.807-3.742a23.836 23.836 0 0 0-1.362-14.345l-2.03-66.154c7.249-46.974-27.294-77.61-27.294-77.61l3.175-24.62-55.87 1.27.95 23.35-11.109 38.169c-5.846 56.96-45.194 92.762-39.213 147.231a1.04 1.04 0 0 1-.068.46 13.906 13.906 0 0 0 1.123 11.222 4.244 4.244 0 0 1 .505 1.392c1.233 9.55 1.393-2.53 3.026 6.935l-.318.16c-1.144.395-1.634 1.98-1.096 3.54s1.902 2.505 3.046 2.11l29.867.32c1.144-.395 1.635-1.98 1.097-3.54-.539-1.56-1.903-2.505-3.047-2.11l-1.858.94 3.109-10.171 1.09-1.018a4.737 4.737 0 0 0-.603-7.401l3.097-16.528-.44-12.5.906-14.18 39.189-56.786 15.855 78.438 16.195 84.964a2.968 2.968 0 0 0-.929 2.753 3.077 3.077 0 0 0 3.066 2.435h21.986a2.995 2.995 0 0 0 2.884-3.792 3.031 3.031 0 0 0-2.963-2.185ZM571.343 41.14a27.662 27.662 0 1 1-47.578-19.186l.132-.133c.222-.232.443-.464.675-.686a.01.01 0 0 0 .011-.01 5.805 5.805 0 0 0 .874-.786c3.735-5.218 7.968-9.594 18.224-6.86 14.765 3.935 27.662 12.381 27.662 27.661Z"
    />
    <path
      fill="#ffb6b6"
      d="M419.391 29.299a10.132 10.132 0 0 0 11.48 10.47l71.337 58.546 10.896-19.152-73.548-51.527A10.187 10.187 0 0 0 419.39 29.3Z"
    />
    <path
      fill="currentColor"
      d="M532.33 115.581s-.022-2.108-11.362-2.027c-14.077.1-52.846-20.11-49.454-39.142a7.96 7.96 0 0 1-9.49-2.277l18.874-18.171c5.922.4 5.983 4.363 5.475 6.638l15.169 6.028 39.506 22.697-6.976 22.799Z"
    />
    <circle cx={536.866} cy={47.54} r={23.195} fill="#ffb7b7" />
    <path
      fill="currentColor"
      d="M504.986 162.912a9.312 9.312 0 0 0 .454 11.726c.011.68.113 1.338 1.077 3.777a4.268 4.268 0 0 0 .442.669 1.66 1.66 0 0 0 .102.147 7.71 7.71 0 0 0 4.026 2.71l42.46 11.852 5.613 1.564.987.273.544-7.667a6.864 6.864 0 0 0 1.509-8.811l-.783-1.293 4.559-12.26.442-1.213 11.624-31.288.012-2.087.022-2.155.114-15.038a30.676 30.676 0 0 0-12.135-24.473 30.148 30.148 0 0 0-11.624-5.443l-.09-.011-4.593-6.181-18.633 1.86-1.542 3.164-1.872 3.81-13.506 8.04-4.616 10.729-3.119 7.258c-4.83 3.47-7.711 7.927-8.54 13.235-1.768 11.272 6.125 22.556 7.066 23.849l.238 12.94Z"
    />
    <path
      fill="#2f2e41"
      d="M561.14 53.621c-.21-.033-.42-.077-.63-.11-3.618-.642-7.236-1.273-10.843-1.926l-.332-8.176-4.37 7.324c-9.97 3.309-19.297 1.439-28.072-2.688a79.25 79.25 0 0 1-8.409-4.636c-.891-13.829 4.7-22.682 15.104-21.754.38.034.652-.354.984-.52a.01.01 0 0 0 .011-.01c.565-.266 1.14-.52 1.715-.753a25.55 25.55 0 0 1 26.821 5.2c7.081 6.76 10.268 17.925 8.022 28.05Z"
    />
    <path
      fill="#fd6584"
      d="M560.51 53.51s-6.87-25.647-36.325-31.567a3.745 3.745 0 0 1-.288-.122c-.088-.055-.199-.11-.31-.166.332-.188.653-.354.985-.52a.01.01 0 0 0 .011-.01c.288-.266.576-.532.874-.786l.841.033s29.554-4.68 34.212 33.138Z"
    />
    <path
      fill="#2f2e41"
      d="M571.841 215.188a3.913 3.913 0 0 1-4.016.65 9.314 9.314 0 0 1-3.432-2.51 32.36 32.36 0 0 1-7.918-14.27l1.273-2.264a12.428 12.428 0 0 1 7.486-13.676 7.663 7.663 0 0 1 4.722-.41 4.476 4.476 0 0 1 3.254 3.223c.49 2.421-1.527 4.608-3.57 5.998a25.399 25.399 0 0 1-11.956 4.278 41.192 41.192 0 0 1 13.27 11.908 9.757 9.757 0 0 1 1.755 3.446 3.923 3.923 0 0 1-.868 3.627Z"
    />
    <path
      fill="#ccc"
      d="M278.647 511.359h389.281a1.247 1.247 0 0 0 0-2.494H278.647a1.247 1.247 0 1 0 0 2.494Z"
      data-name="f02c4e45-f306-4b42-b3ad-5ddd3016d7d2"
    />
    <path
      fill="#fff"
      d="M382.032 190.77v.65c-1.402-1.642-2.833-3.271-4.25-4.9l-1.784-2.04q-3.782-4.291-7.606-8.555a1435.732 1435.732 0 0 0-7.72-8.484c-.623-.68-1.246-1.36-1.883-2.04a14.662 14.662 0 0 0-1.658-1.586 6.15 6.15 0 0 0-2.025-1.12 5.078 5.078 0 0 0-2.606-.027c-.978.226-1.913.58-2.861.878-.978.326-1.941.637-2.918.949-1.955.637-3.895 1.275-5.85 1.912a.194.194 0 0 1-.252-.106l-.003-.007a.197.197 0 0 1 .142-.27c.481-.155.977-.31 1.473-.467a942.96 942.96 0 0 1 5.892-1.926c.977-.326 1.954-.637 2.932-.949a8.762 8.762 0 0 1 2.833-.567 6.409 6.409 0 0 1 4.164 1.955 28.774 28.774 0 0 1 1.714 1.77l1.955 2.125q3.93 4.292 7.804 8.612 3.888 4.334 7.705 8.683l.553.637c1.43 1.614 2.832 3.244 4.249 4.872Z"
    />
    <path
      fill="#fff"
      d="M356.911 166.425a4.788 4.788 0 0 0-2.062-1.533l-.806.028a85.054 85.054 0 0 0 24.33 24.758l-21.462-23.253Z"
    />
    <path
      fill="#ccc"
      d="m488.841 268.655-32.087-171.81a12.726 12.726 0 0 0-12.086-10.298q-.39-.008-.778 0l-21.86.784-2.352.087-10.91.392-2.352.08-7.017.254-141.498 5.08a8.035 8.035 0 0 1-.645.006l-31.708-.663-11.734-.242-.374-.006-8.66-.185-75.466-1.568-20.008-.42-9.866-.203-2.364-.052-11.25-.23-2.358-.052-11.509-.237-2.358-.051-12.645-.266-1.983-.04-11.279-.237-1.781-.034h-.26a12.692 12.692 0 0 0-12.466 10.598L.167 277.627a12.624 12.624 0 0 0 12.46 14.657h166.66a11.549 11.549 0 0 1 6.816 2.249l3.16 2.34a12.74 12.74 0 0 0 7.513 2.48 192.44 192.44 0 0 1 98.218 0 12.548 12.548 0 0 0 8.932-3.702 11.457 11.457 0 0 1 7.495-3.338l165.67-8.73a12.625 12.625 0 0 0 11.75-14.928Z"
    />
    <path
      fill="#f2f2f2"
      d="M443.44 241.863a21.976 21.976 0 0 1-22.75 21.16c-9.62-.34-21.1-.51-33.56-.25-40.69.87-91.81 6.47-122.56 26.61a21.966 21.966 0 0 1-13.05 3.58c-.34-.02-.67-.04-1.01-.07a21.928 21.928 0 0 1-12.84-5.69c-16.94-15.5-55.79-34.27-144.72-35.59-.37 0-.73-.02-1.09-.04a21.964 21.964 0 0 1-20.54-23.32l8.74-138.71 1.31-20.79 2.3-36.37a14.443 14.443 0 0 1 14.4-13.53h.09l52.98.31q33.48 1.515 55.46 8.53a95.875 95.875 0 0 1 14.56 5.86 55.975 55.975 0 0 1 16.94 12.65 19.119 19.119 0 0 0 11.5 6.21 18.276 18.276 0 0 0 2.08.19 19.057 19.057 0 0 0 11.53-3.3c34.61-23.19 105.14-24.95 144.09-23.95a21.93 21.93 0 0 1 21.36 20.31l1.58 20.69 1.57 20.63 9.06 119.23 2.52 33.17a22.806 22.806 0 0 1 .05 2.48Z"
    />
    <path
      fill="#3f3d56"
      d="M106.131 67.663c31.927-10.187 67.546-7.49 97.793 6.86a128.768 128.768 0 0 1 24.301 14.968c2.128 1.67 5.155-1.317 3.005-3.005a139.187 139.187 0 0 0-97.705-28.651 133.697 133.697 0 0 0-28.523 5.73c-2.596.828-1.486 4.932 1.13 4.098ZM281.815 91.14c29.684-15.603 65.23-19.005 97.504-10.149a128.52 128.52 0 0 1 26.512 10.542c2.412 1.287 4.56-2.38 2.145-3.67-30.76-16.413-67.684-20.191-101.275-11.042a133.658 133.658 0 0 0-27.03 10.65c-2.422 1.272-.277 4.941 2.144 3.669ZM281.815 119.468c29.684-15.603 65.23-19.005 97.504-10.148a128.52 128.52 0 0 1 26.512 10.54c2.412 1.288 4.56-2.379 2.145-3.668C377.216 99.779 340.292 96 306.7 105.15a133.658 133.658 0 0 0-27.03 10.649c-2.422 1.272-.277 4.941 2.144 3.669ZM409.015 146.276a1.821 1.821 0 0 0-1.047-1.761 139.35 139.35 0 0 0-80.222-15.026c-.173.013-.333.041-.52.055h-.007a4.253 4.253 0 0 0-.506.076 134.222 134.222 0 0 0-47.042 14.5 2.144 2.144 0 0 0-1.047 1.844v52.846a2.144 2.144 0 0 0 2.69 2.039 81.689 81.689 0 0 1 1.97-.541 210.62 210.62 0 0 1 45.35-7.094c.326-.014.652-.028.991-.041a237.569 237.569 0 0 1 37.41 1.345 7.8 7.8 0 0 1 .777.083c.638.07 1.29.145 1.928.229.256.027.5.055.735.097 2.482.312 4.944.665 7.412 1.047q5.347.853 10.693 1.9c.222.041.45.097.68.139a316.563 316.563 0 0 1 17.079 3.91 2.153 2.153 0 0 0 2.69-2.052v-53.512a.176.176 0 0 0-.014-.083Z"
    />
    <path
      fill="#ff6582"
      d="M331.078 132.37a6.988 6.988 0 0 0-5.007-.44 5.934 5.934 0 0 0-3.335 3.137 15.083 15.083 0 0 0-1.098 4.314c-.088.584-.093 1.32.494 1.593a1.243 1.243 0 0 0 1.26-.25 3.729 3.729 0 0 0 .74-1.03 11.215 11.215 0 0 1 9.473-5.15l1.569.624a18.581 18.581 0 0 0-4.096-2.798Z"
    />
    <path
      fill="#fff"
      d="M348.056 144.55c-1.217-1.792-2.66-3.706-4.92-4.29-2.615-.678-5.303.668-7.583 1.996a221.538 221.538 0 0 0-19.666 12.898l.007.07 14.675-.901c3.534-.217 7.19-.464 10.314-1.952a9.726 9.726 0 0 1 3.683-1.372 6.156 6.156 0 0 1 4.267 2.056c6.763 6.41 8.72 16.214 16.308 21.846a206.083 206.083 0 0 0-17.085-30.35ZM329.628 193.172l-.992.042a89.336 89.336 0 0 0-7.237-2.252 79.253 79.253 0 0 0-4.816-1.133 81.69 81.69 0 0 0-5.51-.978 88.116 88.116 0 0 0-10.58-.892.192.192 0 1 1 0-.382c.452 0 .906.014 1.345.042a88.236 88.236 0 0 1 10.595 1.034c1.827.298 3.654.652 5.467 1.048a95.49 95.49 0 0 1 4.9 1.233q3.464.977 6.828 2.238ZM370.477 194.928a11.41 11.41 0 0 0-.736-.1 60.502 60.502 0 0 0-3.003-2.322 56.9 56.9 0 0 0-3.98-2.635 70.673 70.673 0 0 0-9.405-4.787 93.783 93.783 0 0 0-15.892-5.014 158.136 158.136 0 0 0-18.796-2.946c-6.317-.652-12.663-1.048-19.008-1.445-.27-.014-.284-.41 0-.397.793.057 1.586.1 2.393.156 6.417.397 12.833.85 19.207 1.572a146.424 146.424 0 0 1 18.767 3.258 91.28 91.28 0 0 1 15.383 5.283 69.943 69.943 0 0 1 8.271 4.405 62.239 62.239 0 0 1 4.873 3.4c.652.51 1.303 1.02 1.926 1.572Z"
    />
    <path
      fill="#fff"
      d="M367.814 194.602a7.698 7.698 0 0 0-.779-.085c-.297-.24-.609-.467-.92-.694a64.772 64.772 0 0 0-6.813-4.263 72.163 72.163 0 0 0-6.587-3.159 92.525 92.525 0 0 0-15.892-5.014 158.11 158.11 0 0 0-18.796-2.946c-6.317-.652-12.662-1.048-19.008-1.445-.269-.028-.269-.41 0-.396.808.056 1.6.099 2.394.155 6.416.397 12.833.85 19.206 1.573a146.393 146.393 0 0 1 18.768 3.257 90.804 90.804 0 0 1 15.382 5.284 70.97 70.97 0 0 1 5.482 2.776 57.987 57.987 0 0 1 7.563 4.957Z"
    />
    <path
      fill="#fff"
      d="M389.259 198.016c-.227-.043-.454-.1-.68-.142-.255-.27-.496-.552-.737-.836q-1.827-2.103-3.612-4.249a279.26 279.26 0 0 1-13.328-17.195q-5.397-7.521-10.24-15.34a247.693 247.693 0 0 1-4.49-7.479q-1.7-2.931-3.315-5.878a98.613 98.613 0 0 1-.906-1.7c-.695-1.33-1.389-2.676-2.125-3.994a11.287 11.287 0 0 0-3.428-4.079 5.016 5.016 0 0 0-2.436-.835 7.366 7.366 0 0 0-3.513.906 114.45 114.45 0 0 0-22.28 13.668 107.685 107.685 0 0 0-18.102 17.89c-.17.198-.538.014-.382-.199a157.32 157.32 0 0 1 1.94-2.365 108.582 108.582 0 0 1 17.125-16.275c.014-.014.042-.028.056-.042.553-.44 1.105-.864 1.672-1.275a115.917 115.917 0 0 1 10.935-7.139q2.889-1.678 5.906-3.187a50.6 50.6 0 0 1 1.515-.75c.51-.255 1.02-.496 1.53-.737a8.338 8.338 0 0 1 3.244-.892c3.937-.057 5.963 3.91 7.365 6.586.44.836.893 1.672 1.332 2.507 1.7 3.159 3.47 6.303 5.311 9.405a235.181 235.181 0 0 0 3.456 5.737 259.039 259.039 0 0 0 5.213 8.045.014.014 0 0 0 .013.014 254.819 254.819 0 0 0 6.488 9.306q5.758 7.882 12.068 15.41 2.167 2.592 4.405 5.114Z"
    />
    <path
      fill="#ff6582"
      d="m338.726 135.46-.392.171a10.691 10.691 0 0 0-8.637-5.228c-5.798-.412-10.892 3.453-11.355 8.614a8.53 8.53 0 0 0 1.724 5.938l-.364.215a8.884 8.884 0 0 1-1.796-6.184c.482-5.375 5.787-9.4 11.826-8.97a11.133 11.133 0 0 1 8.994 5.444Z"
    />
    <path
      fill="#fff"
      d="M310.072 145.903a10.459 10.459 0 0 0-9.525.606 150.154 150.154 0 0 1 15.456 3.211c-2.109-1.1-3.758-2.823-5.931-3.817ZM300.517 146.505l-.573.414c.194-.145.396-.281.603-.41Z"
    />
    <path
      fill="#fff"
      d="M317.777 148.91c-.229-.227-.456-.454-.686-.68a19.891 19.891 0 0 0-6.248-4.573 10 10 0 0 0-3.798-.784 11.985 11.985 0 0 0-3.944.766c-.594.21-1.174.45-1.746.706-.654.292-1.295.605-1.934.92q-1.799.885-3.55 1.85-3.487 1.917-6.754 4.127-1.694 1.146-3.318 2.368-1.51 1.135-2.96 2.33c-.207.17-.515-.105-.309-.274.255-.212.513-.421.77-.629q1.092-.878 2.218-1.722 2.054-1.543 4.214-2.967 3.36-2.215 6.95-4.129 1.794-.956 3.638-1.831a33.15 33.15 0 0 1 1.126-.512 19.283 19.283 0 0 1 2.678-.97 10.644 10.644 0 0 1 4.044-.354 11.419 11.419 0 0 1 3.731 1.149 24.482 24.482 0 0 1 6.186 4.932c.186.187-.12.463-.308.276Z"
    />
    <path
      fill="#3f3d56"
      d="M106.131 95.99c31.927-10.185 67.546-7.489 97.793 6.862a128.767 128.767 0 0 1 24.301 14.967c2.128 1.67 5.155-1.317 3.005-3.004a139.187 139.187 0 0 0-97.705-28.652 133.697 133.697 0 0 0-28.523 5.73c-2.596.828-1.486 4.932 1.13 4.098ZM106.131 124.32c31.927-10.187 67.546-7.49 97.793 6.86a128.767 128.767 0 0 1 24.301 14.968c2.128 1.67 5.155-1.317 3.005-3.005a139.187 139.187 0 0 0-97.705-28.652 133.698 133.698 0 0 0-28.523 5.73c-2.596.829-1.486 4.933 1.13 4.098ZM106.131 152.647c31.927-10.186 67.546-7.49 97.793 6.861a128.768 128.768 0 0 1 24.301 14.968c2.128 1.67 5.155-1.317 3.005-3.005a139.187 139.187 0 0 0-97.705-28.652 133.697 133.697 0 0 0-28.523 5.73c-2.596.829-1.486 4.933 1.13 4.098ZM106.131 180.976c31.927-10.187 67.546-7.49 97.793 6.86a128.767 128.767 0 0 1 24.301 14.968c2.128 1.67 5.155-1.317 3.005-3.005a139.187 139.187 0 0 0-97.705-28.651 133.697 133.697 0 0 0-28.523 5.73c-2.596.828-1.486 4.932 1.13 4.098ZM106.131 209.304c31.927-10.187 67.546-7.49 97.793 6.86a128.765 128.765 0 0 1 24.301 14.968c2.128 1.67 5.155-1.317 3.005-3.004a139.187 139.187 0 0 0-97.705-28.652 133.698 133.698 0 0 0-28.523 5.73c-2.596.828-1.486 4.932 1.13 4.098Z"
    />
    <path
      fill="currentColor"
      d="M221.16 33.553v67.93c0 1.35-2.36 2.48-5.54 2.79a.22.22 0 0 0-.08.01c-.53.04-1.09.07-1.66.07a14.57 14.57 0 0 1-5.15-.84c-.15-.06-.3-.13-.45-.19-.14-.08-.29-.15-.43-.23-.79-.47-1.25-1.02-1.25-1.61v-73.79a95.875 95.875 0 0 1 14.56 5.86Z"
    />
    <path fill="#e6e6e6" d="M249.599 49.308h2.08v242.235h-2.08z" />
    <path
      fill="#ffb6b6"
      d="M477.9 215.135a10.132 10.132 0 0 0 8.85-12.771l43.177-20.156 25.994-42.049-20.432-8.25-17.536 33.885-44.385 29.577a10.187 10.187 0 0 0 4.333 19.764Z"
    />
    <path
      fill="currentColor"
      d="M571.956 99.877s5.089 1.661-.496 11.532c-4.472 7.901-26.253 53.417-35.143 62.354a7.96 7.96 0 0 1-.992 9.708l-20.523-16.286c-.392-5.923 3.528-6.51 5.85-6.31l4.722-16.734 16.468-41.279 26.457-4.25Z"
    />
    <path
      fill="#2f2e41"
      d="M572.4 22.508c.97-3.923 8.009-6.82-4.05-12.297-2.926-1.33-2.804-5.907-5.28-7.957s-6.1-3-8.978-1.568c-2.569 1.28-4.017 4.176-4.325 7.03a25.892 25.892 0 0 0 .98 8.511l-.233.342 14.33 10.965c1.755 1.343 4.05 2.75 6.017 1.742 2.227-1.141.938-4.339 1.538-6.768Z"
    />
  </svg>
);
export default SvgBavr;
