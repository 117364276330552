import * as React from "react";
const SvgMoreOptions = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10em"
    height="10em"
    data-name="Layer 1"
    viewBox="0 0 924.766 641.989"
    {...props}
  >
    <path
      fill="#f0f0f0"
      d="M764.224 548.827c3.74-30.077 22.38-59.713 51.065-69.5a139.647 139.647 0 0 0 .007 95.876c4.408 11.923 10.552 24.725 6.406 36.742-2.58 7.477-8.89 13.197-15.945 16.774-7.054 3.577-14.883 5.282-22.614 6.952l-1.522 1.259c-12.273-27.713-21.138-58.025-17.397-88.103Z"
    />
    <path
      fill="#fff"
      d="M815.574 479.895a119.36 119.36 0 0 0-29.673 67.176 51.4 51.4 0 0 0 .585 16.094 29.48 29.48 0 0 0 7.332 13.674c3.305 3.63 7.106 6.962 9.47 11.333a18.051 18.051 0 0 1 .882 14.736c-2.087 5.988-6.202 10.869-10.392 15.502-4.652 5.144-9.565 10.413-11.543 17.233-.24.826-1.507.406-1.268-.419 3.44-11.865 14.958-18.604 20.451-29.291 2.563-4.987 3.639-10.776 1.236-16.04-2.101-4.603-6.018-8.042-9.396-11.69a31.458 31.458 0 0 1-7.667-13.102 47.519 47.519 0 0 1-1.201-16.012 115.8 115.8 0 0 1 8.457-35.191 121.477 121.477 0 0 1 21.843-35.005c.571-.64 1.451.367.884 1.002Z"
    />
    <path
      fill="#fff"
      d="M786.26 538.987a17.908 17.908 0 0 1-13.63-18.76c.067-.856 1.402-.79 1.334.067a16.582 16.582 0 0 0 12.716 17.424c.836.2.412 1.467-.42 1.269ZM791.9 575.219a34.516 34.516 0 0 0 15.413-19.88c.243-.825 1.511-.405 1.269.42a35.902 35.902 0 0 1-16.081 20.653c-.74.44-1.338-.756-.601-1.193ZM799.318 502.335a10.137 10.137 0 0 0 9.606-.488c.735-.448 1.331.748.601 1.193a11.359 11.359 0 0 1-10.626.563.69.69 0 0 1-.425-.843.671.671 0 0 1 .844-.425Z"
    />
    <path
      fill="#f0f0f0"
      d="M902.725 533.049c-.45.293-.902.586-1.353.89a133.495 133.495 0 0 0-17.069 13.327 58.76 58.76 0 0 0-1.24 1.138 140.734 140.734 0 0 0-30.564 41.839 136.671 136.671 0 0 0-7.487 19.37c-2.762 9.165-5.028 19.323-10.496 26.82a23.445 23.445 0 0 1-1.827 2.256h-49.415c-.112-.057-.225-.102-.338-.158l-1.973.09c.08-.35.17-.71.248-1.06.046-.203.102-.406.147-.609.033-.135.068-.27.09-.394.011-.045.023-.09.034-.124.022-.124.056-.237.079-.35q.744-3.027 1.533-6.054c0-.011 0-.011.011-.023 4.048-15.367 9.414-30.53 16.912-44.398.225-.417.45-.845.699-1.262a130.413 130.413 0 0 1 11.714-17.769 115.29 115.29 0 0 1 7.678-8.782 95.873 95.873 0 0 1 23.991-17.971c17.723-9.358 38.242-12.943 57.183-7.227.485.146.959.293 1.443.45Z"
    />
    <path
      fill="#fff"
      d="M902.614 533.676a119.36 119.36 0 0 0-64.137 35.771 51.4 51.4 0 0 0-9.223 13.202 29.48 29.48 0 0 0-2.378 15.332c.453 4.89 1.482 9.838.738 14.751a18.051 18.051 0 0 1-8.168 12.297c-5.272 3.524-11.496 4.944-17.63 6.12-6.812 1.307-13.907 2.556-19.592 6.81-.689.516-1.448-.583-.76-1.097 9.89-7.403 23.144-5.85 33.964-11.075 5.049-2.438 9.393-6.413 10.644-12.063 1.093-4.94.037-10.044-.464-14.99a31.458 31.458 0 0 1 1.766-15.078 47.519 47.519 0 0 1 8.682-13.508 115.8 115.8 0 0 1 27.94-23.006 121.477 121.477 0 0 1 38.516-14.798c.84-.167.938 1.166.102 1.332Z"
    />
    <path
      fill="#fff"
      d="M843.632 563.209a17.908 17.908 0 0 1 .41-23.185c.57-.643 1.596.212 1.026.856a16.582 16.582 0 0 0-.338 21.568c.548.662-.553 1.42-1.098.76ZM826.32 595.533a34.516 34.516 0 0 0 24.275-6.592c.691-.513 1.45.586.761 1.098a35.902 35.902 0 0 1-25.274 6.808c-.856-.095-.613-1.409.238-1.314ZM876.124 541.806a10.137 10.137 0 0 0 7.964 5.394c.857.084.612 1.398-.239 1.314a11.359 11.359 0 0 1-8.823-5.948.69.69 0 0 1 .169-.93.671.671 0 0 1 .93.17Z"
    />
    <path
      fill="#9e616a"
      d="M604.622 396.977a10.056 10.056 0 0 0-1.61-15.335l9.136-34.547-17.601 5.92-5.977 31.9a10.11 10.11 0 0 0 16.052 12.062ZM686.055 629.029l-12.26-.001-5.832-47.288 18.094.001-.002 47.288z"
    />
    <path
      fill="#2f2e41"
      d="m689.182 640.913-39.531-.002v-.5a15.387 15.387 0 0 1 15.386-15.386h24.145Z"
    />
    <path
      fill="#9e616a"
      d="m628.055 629.029-12.26-.001-5.832-47.288 18.094.001-.002 47.288z"
    />
    <path
      fill="#2f2e41"
      d="m631.182 640.913-39.531-.002v-.5a15.387 15.387 0 0 1 15.386-15.386h24.145Z"
    />
    <path
      fill="#2f2e41"
      d="m621.236 344.11-4.706 1.3s-12.294 79.7-13.294 121.7 3 148 3 148h26l21-180 10 180h28s20-236 11-255-81-16-81-16Z"
      data-name="Path 1095"
    />
    <circle
      cx={658.744}
      cy={179.984}
      r={23.493}
      fill="#9e616a"
      data-name="Ellipse 219"
    />
    <path
      fill="#3f3d56"
      d="m718.236 234.11-36.464-23.83-37.786 1.487-36.75 20.342 14 78-6 45s54-6 66 3a51.277 51.277 0 0 0 24 10l-2-77Z"
      data-name="Path 1099"
    />
    <path
      fill="#3f3d56"
      d="m614.236 231.11-3.778-.785-3.222 1.784s-9 12-9 23-11 117-11 117l20 9 16-83Z"
      data-name="Path 1101"
    />
    <path
      fill="#2f2e41"
      d="M679.317 162.759s6.145-11.746-7.373-12.814c0 0-11.525-10.454-23.271-1.911 0 0-6.407 0-9.91 7.25 0 0-5.04-1.911-6.147 3.204 0 0-3.688 10.678 0 20.289s4.912 10.678 4.912 10.678-6.06-20.149 8.689-21.217 31.252-10.284 32.481 1.462 3.079 14.64 3.079 14.64 11.673-16.776-2.46-21.581Z"
      data-name="Path 1104"
    />
    <path
      fill="#fff"
      d="M375.736 300.964h-291v-218h291Z"
      data-name="Subtraction 1"
    />
    <path
      fill="#e6e6e6"
      d="M375.736 300.964h-291v-218h291Zm-288-215v212h285v-212Z"
      data-name="Subtraction 1"
    />
    <g data-name="Group 41">
      <circle
        cx={230.145}
        cy={192.373}
        r={55.409}
        fill="currentColor"
        data-name="Ellipse 220"
      />
      <path
        fill="#fff"
        d="M253.154 187.72h-18.84v-18.839a4.433 4.433 0 0 0-4.432-4.433 4.433 4.433 0 0 0-4.433 4.433v18.84h-18.84a4.433 4.433 0 0 0-4.432 4.432 4.433 4.433 0 0 0 4.433 4.433h18.839v18.84a4.433 4.433 0 0 0 4.433 4.432 4.433 4.433 0 0 0 4.433-4.433v-18.839h18.839a4.433 4.433 0 0 0 4.433-4.433 4.433 4.433 0 0 0-4.433-4.433Z"
        data-name="Path 1106"
      />
    </g>
    <path fill="#fff" d="M108.739 82.054H0V0h108.739Z" />
    <path
      fill="#e6e6e6"
      d="M108.739 82.054H0V0h108.739ZM2 80.054h104.739V2H2Z"
    />
    <path
      fill="#e4e4e4"
      d="M14.342 22.348h80.054v5.337H14.342z"
      data-name="Rectangle 342"
    />
    <path
      fill="#e4e4e4"
      d="M14.342 38.358h80.054v5.337H14.342z"
      data-name="Rectangle 343"
    />
    <path
      fill="#e4e4e4"
      d="M14.342 54.369h80.054v5.337H14.342z"
      data-name="Rectangle 344"
    />
    <path fill="#fff" d="M831.739 641.054H723V559h108.739Z" />
    <path
      fill="#e6e6e6"
      d="M831.739 641.054H723V559h108.739Zm-106.739-2h104.739V561H725Z"
    />
    <path
      fill="#e4e4e4"
      d="M737.342 581.348h80.054v5.337h-80.054z"
      data-name="Rectangle 342"
    />
    <path
      fill="#e4e4e4"
      d="M737.342 597.358h80.054v5.337h-80.054z"
      data-name="Rectangle 343"
    />
    <path
      fill="#e4e4e4"
      d="M737.342 613.369h80.054v5.337h-80.054z"
      data-name="Rectangle 344"
    />
    <path
      fill="#fff"
      d="m765.701 493.822-81.22-72.3 54.557-61.29 81.22 72.3Z"
    />
    <path
      fill="#e6e6e6"
      d="m765.701 493.822-81.22-72.3 54.557-61.29 81.22 72.3Zm-78.397-72.464 78.233 69.64 51.898-58.301-78.233-69.64Z"
    />
    <path
      fill="#e4e4e4"
      d="m731.342 390.447 3.549-3.986 59.795 53.227-3.548 3.987z"
      data-name="Rectangle 342"
    />
    <path
      fill="#e4e4e4"
      d="m720.698 402.406 3.548-3.986 59.796 53.227-3.549 3.986z"
      data-name="Rectangle 343"
    />
    <path
      fill="#e4e4e4"
      d="m710.052 414.365 3.548-3.986 59.796 53.227-3.549 3.987z"
      data-name="Rectangle 344"
    />
    <path fill="#fff" d="M170.307 130.054H49V38.517h121.307Z" />
    <path
      fill="#e6e6e6"
      d="M170.307 130.054H49V38.517h121.307ZM51.23 127.823h116.844V40.748H51.231Z"
    />
    <path
      fill="currentColor"
      d="M65 63.448h89.306v5.954H65z"
      data-name="Rectangle 342"
    />
    <path
      fill="currentColor"
      d="M65 81.308h89.306v5.954H65z"
      data-name="Rectangle 343"
    />
    <path
      fill="currentColor"
      d="M65 99.169h89.306v5.954H65z"
      data-name="Rectangle 344"
    />
    <path fill="#fff" d="M485.117 369.688H300V230h185.117Z" />
    <path
      fill="#e6e6e6"
      d="M485.117 369.688H300V230h185.117Zm-181.712-3.404h178.307v-132.88H303.405Z"
    />
    <path
      fill="currentColor"
      d="M324.416 268.045h136.283v9.086H324.416z"
      data-name="Rectangle 342"
    />
    <path
      fill="currentColor"
      d="M324.416 295.3h136.283v9.086H324.416z"
      data-name="Rectangle 343"
    />
    <path
      fill="currentColor"
      d="M324.416 322.558h136.283v9.086H324.416z"
      data-name="Rectangle 344"
    />
    <path
      fill="#cacaca"
      d="M923.576 641.99H443.282a1.19 1.19 0 0 1 0-2.382h480.294a1.19 1.19 0 1 1 0 2.381Z"
    />
    <path
      fill="#9e616a"
      d="M705.805 396.224a10.056 10.056 0 0 1 5.502-14.405l.06-35.734 15.485 10.249-2.431 32.363a10.11 10.11 0 0 1-18.616 7.527Z"
    />
    <path
      fill="#3f3d56"
      d="m707.236 238.11 6.918-6.669 5.082 3.668s5 6 9 24 0 117 0 117l-22-3 3-52-9-44Z"
      data-name="Path 1100"
    />
  </svg>
);
export default SvgMoreOptions;
